import { frontendURL } from '../../../../helper/URLHelper';

const ai = (accountId, inboxId) => ({
  parentNav: 'ai',
  routes: [
    'ai_home',
    'ai_intents',
    'ai_training',
    'ai_intents_new',
    'ai_intents_edit',
    'ai_playground',
    'ai_playground_detail',
  ],
  menuItems: [
    {
      label: 'INTENT.TITLE',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/ai/intents`),
      toStateName: 'ai_intents',
    },
    {
      label: 'PLAYGROUND.TITLE',
      hasSubMenu: false,
      toState: frontendURL(
        `accounts/${accountId}/ai/playground/inbox/${inboxId}`
      ),
      toStateName: 'ai_playground',
      posthogFeatureFlag: 'ai-playground',
    },
    // Hide training ground for now
    // {
    //   label: 'TRAINING_GROUND.TITLE',
    //   hasSubMenu: false,
    //   toState: frontendURL(`accounts/${accountId}/ai/training`),
    //   toStateName: 'ai_training',
    //   isDisabled: true, // temporary disabled training ground page
    // },
  ],
});

export default ai;
