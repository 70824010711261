<!-- eslint-disable vue/no-mutating-props -->
<template>
  <woot-modal :show.sync="show" :on-close="onClose" size="min-h-[75vh] !w-4/6">
    <woot-modal-header
      :header-title="modelTitle"
      :header-content="$t('SHOPIFY_PANEL.REFUND_MODAL.DESCRIPTION')"
    />

    <div class="order-refund">
      <div class="order-refund-content">
        <div class="mt-6">
          <div
            class="flex items-center p-4 bg-neutral-subtle dark:bg-primary rounded-lg"
          >
            <div class="flex items-center gap-3 w-1/2">
              <div class="kpi">
                <div class="kpi__headline">
                  {{ order.created_at }}
                </div>
                <div class="kpi__tagline">
                  {{ $t('SHOPIFY_PANEL.REFUND_MODAL.ORDER_DATE') }}
                </div>
              </div>
              <div class="kpi">
                <div class="kpi__headline">{{ order.line_items.length }}</div>
                <div class="kpi__tagline">
                  {{ $t('SHOPIFY_PANEL.REFUND_MODAL.TOTAL_ITEMS') }}
                </div>
              </div>
            </div>
            <div class="flex items-center w-1/2">
              <div class="tag-wrapper">
                <div class="tag-wrapper__item">
                  <div class="tag-wrapper__label">
                    {{ order.financial_status }}
                  </div>
                </div>
                <div class="tag-wrapper__item">
                  <div
                    class="tag-wrapper__label"
                    :class="
                      !order.fulfillment_status
                        ? 'tag-wrapper__label--warning'
                        : 'tag-wrapper__label--fulfilled'
                    "
                  >
                    {{ order.fulfillment_status || 'unfulfilled' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="order-summary-actions">
          <woot-button variant="clear" @click="fullRefundSubmit">
            {{ $t('SHOPIFY_PANEL.REFUND_MODAL.REFUND_ALL') }}
          </woot-button>
        </div>
        <div>
          <table>
            <thead>
              <tr
                class="border-b border-neutral-high dark:border-neutral-dark-high"
              >
                <th width="41%">
                  {{ $t('SHOPIFY_PANEL.REFUND_MODAL.PRODUCT') }}
                </th>
                <th width="21%" class="text-right">
                  {{ $t('SHOPIFY_PANEL.REFUND_MODAL.ITEM_PRICE') }}
                </th>
                <th width="17%" class="text-center">
                  {{ $t('SHOPIFY_PANEL.REFUND_MODAL.QTY') }}
                </th>
                <th width="21%" class="text-right">
                  {{ $t('SHOPIFY_PANEL.REFUND_MODAL.ITEM_TOTAL') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in refundItems" :key="item.id">
                <td>
                  <div class="text-primary dark:text-primary-dark text-sm">
                    {{ item.quantity }} X {{ item.name }}
                  </div>
                  <div class="text-secondary dark:text-secondary-dark text-sm">
                    {{
                      $t('SHOPIFY_PANEL.REFUND_MODAL.SKU', { sku: item.sku })
                    }}
                  </div>
                </td>
                <td>
                  <div class="text-right text-sm">
                    {{ amountWithCurrency(item.price) }}
                  </div>
                </td>
                <td>
                  <div class="flex items-center justify-center gap-2">
                    <input
                      v-model.number="item.refund_quantity"
                      :type="'number'"
                      autocomplete="off"
                      min="0"
                      :max="item.quantity"
                      :value="0"
                      class="mb-0 w-16"
                      @input="fetchRefundInfo"
                    />
                    <span> / {{ item.quantity }}</span>
                  </div>
                </td>
                <td>
                  <div class="text-right font-medium">
                    {{
                      amountWithCurrency(
                        (item.quantity * item.price).toFixed(2)
                      )
                    }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="order-summary-foot">
          <div>
            <label for="restockItems">
              <input
                id="restockItems"
                v-model="restockItems"
                :disabled="hasMultipleTransactions"
                type="checkbox"
                class="checkbox"
              />
              {{ $t('SHOPIFY_PANEL.REFUND_MODAL.RESTOCK_ITEMS') }}
            </label>
          </div>
          <div>
            <input
              id="refundReason"
              v-model="refundReason"
              type="text"
              :disabled="hasMultipleTransactions"
              class="mb-0"
              :placeholder="$t('SHOPIFY_PANEL.REFUND_MODAL.REFUND_REASON')"
            />
          </div>
        </div>
        <callout
          v-if="hasMultipleTransactions"
          is-warning
          role="alert"
          :desc="$t('SHOPIFY_PANEL.REFUND_MODAL.MULTIPLE_TRANSACTIONS_WARNING')"
          class="mb-4"
        />
        <callout
          v-if="refundError"
          is-danger
          role="alert"
          :desc="refundError"
          class="mb-4"
        />
      </div>
      <div class="order-refund-summary">
        <div class="order-refund-summary-content">
          <div
            class="text-primary dark:text-primary-dark mb-4 text-xl font-semibold uppercase tracking-[1px]"
          >
            Summary
          </div>
          <table>
            <tbody>
              <tr>
                <td>Items subtotal</td>
                <td class="text-right">
                  {{ calcItemsSubtotal() }}
                </td>
              </tr>
              <tr>
                <td>Tax</td>
                <td class="text-right">
                  {{ amountWithCurrency(order.current_total_tax || 0.0) }}
                </td>
              </tr>
              <tr>
                <td>Discounts</td>
                <td class="text-right">
                  {{ amountWithCurrency(order.current_total_discounts || 0.0) }}
                </td>
              </tr>
              <tr>
                <td>
                  Shipping
                  <span class="text-secondary dark:text-secondary-dark">
                    ({{ amountWithCurrency(refundableShippingAmount) }})
                  </span>
                </td>
                <td class="text-right">
                  <input
                    id="shippingAmount"
                    v-model="shippingAmount"
                    type="number"
                    :disabled="
                      hasMultipleTransactions || refundableShippingAmount == 0
                    "
                    :max="refundableShippingAmount"
                    class="mb-0 w-16 float-right text-right"
                    @input="fetchRefundInfo"
                  />
                </td>
              </tr>
              <tr class="refund-total-wrapper">
                <td>
                  <div class="font-semibold">Refund total</div>
                </td>
                <td class="text-right font-semibold">
                  {{ calcRefundTotal() }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-28">
          <form class="w-full">
            <label>
              <div class="mb-1">
                {{ $t('SHOPIFY_PANEL.REFUND_MODAL.MANUAL_AMOUNT') }}
              </div>
              <input
                v-model.number="manualAmount"
                type="text"
                class="mb-0"
                :disabled="hasMultipleTransactions"
              />
            </label>
            <p class="text-sm text-secondary dark:text-secondary-dark mt-1">
              {{
                $t('SHOPIFY_PANEL.REFUND_MODAL.AVAILABLE_REFUND', {
                  availableRefund: amountWithCurrency(refundableAmount),
                })
              }}
            </p>
          </form>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <label for="sendNotification">
        <input
          id="sendNotification"
          v-model="sendNotification"
          :disabled="hasMultipleTransactions"
          type="checkbox"
          value="false"
        />
        {{ $t('SHOPIFY_PANEL.REFUND_MODAL.SEND_NOTIFICATION') }}
      </label>

      <button
        :disabled="isRefundDisabled"
        class="button primary"
        @click="onSubmit"
      >
        <span class="mr-1">
          {{ $t('SHOPIFY_PANEL.REFUND') }} {{ actualRefundAmount() }}
        </span>
        <spinner v-if="isRefunding" color-scheme="primary" size="small" />
      </button>
    </div>
  </woot-modal>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { REFUND_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import Spinner from 'shared/components/Spinner.vue';
import Callout from 'shared/components/Callout.vue';
import shopifyMixin from 'dashboard/mixins/shopifyMixin';

export default {
  components: {
    Spinner,
    Callout,
  },
  mixins: [alertMixin, shopifyMixin],
  props: {
    order: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sendNotification: false,
      restockItems: false,
      refundReason: '',
      manualAmount: 0.0,
      shippingAmount: 0.0,
      isRefunding: false,
      refundableAmount: 0.0,
      refundTotalAmount: 0.0,
      hasMultipleTransactions: false,
      refundableShippingAmount: 0.0,
      refundError: '',
      refundItems: this.order.line_items.map(
        item => {
          return { ...item, refund_quantity: 0 };
        } // pass prop "order.line_items" to data and add new property "refund_quantity" to calc refund total
      ),
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      uiFlags: 'refund/getUIFlags',
    }),
    modelTitle() {
      return `${this.$t('SHOPIFY_PANEL.REFUND_MODAL.TITLE')} ${
        this.order.name
      }`;
    },
    console: () => console,
    moment: () => moment,
    isRefundDisabled() {
      return (
        (this.refundTotalAmount === 0 && this.manualAmount === 0) ||
        this.isRefunding ||
        this.hasMultipleTransactions
      );
    },
  },
  mounted() {
    this.fetchRefundInfo();
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    amountWithCurrency(amount) {
      return `${this.currency_symbol(this.order.currency)}${amount}`;
    },
    calcRefundTotal() {
      return this.amountWithCurrency(this.refundTotalAmount);
    },
    actualRefundAmount() {
      if (this.manualAmount > 0) {
        return this.amountWithCurrency(this.manualAmount);
      }
      return this.calcRefundTotal();
    },
    calcItemsSubtotal() {
      let totalPrice = 0.0;
      // eslint-disable-next-line
      for (let item of this.refundItems) {
        totalPrice += item.quantity * item.price;
      }
      return this.amountWithCurrency(totalPrice);
    },
    async onSubmit() {
      this.refundError = '';
      this.isRefunding = true;
      this.$track(REFUND_EVENTS.CREATE);
      const data = {
        send_notification: this.sendNotification,
        restock_items: this.restockItems,
        refund_reason: this.refundReason,
        manual_amount: this.manualAmount,
        refund_items: this.refundItems,
        order_id: this.order.id,
        shipping_amount: this.shippingAmount,
        full_refund: false,
      };

      await this.$store
        .dispatch('refund', {
          conversationId: this.currentChat.id,
          params: data,
        })
        .then(() => {
          this.showAlert(
            this.$t('SHOPIFY_PANEL.REFUND_MODAL.FORM.SUCCESS_MESSAGE')
          );
          this.$store.dispatch('fetchShopifyData', this.currentChat.id);
          this.onClose();
        })
        .catch(error => {
          const errorMessage = error.response
            ? error.response.data.error
            : undefined;
          this.refundError = errorMessage;
          this.showAlert(this.$t('CANNED_MGMT.EDIT.API.ERROR_MESSAGE'));
        });
      this.isRefunding = false;
    },

    async fullRefundSubmit() {
      this.isRefunding = true;
      this.$track(REFUND_EVENTS.CREATE);
      const data = {
        send_notification: this.sendNotification,
        restock_items: this.restockItems,
        refund_reason: this.refundReason,
        manual_amount: this.manualAmount,
        refund_items: this.refundItems,
        order_id: this.order.id,
        shipping_amount: this.shippingAmount,
        full_refund: true,
      };

      await this.$store
        .dispatch('refund', {
          conversationId: this.currentChat.id,
          params: data,
        })
        .then(() => {
          this.showAlert(
            this.$t('SHOPIFY_PANEL.REFUND_MODAL.FORM.SUCCESS_MESSAGE')
          );
          this.$store.dispatch('fetchShopifyData', this.currentChat.id);
        })
        .catch(() => {
          this.showAlert(
            this.$t('SHOPIFY_PANEL.REFUND_MODAL.FORM.ERROR_MESSAGE')
          );
        });
      this.isRefunding = false;
      this.onClose();
    },

    async fetchRefundInfo() {
      if (!this.currentChat.id) {
        return;
      }
      await this.$store
        .dispatch('refundInfo', {
          conversationId: this.currentChat.id,
          params: {
            order_id: this.order.id,
            refund_reason: this.refundReason,
            refund_items: this.refundItems,
            shipping_amount: this.shippingAmount,
          },
        })
        .then(result => {
          this.refundableAmount = result.refundable_amount;
          this.hasMultipleTransactions = result.has_multiple_transactions;
          this.refundableShippingAmount = result.refundable_shipping_amount;
          this.refundTotalAmount = result.refund_total_amount;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
// Adjust modal container
::v-deep {
  .modal-container {
    @apply w-[85%] xl:w-[1150px] 2xl:w-[1200px] max-h-[90vh] rounded-md;
  }
}

.modal-container {
  input[type='checkbox'] {
    @apply mb-0 mr-1;
  }

  form {
    @apply p-0;
  }

  .modal-footer {
    @apply flex items-center justify-end px-8 py-4 bg-neutral-subtle dark:bg-primary;
  }

  .modal-body {
    @apply px-8 pt-4 pb-8;
  }
}

.order-refund {
  @apply flex gap-x-8 px-8;
}

.order-refund-content {
  @apply basis-3/4 grow h-full flex flex-col;
}

.order-refund-summary {
  @apply basis-1/4 bg-neutral-subtle dark:bg-neutral-dark-medium rounded-lg p-5 flex flex-col h-full sticky top-6 mt-6 mb-6;

  table {
    td {
      @apply px-0;
    }
  }
  tr {
    @apply border-0;
  }
  tr.refund-total-wrapper {
    @apply border-t border-neutral-high dark:border-neutral-dark-high;
  }
}

.order-refund-summary-content {
  @apply grow overflow-y-auto;
}

.order-summary-foot {
  @apply flex justify-between items-center pb-4 pt-4 border-t border-neutral-high dark:border-neutral-dark-high;
}

.order-summary {
  @apply flex gap-x-1 flex-wrap;
}

.order-summary-actions {
  @apply flex justify-end pt-2 pb-2;
}

.kpi {
  @apply flex flex-col basis-1 grow bg-neutral-faint dark:bg-neutral-dark-faint rounded-lg px-3 py-2;

  &__headline {
    @apply text-lg text-primary dark:text-primary-dark;
  }

  &__tagline {
    @apply text-[13px] text-secondary dark:text-secondary-dark;
  }
}

.tag-wrapper {
  @apply flex flex-wrap items-center justify-end w-full;

  &__item {
    @apply flex items-center ml-3 mb-1.5 mt-1.5;
  }

  &__label {
    @apply flex items-center bg-secondary-low dark:bg-secondary-dark-low text-primary dark:text-stategrey text-[13px] pl-2 pr-2 border-0 rounded-[2px] select-none;

    &--warning {
      @apply dark:text-stategrey text-[#746D2D] bg-yellow-100 dark:bg-[#403D20];
    }
  }

  &__icon {
    @apply relative w-[10px] h-[10px] ml-2.5 p-[6px] transition-all ease-in-out duration-200 cursor-pointer rounded-[1px] text-primary dark:text-stategrey;

    &:hover {
      @apply bg-neutral-subtle dark:bg-[#25292B];
    }

    svg {
      @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }
  }
}
</style>
