<template>
  <div class="flex-grow flex-shrink min-w-0 p-6 overflow-auto">
    <form v-if="!uiFlags.isFetchingItem" @submit.prevent="updateAccount">
      <div
        class="flex flex-row p-4 border-b border-slate-25 dark:border-slate-800"
      >
        <div
          class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 py-4 pr-6 pl-0"
        >
          <h4 class="block-title text-black-900 dark:text-slate-200">
            {{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.TITLE') }}
          </h4>
          <p>{{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.NOTE') }}</p>
        </div>
        <div class="p-4 flex-grow-0 flex-shrink-0 flex-[50%]">
          <label :class="{ error: $v.name.$error }">
            {{ $t('GENERAL_SETTINGS.FORM.NAME.LABEL') }}
            <input
              v-model="name"
              type="text"
              :placeholder="$t('GENERAL_SETTINGS.FORM.NAME.PLACEHOLDER')"
              @blur="$v.name.$touch"
            />
            <span v-if="$v.name.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.NAME.ERROR') }}
            </span>
          </label>
          <label :class="{ error: $v.locale.$error }">
            {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.LABEL') }}
            <select v-model="locale">
              <option
                v-for="lang in languagesSortedByCode"
                :key="lang.iso_639_1_code"
                :value="lang.iso_639_1_code"
              >
                {{ lang.name }}
              </option>
            </select>
            <span v-if="$v.locale.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.ERROR') }}
            </span>
          </label>
          <label v-if="featureInboundEmailEnabled">
            {{ $t('GENERAL_SETTINGS.FORM.FEATURES.INBOUND_EMAIL_ENABLED') }}
          </label>
          <label v-if="featureCustomReplyDomainEnabled">
            {{
              $t('GENERAL_SETTINGS.FORM.FEATURES.CUSTOM_EMAIL_DOMAIN_ENABLED')
            }}
          </label>
          <label v-if="featureCustomReplyDomainEnabled">
            {{ $t('GENERAL_SETTINGS.FORM.DOMAIN.LABEL') }}
            <input
              v-model="domain"
              type="text"
              :placeholder="$t('GENERAL_SETTINGS.FORM.DOMAIN.PLACEHOLDER')"
            />
          </label>
          <label v-if="featureCustomReplyEmailEnabled">
            {{ $t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.LABEL') }}
            <input
              v-model="supportEmail"
              type="text"
              :placeholder="
                $t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.PLACEHOLDER')
              "
            />
          </label>
          <label
            v-if="showAutoResolutionConfig"
            :class="{ error: $v.autoResolveDuration.$error }"
          >
            {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.LABEL') }}
            <input
              v-model="autoResolveDuration"
              type="number"
              :placeholder="
                $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.PLACEHOLDER')
              "
              @blur="$v.autoResolveDuration.$touch"
            />
            <span v-if="$v.autoResolveDuration.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.ERROR') }}
            </span>
          </label>
          <label
            v-if="showOrderAttributionConfig"
            :class="{ error: $v.agentOrderAttributionDays.$error }"
          >
            {{ $t('GENERAL_SETTINGS.FORM.AGENT_ORDER_ATTRIBUTION_DAYS.LABEL') }}
            <input
              v-model="agentOrderAttributionDays"
              type="number"
              @blur="$v.agentOrderAttributionDays.$touch"
            />
            <span v-if="$v.agentOrderAttributionDays.$error" class="message">
              {{
                $t('GENERAL_SETTINGS.FORM.AGENT_ORDER_ATTRIBUTION_DAYS.ERROR')
              }}
            </span>
          </label>
        </div>
      </div>

      <div
        class="p-4 border-slate-25 dark:border-slate-700 text-black-900 dark:text-slate-300 row"
      >
        <div
          class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 py-4 pr-6 pl-0"
        >
          <h4 class="block-title text-black-900 dark:text-slate-200">
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.TITLE') }}
          </h4>
          <p>
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.NOTE') }}
          </p>
        </div>
        <div class="p-4 flex-grow-0 flex-shrink-0 flex-[50%]">
          <copy-content full-width as-field :content="getAccountId" />
        </div>
      </div>
      <div class="p-4 row">
        <div
          class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 py-4 pr-6 pl-0"
        >
          <h4 class="block-title text-black-900 dark:text-slate-200">
            {{ $t('GENERAL_SETTINGS.EXPORT.TITLE') }}
          </h4>
          <p>
            {{ $t('GENERAL_SETTINGS.EXPORT.DESCRIPTION') }}
          </p>
        </div>
        <div class="p-4 flex-grow-0 flex-shrink-0 flex-[50%]">
          <multiselect
            v-model="exportPeriod"
            class="mb-2"
            track-by="name"
            label="name"
            :placeholder="$t('GENERAL_SETTINGS.EXPORT.PLACEHOLDER')"
            selected-label
            :select-label="$t('GENERAL_SETTINGS.EXPORT.SELECT_LABEL')"
            :options="exportPeriodOptions"
            :searchable="false"
            :allow-empty="false"
          />

          <woot-button @click.prevent="startConversationsExport()">
            {{ $t('GENERAL_SETTINGS.EXPORT.TITLE') }}
          </woot-button>
        </div>
      </div>

      <!-- Migrate -->
      <div ref="gorgias" class="p-4 row">
        <div
          class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 py-4 pr-6 pl-0"
        >
          <h4 class="block-title text-black-900 dark:text-slate-200">
            {{ $t('GENERAL_SETTINGS.GORGIAS_IMPORTER') }}
          </h4>
          <p>
            {{ $t('GENERAL_SETTINGS.GORGIAS_IMPORTER_DESC') }}
          </p>
        </div>
        <div class="p-4 flex-grow-0 flex-shrink-0 flex-[50%]">
          <gorgias-importer />
        </div>
      </div>
      <div class="p-4 row">
        <div
          class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 py-4 pr-6 pl-0"
        >
          <h4 class="block-title text-black-900 dark:text-slate-200">
            {{ $t('GENERAL_SETTINGS.ZENDESK_IMPORTER') }}
          </h4>
          <p>
            {{ $t('GENERAL_SETTINGS.ZENDESK_IMPORTER_DESC') }}
          </p>
        </div>
        <div class="p-4 flex-grow-0 flex-shrink-0 flex-[50%]">
          <zendesk-importer />
        </div>
      </div>
      <div class="p-4 row">
        <div
          class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 py-4 pr-6 pl-0"
        >
          <h4 class="block-title text-black-900 dark:text-slate-200">
            {{ $t('GENERAL_SETTINGS.REAMAZE_IMPORTER') }}
          </h4>
          <p>
            {{ $t('GENERAL_SETTINGS.REAMAZE_IMPORTER_DESC') }}
          </p>
        </div>
        <div class="p-4 flex-grow-0 flex-shrink-0 flex-[50%]">
          <reamaze-importer />
        </div>
      </div>

      <woot-submit-button
        class="button success small button--fixed-top"
        :button-text="$t('GENERAL_SETTINGS.SUBMIT')"
        :loading="isUpdating"
      />
    </form>

    <woot-loading-state v-if="uiFlags.isFetchingItem" />
  </div>
</template>

<script>
import {
  required,
  minValue,
  maxValue,
  integer,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import accountMixin from '../../../../mixins/account';
import { FEATURE_FLAGS } from '../../../../featureFlags';
import semver from 'semver';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { getLanguageDirection } from 'dashboard/components/widgets/conversation/advancedFilterItems/languages';
import GorgiasImporter from './GorgiasImporter';
import ReamazeImporter from './ReamazeImporter';
import ZendeskImporter from './ZendeskImporter';
import CopyContent from '../inbox/components/CopyContent';

const AGENT_ORDER_ATTRIBUTION_MAX_DAYS = 5;

export default {
  components: { GorgiasImporter, ReamazeImporter, ZendeskImporter, CopyContent },
  mixins: [accountMixin, alertMixin, configMixin, uiSettingsMixin],
  beforeRouteUpdate(to, from, next) {
    next();
    this.$nextTick(() => {
      this.handleScroll();
    });
  },
  data() {
    return {
      id: '',
      name: '',
      locale: 'en',
      domain: '',
      supportEmail: '',
      features: {},
      autoResolveDuration: null,
      agentOrderAttributionDays: null,
      latestChatwootVersion: null,
      exportPeriod: null,
    };
  },
  validations: {
    name: {
      required,
    },
    locale: {
      required,
    },
    autoResolveDuration: {
      minValue: minValue(1),
      maxValue: maxValue(999),
    },
    agentOrderAttributionDays: {
      minValue: minValue(0),
      maxValue: maxValue(AGENT_ORDER_ATTRIBUTION_MAX_DAYS),
      integer,
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      getAccount: 'accounts/getAccount',
      uiFlags: 'accounts/getUIFlags',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    showAutoResolutionConfig() {
      return this.isFeatureEnabledonAccount(
        this.accountId,
        FEATURE_FLAGS.AUTO_RESOLVE_CONVERSATIONS
      );
    },
    showOrderAttributionConfig() {
      return true;
    },
    hasAnUpdateAvailable() {
      if (!semver.valid(this.latestChatwootVersion)) {
        return false;
      }

      return semver.lt(
        this.globalConfig.appVersion,
        this.latestChatwootVersion
      );
    },
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    isUpdating() {
      return this.uiFlags.isUpdating;
    },

    featureInboundEmailEnabled() {
      return !!this.features.inbound_emails;
    },

    featureCustomReplyDomainEnabled() {
      return (
        this.featureInboundEmailEnabled && !!this.features.custom_reply_domain
      );
    },

    featureCustomReplyEmailEnabled() {
      return (
        this.featureInboundEmailEnabled && !!this.features.custom_reply_email
      );
    },

    getAccountId() {
      return this.id.toString();
    },

    exportPeriodOptions() {
      return [
        {
          id: 'LAST_7_DAYS',
          name: this.$t('GENERAL_SETTINGS.EXPORT.PERIOD.LAST_7_DAYS'),
        },
        {
          id: 'LAST_30_DAYS',
          name: this.$t('GENERAL_SETTINGS.EXPORT.PERIOD.LAST_30_DAYS'),
        },
        {
          id: 'LAST_3_MONTHS',
          name: this.$t('GENERAL_SETTINGS.EXPORT.PERIOD.LAST_3_MONTHS'),
        },
        {
          id: 'LAST_6_MONTHS',
          name: this.$t('GENERAL_SETTINGS.EXPORT.PERIOD.LAST_6_MONTHS'),
        },
        {
          id: 'LAST_YEAR',
          name: this.$t('GENERAL_SETTINGS.EXPORT.PERIOD.LAST_YEAR'),
        },
        {
          id: 'FULL_HISTORY',
          name: this.$t('GENERAL_SETTINGS.EXPORT.PERIOD.FULL_HISTORY'),
        },
      ];
    },
  },
  watch: {
    '$route.query.onboarding': {
      handler() {
        this.handleScroll();
      },
      immediate: true,
    },
  },
  mounted() {
    this.initializeAccount();
    this.$nextTick(() => {
      this.handleScroll();
    });
  },
  methods: {
    async initializeAccount() {
      try {
        const {
          name,
          locale,
          id,
          domain,
          support_email,
          features,
          auto_resolve_duration,
          agent_order_attribution_days,
          shops,
          latest_chatwoot_version: latestChatwootVersion,
        } = this.getAccount(this.accountId);

        this.$root.$i18n.locale = locale;
        this.name = name;
        this.locale = locale;
        this.id = id;
        this.domain = domain;
        this.supportEmail = support_email;
        this.features = features;
        this.shops = shops;
        this.autoResolveDuration = auto_resolve_duration;
        this.agentOrderAttributionDays = agent_order_attribution_days;
        this.latestChatwootVersion = latestChatwootVersion;
      } catch (error) {
        // Ignore error
      }
    },

    async updateAccount() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('GENERAL_SETTINGS.FORM.ERROR'));
        return;
      }
      try {
        await this.$store.dispatch('accounts/update', {
          locale: this.locale,
          name: this.name,
          domain: this.domain,
          support_email: this.supportEmail,
          auto_resolve_duration: this.autoResolveDuration,
          agent_order_attribution_days: this.agentOrderAttributionDays,
        });
        this.$root.$i18n.locale = this.locale;
        this.getAccount(this.id).locale = this.locale;
        this.updateDirectionView(this.locale);
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'));
      }
    },

    updateDirectionView(locale) {
      const isRTLSupported = getLanguageDirection(locale);
      this.updateUISettings({
        rtl_view: isRTLSupported,
      });
    },

    async startConversationsExport() {
      if (!this.exportPeriod) {
        this.showAlert(this.$t('GENERAL_SETTINGS.EXPORT.SPECIFY_TIME_PERIOD'));
        return;
      }

      try {
        await this.$store.dispatch('exportConversations', this.exportPeriod.id);
        this.showAlert(this.$t('GENERAL_SETTINGS.EXPORT.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('GENERAL_SETTINGS.EXPORT.ERROR_MESSAGE')
        );
      }
    },

    handleScroll() {
      const sectionId = this.$route.query.onboarding;

      if (sectionId === 'gorgias') {
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.$refs.gorgias) {
              this.$refs.gorgias.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          }, 100);
        });
      }
    },
  },
};
</script>
