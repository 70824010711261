<template>
  <div>
    <!-- Instagram -->
    <div v-if="showInstagram" class="comment-head">
      <instagram-story :story-url="mediaUrl" class="rounded-md" />
      <table v-if="commentor" class="meta-table">
        <tr>
          <td width="15">
            {{ $t('COMMENT_HEADER.COMMENTOR') }}
          </td>
          <td>{{ commentor }}</td>
        </tr>
      </table>
    </div>
    <div v-else-if="showHead" class="comment-head">
      <div v-if="fbMediaUrl && !isReply" class="meta-thumb">
        <div v-if="isImageError" class="meta-thumb__error">
          {{ $t('CONVERSATION.IMAGE_ERROR') }}
        </div>
        <expandable-image
          v-else
          :is-show-toggle-text="isPortrait"
          :height="isPortrait ? `${portraitHeight}px` : 'initial'"
        >
          <bubble-image
            :url="fbMediaUrl"
            class="meta-thumb__image"
            @error="handleImageError"
            @load="handleImageLoaded"
          />
        </expandable-image>
      </div>
      <table v-if="post || commentor" class="meta-table">
        <tr v-if="!isReply" >
          <td width="15">
            {{ $t('COMMENT_HEADER.POST') }}
          </td>
          <td>
            <show-more :text="post" />
          </td>
        </tr>
        <tr>
          <td width="15">
            {{ $t('COMMENT_HEADER.COMMENTOR') }}
          </td>
          <td>{{ commentor }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import InstagramStory from './InstagramStory.vue';
import BubbleImage from './Image.vue';
import ExpandableImage from 'shared/components/ExpandableImage.vue';
import ShowMore from 'shared/components/ShowMore.vue';

export default {
  components: {
    InstagramStory,
    BubbleImage,
    ExpandableImage,
    ShowMore,
  },
  props: {
    commentAttributes: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isImageError: false,
      isPortrait: false,
      portraitHeight: 400, // 400 is longest height of portrait image based on figma
    };
  },
  computed: {
    commentor() {
      return this.commentAttributes.commentor || '';
    },
    post() {
      return this.commentAttributes.post || '';
    },
    showHead() {
      return this.commentor || this.post;
    },
    isReply() {
      return this.commentAttributes.is_reply || false;
    },
    mediaType() {
      return this.commentAttributes.media_type || '';
    },
    mediaUrl() {
      return this.commentAttributes.media_url || '';
    },
    showInstagram() {
      return this.mediaType || '';
    },
    fbMediaUrl() {
      return this.commentAttributes.media_url || '';
    },
  },
  methods: {
    handleImageError() {
      this.isImageError = true;
    },
    handleImageLoaded(width) {
      if (width < this.portraitHeight) {
        this.isPortrait = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.comment-head {
  @apply text-secondary dark:text-secondary-dark border-b pb-2 mb-2 border-secondary dark:border-secondary border-opacity-30 dark:border-opacity-30;
}

.meta-thumb {
  @apply w-full overflow-hidden text-sm text-secondary dark:text-secondary-dark;

  &__error {
    @apply flex items-center justify-center mt-0.5 mb-2.5 bg-neutral-subtle dark:bg-neutral-dark-subtle rounded-md h-[180px];
  }
}

.meta-table {
  @apply p-0 mb-0 w-full break-words;

  tr {
    td:first-child {
      @apply font-semibold pr-2.5;
    }
  }

  tr:last-child {
    td {
      @apply pb-0;
    }
  }

  td {
    @apply pb-1 align-top text-xs text-secondary dark:text-secondary-dark;
  }
}
</style>
