<!-- eslint-disable vue/no-mutating-props -->
<template>
  <woot-modal :show.sync="show" :on-close="onCancel" size="modal-fixed-height">
    <div class="create-conversation-modal">
      <div class="header border-b border-neutral-medium dark:border-neutral-dark-medium">
        <woot-modal-header
          :header-title="$t('NEW_CREATE_CONVERSATION.TITLE')"
        />
      </div>
      <create-conversation-form
        :inboxes="inboxes"
        :processed-message-content="processedMessageContent"
        :message-email-from="messageEmailFrom"
        :message-original-sender="messageOriginalSender"
        :on-submit="onSubmit"
        @success="onCancel"
        @cancel="onCancel"
      />
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import CreateConversationForm from 'dashboard/components/widgets/conversation/CreateConversationForm.vue';

export default {  
  mixins: [alertMixin],
  components: {
    CreateConversationForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    messageEmailFrom: {
      type: String,
      default: '',
    },
    messageOriginalSender: {
      type: String,
      default: '',
    },
    processedMessageContent: {
      type: String,
      default: '',
    },
  },
  validations: {
    form: {
      recipient: { required },
    },
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
    }),
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    async onSubmit(payload, isFromWhatsApp) {
      const data = await this.$store.dispatch('contactConversations/create', {
        params: payload,
        isFromWhatsApp,
      });
      return data;
    },
    resetValidation(field) {
      this.$v.contact[field].$reset();
    },
  },
};
</script>
<style scoped lang="scss">
@import '~dashboard/assets/scss/mixins';

.create-conversation-modal {
  @apply flex flex-col h-full border border-neutral-medium dark:border-neutral-dark-medium;

  .header {
    @apply sticky top-0 bg-white dark:bg-primary z-10;

    .woot-modal-header {
      @apply p-4;
    }
  }

  .content {
    @apply flex flex-col p-0 flex-1 min-h-0 overflow-hidden;
  }

  ::v-deep .emoji-dialog {
    // Change position to be above the trigger button
    @apply fixed md:left-[20%] left-[20px] bottom-[25%] md:bottom-[22%];

    &::before {
      $space-slab: 12px;

      @media (prefers-color-scheme: dark) {
        $color-bg-dark: #26292b;
        @include arrow(bottom, $color-bg-dark, $space-slab);
      }
      @media (prefers-color-scheme: light) {
        $color-bg: #ebf0f5;
        @include arrow(bottom, $color-bg, $space-slab);
      }

      // Adjust arrow position to bottom
      @apply -bottom-3 fixed right-5 ;
    }
  }
}
</style>
