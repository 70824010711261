<!-- eslint-disable vue/no-mutating-props -->
<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header
        :header-title="$t('NEW_CONVERSATION.TITLE')"
        :header-content="$t('NEW_CONVERSATION.DESC')"
      />
      <div v-if="step === 1">
        <form @submit.prevent="onCreateContactSubmit">
          <div>
            <label :class="{ error: $v.contact.email.$error }">
              {{ $t('CONTACT_FORM.FORM.EMAIL_ADDRESS.LABEL') }}
              <input
                v-model.trim="contact.email"
                type="text"
                :placeholder="$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.PLACEHOLDER')"
                @input="resetValidation('email')"
              />
              <span v-if="$v.contact.email.$error" class="message">
                {{ $t('CONTACT_FORM.FORM.EMAIL_ADDRESS.ERROR') }}
              </span>
            </label>
          </div>
          <div v-if="!contactExists">
            <label :class="{ error: $v.contact.name.$error }">
              {{ $t('CONTACT_FORM.FORM.NAME.LABEL') }}
              <input
                v-model.trim="contact.name"
                type="text"
                :placeholder="$t('CONTACT_FORM.FORM.NAME.PLACEHOLDER')"
                @input="resetValidation('name')"
              />
              <span v-if="$v.contact.name.error" class="message">
                {{ $v.contact.name.error }}
              </span>
            </label>
          </div>
          <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
            <woot-button type="submit">
              {{ contactExists ? 'Next' : 'Create Contact' }}
            </woot-button>
          </div>
        </form>
      </div>
      <div v-if="step === 2">
        <conversation-form
          :processed-message-content="processedMessageContent"
          :message-email-from="messageEmailFrom"
          :message-original-sender="messageOriginalSender"
          :contact="contact"
          :on-submit="onSubmit"
          @success="onSuccess"
          @cancel="onCancel"
        />
      </div>
    </div>
  </woot-modal>
</template>

<script>
import ConversationForm from 'dashboard/routes/dashboard/conversation/contact/ConversationForm';
import { required } from 'vuelidate/lib/validators';
import { DuplicateContactException } from 'shared/helpers/CustomErrors';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    ConversationForm,
  },
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    messageEmailFrom: {
      type: String,
      default: '',
    },
    messageOriginalSender: {
      type: String,
      default: '',
    },
    processedMessageContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      step: 1,
      contactExists: true,
      contact: {
        email: '',
        name: '',
      },
    };
  },
  validations: {
    contact: {
      email: { required },
      name: { required },
    },
  },
  methods: {
    async onCreateContactSubmit() {
      this.$v.$touch();
      if (this.$v.contact.email.$invalid) {
        return;
      }
      const exists = await this.checkIfContactExists(this.contact.email);
      if (!exists) {
        this.contactExists = false;
        if (!this.$v.contact.email.$invalid && !this.$v.contact.name.$invalid) {
          await this.createContact();
        }
      } else {
        await this.proceedToNextStep();
      }
    },
    async proceedToNextStep() {
      this.step = 2;
    },
    async createContact() {
      try {
        const data = await this.$store.dispatch('contacts/create', {
          name: this.contact.name,
          email: this.contact.email || '',
        });
        const response = await this.$store.dispatch(
          'contacts/getContactableInboxes',
          {
            contactId: data.id,
          }
        );
        this.contact = {
          id: data.id,
          name: data.name,
          email: data.email,
          contactableInboxes: response.data.payload,
        };
        this.step = 2;
      } catch (error) {
        if (error instanceof DuplicateContactException) {
          if (error.data.includes('email')) {
            this.showAlert(
              this.$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.DUPLICATE')
            );
          }
        }
      }
    },
    async checkIfContactExists(email) {
      try {
        const response = await this.$store.dispatch('contacts/search', {
          search: email,
        });

        if (response.meta.count !== 0) {
          const contact = response.payload[0];
          const contactId = contact.id;
          const inboxesResponse = await this.$store.dispatch(
            'contacts/getContactableInboxes',
            {
              contactId,
            }
          );
          if (inboxesResponse) {
            this.contact = {
              id: contact.id,
              name: contact.name,
              email: contact.email,
              contactableInboxes: inboxesResponse.data.payload,
            };
          }
          return true;
        }
      } catch (error) {
        // Handle error
      }
      return false;
    },
    onCancel() {
      this.$emit('cancel');
      this.step = 1;
      this.contact = {};
      this.contactExists = true;
      this.resetValidation('name');
      this.resetValidation('email');
    },
    onSuccess() {
      this.$emit('cancel');
      this.step = 1;
      this.contact = {};
      this.contactExists = true;
      this.resetValidation('name');
      this.resetValidation('email');
    },
    async onSubmit(params, isFromWhatsApp) {
      const data = await this.$store.dispatch('contactConversations/create', {
        params,
        isFromWhatsApp,
      });
      return data;
    },
    resetValidation(field) {
      this.$v.contact[field].$reset();
    },
  },
};
</script>
