import {
  MESSAGE_TYPE,
  CONVERSATION_PRIORITY_ORDER,
} from 'shared/constants/messages';
import { applyPageFilters } from './helpers';
// import StateBlock from 'markdown-it/lib/rules_block/state_block.mjs';
import wootConstants from 'dashboard/constants/globals';

// Map tab types to their corresponding stats properties
const statsCountMap = {
  [wootConstants.ASSIGNEE_TYPE.ME]: 'mineCount',
  [wootConstants.ASSIGNEE_TYPE.UNASSIGNED]: 'unAssignedCount',
  [wootConstants.ASSIGNEE_TYPE.ALL]: 'allCount'
};

export const getSelectedChatConversation = ({
  allConversations,
  selectedChatId,
}) =>
  allConversations.filter(conversation => conversation.id === selectedChatId);

const sortComparator = {
  latest: (a, b) => b.last_activity_at - a.last_activity_at,
  sort_on_created_at: (a, b) => a.created_at - b.created_at,
  sort_on_priority: (a, b) => {
    return (
      CONVERSATION_PRIORITY_ORDER[a.priority] -
      CONVERSATION_PRIORITY_ORDER[b.priority]
    );
  },
  sort_on_waiting_since: (a, b) => {
    if (!a.waiting_since && !b.waiting_since) {
      return a.created_at - b.created_at;
    }

    if (!a.waiting_since) {
      return 1;
    }

    if (!b.waiting_since) {
      return -1;
    }

    return a.waiting_since - b.waiting_since;
  },
};

// getters
const getters = {
  getAllConversations: ({ allConversations, chatSortFilter }) => {
    return allConversations.sort(sortComparator[chatSortFilter]);
  },
  getSelectedChat: ({ selectedChatId, allConversations }) => {
    const selectedChat = allConversations.find(
      conversation => conversation.id === selectedChatId
    );
    return selectedChat || {};
  },
  getSelectedChatAttachments: (_state, _getters) => {
    const selectedChat = _getters.getSelectedChat;
    return selectedChat.attachments || [];
  },
  getLastEmailInSelectedChat: (stage, _getters) => {
    const selectedChat = _getters.getSelectedChat;
    const { messages = [] } = selectedChat;
    const lastEmail = [...messages].reverse().find(message => {
      const {
        content_attributes: contentAttributes = {},
        message_type: messageType,
      } = message;
      const { email = {} } = contentAttributes;
      const isIncomingOrOutgoing =
        messageType === MESSAGE_TYPE.OUTGOING ||
        messageType === MESSAGE_TYPE.INCOMING;
      if (email.from && isIncomingOrOutgoing) {
        return true;
      }
      return false;
    });

    return lastEmail;
  },
  getMineChats: (_state, _, __, rootGetters) => activeFilters => {
    const currentUserID = rootGetters.getCurrentUser?.id;

    return _state.allConversations.filter(conversation => {
      const { assignee } = conversation.meta;
      const isAssignedToMe = assignee && assignee.id === currentUserID;
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      const isChatMine = isAssignedToMe && shouldFilter;

      return isChatMine;
    });
  },
  getAppliedConversationFilters: _state => {
    return _state.appliedFilters;
  },
  getUnAssignedChats: _state => activeFilters => {
    return _state.allConversations.filter(conversation => {
      const isUnAssigned = !conversation.meta.assignee;
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      return isUnAssigned && shouldFilter;
    });
  },

  getAllStatusChats: _state => activeFilters => {
    return _state.allConversations.filter(conversation => {
      const shouldFilter = applyPageFilters(conversation, activeFilters);
      return shouldFilter;
    });
  },
  getChatListLoadingStatus: ({ listLoadingStatus }) => listLoadingStatus,
  getAllMessagesLoaded(_state) {
    const [chat] = getSelectedChatConversation(_state);
    return !chat || chat.allMessagesLoaded === undefined
      ? false
      : chat.allMessagesLoaded;
  },
  getUnreadCount(_state) {
    const [chat] = getSelectedChatConversation(_state);
    if (!chat) return [];
    return chat.messages.filter(
      chatMessage =>
        chatMessage.created_at * 1000 > chat.agent_last_seen_at * 1000 &&
        chatMessage.message_type === 0 &&
        chatMessage.private !== true
    ).length;
  },
  getChatStatusFilter: ({ chatStatusFilter }) => chatStatusFilter,
  getChatSortFilter: ({ chatSortFilter }) => chatSortFilter,
  getSelectedInbox: ({ currentInbox }) => currentInbox,
  getConversationById: _state => conversationId => {
    return _state.allConversations.find(
      value => value.id === Number(conversationId)
    );
  },
  getConversationParticipants: _state => {
    return _state.conversationParticipants;
  },
  getConversationLastSeen: _state => {
    return _state.conversationLastSeen;
  },
  metaCommentHiddenStatus: _state => {
    return _state.metaCommentHiddenStatus;
  },
  metaCommentLikedStatus: _state => {
    return _state.metaCommentLikedStatus;
  },
  currentCustomView: _state => {
    if(!_state.selectedFolderId) return undefined

    return _state?.folders?.find(item => item.id === _state.selectedFolderId)
  },
  getRefreshConversationsFlag: _state => {
    return _state.isRefreshConversationsFlagActive;
  },
  isEndOfList: (state, rootState) => activeTab => {
    // Get stats from the conversationStats module to 
    // get the total count of conversations
    // they are: mine, unassigned, all
    const stats = rootState['conversationStats/getStats']

    // if it's appliedFilters, such as custom views/tickets, take the count from stats.mineCount
    const countKey = activeTab === 'appliedFilters' ? 'mineCount' : statsCountMap[activeTab];
    if (typeof stats[countKey] === 'undefined') {
      return false;
    }

    // Compare total count from stats with current conversations length
    return state.allConversations.length >= stats[countKey];
  },
  isInsidePlayground: (_, rootState) => {
    return rootState.route.name === 'ai_playground_detail';
  },
};

export default getters;
