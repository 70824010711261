<template>
    <div class="recipients">
        <div class="input-group-wrap">
            <div class="input-group small" :class="{ error: showToEmailError }">
                <label class="input-group-label">
                    {{ $t('NEW_CREATE_CONVERSATION.TO.LABEL') }}
                </label>
                <div class="input-group-field">
                    <multiselect
                        v-if="isFieldTaggable"
                        v-model="toEmailsLocal"
                        :options="contacts"
                        :options-limit="5"
                        :taggable="true"
                        :select-label="''"
                        is-searchable
                        :multiple="true"
                        track-by="email"
                        label="email"
                        :placeholder="$t('CONVERSATION.REPLYBOX.EMAIL_HEAD.CC.PLACEHOLDER')"
                    >
                        <template slot="option" slot-scope="props">
                            <div class="flex items-center justify-between w-full h-full px-3 py-[10px]">
                                <woot-thumbnail
                                    :src="props.option.thumbnail"
                                    :username="props.option.name"
                                    size="32px"
                                />  
                                <div class="flex flex-col h-full w-full pl-3">
                                    <div class="w-full">{{ props.option.name }}</div>
                                    <div class="w-full text-xs text-slate-500">{{ props.option.email }}</div>
                                </div>
                            </div>
                        </template>
                        <template slot="limit" slot-scope="props">
                            <div class="text-xs text-slate-500">
                                {{ `This is - ` +props.limit }}
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <span 
                v-if="showToEmailError" 
                class="message"
            >
                {{ getErrorMessage('toEmailsVal') }}
            </span>
        </div>
        <div class="input-group-wrap">
            <div class="input-group small" :class="{ error: $v.ccEmailsVal.$error }">
                <label class="input-group-label">
                {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.CC.LABEL') }}
                </label>
                <div class="input-group-field">
                    <multiselect
                        v-if="isFieldTaggable"
                        v-model="ccEmailsLocal"
                        :options="contacts"
                        :options-limit="5"
                        :taggable="true"
                        :select-label="''"
                        is-searchable
                        :multiple="true"
                        track-by="email"
                        label="email"
                        :placeholder="$t('CONVERSATION.REPLYBOX.EMAIL_HEAD.CC.PLACEHOLDER')"
                    >
                        <template slot="option" slot-scope="props">
                            <div class="flex items-center justify-between w-full h-full px-3 py-[10px]">
                                <woot-thumbnail
                                    :src="props.option.thumbnail"
                                    :username="props.option.name"
                                    size="32px"
                                />  
                                <div class="flex flex-col h-full w-full pl-3">
                                    <div class="w-full">{{ props.option.name }}</div>
                                    <div class="w-full text-xs text-slate-500">{{ props.option.email }}</div>
                                </div>
                            </div>
                        </template>
                        <template slot="limit" slot-scope="props">
                            <div class="text-xs text-slate-500">
                                {{ `This is - ` +props.limit }}
                            </div>
                        </template>
                    </multiselect>
                </div>
                <woot-button
                    v-if="!showBcc"
                    variant="clear"
                    size="small"
                    @click="handleAddBcc">
                {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.ADD_BCC') }}
                </woot-button>
            </div>
            <span v-if="$v.ccEmailsVal.$error" class="message">
                {{ getErrorMessage('ccEmailsVal') }}
            </span>
        </div>
        <div v-if="showBcc" class="input-group-wrap">
            <div class="input-group small" :class="{ error: $v.bccEmailsVal.$error }">
                <label class="input-group-label">
                {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.BCC.LABEL') }}
                </label>
                <div class="input-group-field">
                    <multiselect
                        v-model="bccEmailsLocal"
                        :options="contacts"
                        :options-limit="5"
                        :taggable="true"
                        :select-label="''"
                        is-searchable
                        :multiple="true"
                        track-by="email"
                        label="email"
                        :placeholder="$t('CONVERSATION.REPLYBOX.EMAIL_HEAD.CC.PLACEHOLDER')"
                    >
                        <template slot="option" slot-scope="props">
                            <div class="flex items-center justify-between w-full h-full px-3 py-[10px]">
                                <woot-thumbnail
                                    :src="props.option.thumbnail"
                                    :username="props.option.name"
                                    size="32px"
                                />  
                                <div class="flex flex-col h-full w-full pl-3">
                                    <div class="w-full">{{ props.option.name }}</div>
                                    <div class="w-full text-xs text-slate-500">{{ props.option.email }}</div>
                                </div>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <span v-if="$v.bccEmailsVal.$error" class="message">
                {{ getErrorMessage('bccEmailsVal') }}
            </span>
        </div>
    </div>
  </template>
  <script>
  import { validEmailsByComma } from './helpers/emailHeadHelper';
  
  export default {
    props: {
      contacts: {
        type: Array,
        default: () => [],
      },
      ccEmails: {
        type: Array,
        default: () => [],
      },
      bccEmails: {
        type: Array,
        default: () => [],
      },
      toEmails: {
        type: Array,
        default: () => [],
      },
      isFieldTaggable: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showBcc: false,
        toEmailsLocal: [],
        ccEmailsLocal: [],
        bccEmailsLocal: [],
      };
    },
    watch: {
      toEmails: {
        immediate: true,
        handler(value) {
          this.toEmailsLocal = value;
        },
      },
      ccEmails: {
        immediate: true,
        handler(value) {
          this.ccEmailsLocal = value;
        },
      },
      bccEmails: {
        immediate: true,
        handler(value) {
          this.bccEmailsLocal = value;
        },
      },
      toEmailsLocal(value) {
        this.$emit('update:toEmails', value);
      },
      ccEmailsLocal(value) {
        this.$emit('update:ccEmails', value);
      },
      bccEmailsLocal(value) {
        this.$emit('update:bccEmails', value);
      },
    },
    computed: {
        toEmailsVal() {
            return this.toEmailsLocal.map(item => item.email).join(',');
        },
        ccEmailsVal() {
            return this.ccEmailsLocal.map(item => item.email).join(',');    
        },
        bccEmailsVal() {
            return this.bccEmailsLocal.map(item => item.email).join(',');
        },
        showToEmailError() {
            return this.$v.toEmailsVal.$error;
        },
    },
    validations: {
      toEmailsVal: {
        hasValidEmails(value) {
          if(!value) return false;

          return validEmailsByComma(value);
        },
      },
      ccEmailsVal: {
        hasValidEmails(value) {
          return validEmailsByComma(value);
        },
      },
      bccEmailsVal: {
        hasValidEmails(value) {
          return validEmailsByComma(value);
        },
      },
    },
    methods: {
      handleAddBcc() {
        this.showBcc = true;
        this.$emit('showBcc');
      },
      onBlur() {
        this.$v.$touch();
        this.$emit('update:bccEmails', this.bccEmailsLocal);
        this.$emit('update:ccEmails', this.ccEmailsLocal);
        this.$emit('update:toEmails', this.toEmailsLocal);
      },
      getErrorMessage(type) {
        const fieldValidation = type === 'toEmailsVal' ? 
            this.$v.toEmailsVal : type === 'ccEmailsVal' ? this.$v.ccEmailsVal : this.$v.bccEmailsVal;
        
        if (fieldValidation.$invalid) {
            return this.$t('NEW_CREATE_CONVERSATION.INVALID_EMAIL');
        }

        return this.$t('NEW_CREATE_CONVERSATION.REQUIRED_EMAIL')
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .recipients {
    span.message {
        @apply text-xs;
    }

    .input-group.error {
        ::v-deep {
            .multiselect {
                @apply border-transparent;
                
                .multiselect__tags {
                    @apply border-none;
                }
            }
        }
    }
    
    ::v-deep .multiselect__tags {
      @apply p-0 border-none flex flex-wrap;

      &-wrap {
        @apply flex flex-wrap gap-2 py-2;
      }
    }

    ::v-deep .multiselect__tag {
      @apply py-1 pl-2 pr-6 mr-0 text-xs my-0 leading-[15px] h-fit;
    }

    ::v-deep .multiselect__input {
      @apply text-xs text-secondary;
    }

    ::v-deep .multiselect {
      @apply mb-0 border-transparent;

      &__placeholder {
        @apply text-xs;
      }

      &__select {
        @apply hidden;
      }
    }
  }

  .input-group-wrap .message {
    @apply text-sm text-red-500 dark:text-red-500;
  }

  .input-group {
    @apply border-b border-solid border-slate-75 dark:border-slate-700 my-1;
  
    .input-group-label {
      @apply border-transparent bg-transparent text-xs font-semibold pl-0 min-w-[30px];
    }
    .input-group-field::v-deep input {
      @apply mb-0 border-transparent;
    }

  }
  
  .input-group.error {
    @apply border-b-red-500 dark:border-b-red-500;
    .input-group-label {
      @apply text-red-500 dark:text-red-500;
    }
  }
  </style>
  