import wootConstants from 'dashboard/constants/globals';

export default {
  data() {
    return {
      isMobileDevice: false,
    };
  },
  mounted() {
    this.checkDeviceType();
    window.addEventListener('resize', this.checkDeviceType);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDeviceType);
  },
  methods: {
    checkDeviceType() {
      const { MOBILE_SCREEN_BREAKPOINT } = wootConstants;
      this.isMobileDevice = window.innerWidth <= MOBILE_SCREEN_BREAKPOINT;
    },
  },
};