var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.tooltipOptions),expression:"tooltipOptions",modifiers:{"right":true}}],ref:"helpElement",staticClass:"help-menu",class:{
    'justify-center relative': !_vm.isSidebarExpanded,
    'help-menu--active': _vm.isShowDropdown,
  },on:{"click":_vm.handleShowDropdown}},[_c('div',{staticClass:"help-menu__prefix",class:{ 'mr-2 rtl:mr-0 rtl:ml-2': _vm.isSidebarExpanded }},[_c('Icon',{attrs:{"size":"18","view-box":"0 -0.3 14 15","icon":"question","type":"outline","icons":_vm.iconLib,"is-in-sidebar":""}})],1),_vm._v(" "),(_vm.isSidebarExpanded)?[_c('div',{staticClass:"help-menu__text"},[_vm._v("Help")])]:_vm._e(),_vm._v(" "),(_vm.isShowDropdown)?_c('div',{staticClass:"help-menu__wrapper",style:(_vm.dropdownStyle)},[_c('div',{staticClass:"help-menu__body"},_vm._l((_vm.helpMenuItems),function(item,index){return _c('div',{key:index,ref:`menuitem-${item.id}`,refInFor:true,staticClass:"nav-item",class:{
          'flex items-center hover:bg-neutral-low dark:hover:bg-primary':
            !item.child,
          '!cursor-default': item.child,
        },on:{"click":function($event){$event.stopPropagation();return _vm.handleClick(item)}}},[_c('div',{staticClass:"flex items-center"},[_c('Icon',{staticClass:"nav-item-icon",attrs:{"size":item.iconSize,"icon":item.icon,"type":"outline","icons":_vm.iconLib,"view-box":item.viewBox}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.label))])],1),_vm._v(" "),_c('div',{staticClass:"child-menu"},_vm._l((item.child),function(childItem,childIndex){return _c('div',{key:childIndex,staticClass:"child-menu__item hover:bg-neutral-low dark:hover:bg-primary cursor-pointer"},[_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.handleChildClick(childItem)}}},[_c('span',[_vm._v(_vm._s(childItem.label))])])])}),0)])}),0)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }