<template>
  <div class="sidebar-labels-wrap">
    <div
      v-if="!conversationUiFlags.isFetching"
      class="contact-conversation--list"
    >
      <div
        v-on-clickaway="closeDropdownLabel"
        class="label-wrap"
        @keyup.esc="closeDropdownLabel"
      >
        <div class="cell mb-1">
          <div class="cell__item">Label</div>
          <div class="cell__item">
            <div class="cell__link" @click="toggleLabels">Add labels</div>
          </div>
        </div>
        <div class="tag-wrapper">
          <div
            v-for="(label, index) in activeLabels"
            :key="index"
            class="tag-wrapper__item"
          >
            <div class="tag-wrapper__label">
              <div
                class="tag-wrapper__color"
                :style="{ backgroundColor: label.color }"
              />
              {{ label.title }}
              <div
                class="tag-wrapper__icon"
                @click="removeLabelFromConversation(label.title)"
              >
                <fluent-icon icon="dismiss" size="12" class="close--icon" />
              </div>
            </div>
          </div>
        </div>

        <div class="dropdown-wrap">
          <div
            :class="{ 'dropdown-pane--open': showSearchDropdownLabel }"
            class="dropdown-pane"
          >
            <label-dropdown
              v-if="showSearchDropdownLabel"
              :account-labels="accountLabels"
              :selected-labels="savedLabels"
              :allow-creation="isAdmin"
              @add="addLabelToConversation"
              @remove="removeLabelFromConversation"
            />
          </div>
        </div>
      </div>
    </div>
    <spinner v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner.vue';
import LabelDropdown from 'shared/components/ui/label/LabelDropdown.vue';
import { mixin as clickaway } from 'vue-clickaway';
import adminMixin from 'dashboard/mixins/isAdmin';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import conversationLabelMixin from 'dashboard/mixins/conversation/labelMixin';
import {
  buildHotKeys,
  isEscape,
  isActiveElementTypeable,
} from 'shared/helpers/KeyboardHelpers';

export default {
  components: {
    Spinner,
    LabelDropdown,
  },

  mixins: [clickaway, conversationLabelMixin, adminMixin, eventListenerMixins],
  props: {
    conversationId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      selectedLabels: [],
      showSearchDropdownLabel: false,
    };
  },

  computed: {
    ...mapGetters({
      conversationUiFlags: 'conversationLabels/getUIFlags',
      labelUiFlags: 'conversationLabels/getUIFlags',
    }),
  },
  methods: {
    toggleLabels() {
      this.showSearchDropdownLabel = !this.showSearchDropdownLabel;
    },
    closeDropdownLabel() {
      this.showSearchDropdownLabel = false;
    },
    handleKeyEvents(e) {
      const keyPattern = buildHotKeys(e);

      if (keyPattern === 'l' && !isActiveElementTypeable(e)) {
        this.toggleLabels();
        e.preventDefault();
      } else if (isEscape(e) && this.showSearchDropdownLabel) {
        this.closeDropdownLabel();
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  @apply flex items-center justify-between;

  &__item {
    @apply basis-[35%] text-sm text-[#868E96] leading-tight;

    &:last-child {
      @apply text-right basis-[65%];
    }
  }

  &__link {
    @apply text-accent text-xs transition-all ease-in-out duration-200 cursor-pointer font-normal inline-block;

    &:hover {
      @apply text-primary dark:text-stategrey underline;
    }
  }
}

.tag-wrapper {
  @apply flex flex-wrap;

  &__item {
    @apply flex items-center mr-3 mb-3;
  }

  &__color {
    @apply w-[12px] h-[12px] mr-2;
  }

  &__label {
    @apply flex items-center bg-secondary-low dark:bg-secondary-dark-low text-primary dark:text-stategrey text-[13px] pl-2 pr-2 border-0 rounded-[2px] select-none;
  }

  &__icon {
    @apply relative w-[10px] h-[10px] ml-2.5 p-[6px] transition-all ease-in-out duration-200 cursor-pointer rounded-[1px] text-primary dark:text-stategrey;

    &:hover {
      @apply bg-neutral-subtle dark:bg-[#25292B];
    }

    svg {
      @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }
  }
}

.sidebar-labels-wrap {
  @apply mb-0;
}
.contact-conversation--list {
  @apply w-full;

  .label-wrap {
    @apply relative;
    line-height: var(--space-medium);

    .dropdown-wrap {
      @apply flex -left-[1px] absolute w-full;
      margin-right: var(--space-medium);
      top: var(--space-medium);

      .dropdown-pane {
        @apply w-full box-border top-[0.2rem];
      }
    }
  }
}

.error {
  color: var(--r-500);
  font-size: var(--font-size-mini);
  font-weight: var(--font-weight-medium);
}
</style>
