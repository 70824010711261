<template>
  <div class="relative w-full mb-2">
    <template v-if="isUseCell">
      <div class="cell mb-4">
        <div class="cell__item">
          {{ cellLabel }}
        </div>
        <div
          class="cell__item"
          :class="hasActionButton ? 'flex items-center justify-end' : undefined"
        >
          <template v-if="hasActionButton && !hasValue">
            <div class="cell__link" @click="handleSelfAssign">
              {{ $t('CONVERSATION_SIDEBAR.SELF_ASSIGN') }}
            </div>
            <span class="text-[#E4E3E6] dark:text-[#2F3336]">
              &nbsp;|&nbsp;
            </span>
          </template>
          <div
            v-on-clickaway="onCloseDropdown"
            class="cell__link"
            @click="toggleDropdown"
          >
            <div
              v-if="hasValue && hasThumbnail"
              class="flex justify-end items-center gap-2"
            >
              <Thumbnail
                :src="selectedItem.thumbnail"
                size="24px"
                :status="selectedItem.availability_status"
                :username="selectedItem.name"
              />
              <div class="text-sm">
                <div v-if="!hasValue">
                  {{ multiselectorPlaceholder }}
                </div>
                <div :title="selectedItem.name">
                  {{ selectedItem.name }}
                </div>
              </div>
            </div>
            <template v-else>
              {{ multiselectorTitle }}
            </template>
          </div>
        </div>
      </div>
    </template>

    <div
      v-if="!isUseCell"
      class="dropdown-trigger"
      :class="{ 'dropdown-trigger--active': showSearchDropdown }"
      @click.prevent="toggleDropdown"
    >
      <div class="flex flex-auto min-w-0">
        <Thumbnail
          v-if="hasValue && hasThumbnail"
          :src="selectedItem.thumbnail"
          size="24px"
          :status="selectedItem.availability_status"
          :username="selectedItem.name"
        />
        <div class="flex justify-between w-full min-w-0 items-center">
          <h4
            v-if="!hasValue"
            class="mt-0 mb-0 mr-2 ml-0 text-ellipsis text-sm text-slate-800 dark:text-slate-100"
          >
            {{ multiselectorPlaceholder }}
          </h4>
          <h4
            v-else
            class="items-center leading-tight my-0 mx-2 overflow-hidden whitespace-nowrap text-ellipsis text-sm text-slate-800 dark:text-slate-100"
            :title="selectedItem.name"
          >
            {{ selectedItem.name }}
          </h4>
          <fluent-icon
            v-if="showSearchDropdown"
            size="16"
            icon="chevron-up"
            type="outline"
          />
          <fluent-icon v-else size="16" icon="chevron-down" type="outline" />
        </div>
      </div>
    </div>
    <div
      v-if="showSearchDropdown"
      :class="{ 'dropdown-pane--open': showSearchDropdown }"
      class="dropdown-pane"
    >
      <div class="flex justify-between items-center mb-1">
        <h4 class="text-sm text-slate-800 dark:text-slate-100 m-0 overflow-hidden whitespace-nowrap text-ellipsis">
          {{ multiselectorTitle }}
        </h4>
        <woot-button
          v-if="isSearchable"
          icon="dismiss"
          size="tiny"
          color-scheme="secondary"
          variant="clear"
          @click="onCloseDropdown"
        />
      </div>
      <multiselect-dropdown-items
        :is-searchable="isSearchable"
        :options="options"
        :selected-items="[selectedItem]"
        :has-thumbnail="hasThumbnail"
        :input-placeholder="inputPlaceholder"
        :no-search-result="noSearchResult"
        @click="onClickSelectItem"
      />
    </div>
  </div>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import MultiselectDropdownItems from 'shared/components/ui/MultiselectDropdownItems.vue';
import { mixin as clickaway } from 'vue-clickaway';
export default {
  components: {
    Thumbnail,
    MultiselectDropdownItems,
  },
  mixins: [clickaway],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: Object,
      default: () => ({}),
    },
    hasThumbnail: {
      type: Boolean,
      default: true,
    },
    multiselectorTitle: {
      type: String,
      default: '',
    },
    multiselectorPlaceholder: {
      type: String,
      default: 'None',
    },
    noSearchResult: {
      type: String,
      default: 'No results found',
    },
    inputPlaceholder: {
      type: String,
      default: 'Search',
    },
    isUseCell: {
      type: Boolean,
      default: false,
    },
    cellLabel: {
      type: String,
      default: '',
    },
    hasActionButton: {
      type: Boolean,
      default: false,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showSearchDropdown: false,
    };
  },
  computed: {
    hasValue() {
      if (this.selectedItem && this.selectedItem.id) {
        return true;
      }
      return false;
    },
  },
  beforeMount() {
    window.addEventListener('keyup', this.onEscapeKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onEscapeKeyUp);
  },
  methods: {
    toggleDropdown() {
      this.showSearchDropdown = !this.showSearchDropdown;
    },

    onCloseDropdown() {
      this.showSearchDropdown = false;
    },

    onClickSelectItem(value) {
      this.$emit('click', value);
      this.onCloseDropdown();
    },
    handleSelfAssign() {
      this.$emit('click-action-button');
    },
    onEscapeKeyUp(event) {
      if (event.which === 27) {
        this.showSearchDropdown = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-pane {
  @apply box-border absolute top-[2.9rem] w-full;
}

.dropdown-trigger {
  @apply w-full
  border
  border-solid
  border-neutral-high
  dark:border-neutral-dark-medium
  px-2.5
  rounded-[0.3125rem]
  h-[40px]
  flex
  items-center
  cursor-pointer
  hover:bg-white
  dark:hover:bg-transparent
  hover:border-neutral-medium
  dark:hover:border-secondary-dark-low;

  &--active {
    @apply bg-white dark:bg-[#26292b] dark:border-secondary-dark-low border-neutral-medium hover:bg-white dark:hover:bg-[#26292b];
  }
}

.cell {
  @apply flex items-center justify-between;

  &__item {
    @apply basis-[35%] text-sm text-[#868E96] leading-tight;

    &:last-child {
      @apply text-right basis-[65%];
    }
  }

  &__link {
    @apply text-accent text-xs transition-all ease-in-out duration-200 font-normal cursor-pointer inline-block;

    &:hover {
      @apply text-primary dark:text-stategrey underline;
    }
  }
}
</style>
