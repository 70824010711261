/* global axios */
import ApiClient from '../ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  get({
    inboxId,
    status,
    assigneeType,
    page,
    labels,
    teamId,
    conversationType,
    sortBy,
    includeAiConversations = false
  }) {
    const params = {
      inbox_id: inboxId,
      team_id: teamId,
      status,
      assignee_type: assigneeType,
      page,
      labels,
      conversation_type: conversationType,
      sort_by: sortBy,
    }

    if(includeAiConversations) {
      params.include_ai_conversations = true;
    }

    return axios.get(this.url, { params });
  }

  filter(payload) {
    return axios.post(`${this.url}/filter`, payload.queryData, {
      params: {
        page: payload.page,
      },
    });
  }

  search({ q }) {
    return axios.get(`${this.url}/search`, {
      params: {
        q,
        page: 1,
      },
    });
  }

  toggleStatus({ conversationId, status, snoozedUntil = null, noCsat = false }) {
    return axios.post(`${this.url}/${conversationId}/toggle_status`, {
      status,
      snoozed_until: snoozedUntil,
      no_csat: noCsat,
    });
  }

  togglePriority({ conversationId, priority }) {
    return axios.post(`${this.url}/${conversationId}/toggle_priority`, {
      priority,
    });
  }

  assignAgent({ conversationId, agentId }) {
    return axios.post(
      `${this.url}/${conversationId}/assignments?assignee_id=${agentId}`,
      {}
    );
  }

  assignTeam({ conversationId, teamId }) {
    const params = { team_id: teamId };
    return axios.post(`${this.url}/${conversationId}/assignments`, params);
  }

  markMessageRead({ id }) {
    return axios.post(`${this.url}/${id}/update_last_seen`);
  }

  markMessagesUnread({ id }) {
    return axios.post(`${this.url}/${id}/unread`);
  }

  toggleTyping({ conversationId, status, isPrivate }) {
    return axios.post(`${this.url}/${conversationId}/toggle_typing_status`, {
      typing_status: status,
      is_private: isPrivate,
    });
  }

  mute(conversationId) {
    return axios.post(`${this.url}/${conversationId}/mute`);
  }

  unmute(conversationId) {
    return axios.post(`${this.url}/${conversationId}/unmute`);
  }

  meta({ inboxId, status, assigneeType, labels, teamId, conversationType }) {
    return axios.get(`${this.url}/meta`, {
      params: {
        inbox_id: inboxId,
        status,
        assignee_type: assigneeType,
        labels,
        team_id: teamId,
        conversation_type: conversationType,
      },
    });
  }

  merge(params) {
    return axios.post(`${this.url}/merge`, params);
  }

  getShopifyData(conversationId) {
    return axios.get(`${this.url}/${conversationId}/shopify_data`);
  }

  getKlaviyoData(conversationId) {
    return axios.get(`${this.url}/${conversationId}/klaviyo_data`);
  }

  sendEmailTranscript({ conversationId, email }) {
    return axios.post(`${this.url}/${conversationId}/transcript`, { email });
  }

  updateCustomAttributes({ conversationId, customAttributes }) {
    return axios.post(`${this.url}/${conversationId}/custom_attributes`, {
      custom_attributes: customAttributes,
    });
  }

  fetchParticipants(conversationId) {
    return axios.get(`${this.url}/${conversationId}/participants`);
  }

  updateParticipants({ conversationId, userIds }) {
    return axios.patch(`${this.url}/${conversationId}/participants`, {
      user_ids: userIds,
    });
  }

  getAllAttachments(conversationId) {
    return axios.get(`${this.url}/${conversationId}/attachments`);
  }

  refundOrder({ conversationId, params }) {
    return axios.post(`${this.url}/${conversationId}/refund`, params);
  }

  refundInfo({ conversationId, params }) {
    return axios.post(`${this.url}/${conversationId}/refund_info`, params);
  }

  cancelOrder({ conversationId, params }) {
    return axios.post(`${this.url}/${conversationId}/cancel`, params);
  }

  updateOrderTags({ conversationId, params }) {
    return axios.post(`${this.url}/${conversationId}/update_order`, params);
  }

  updateCustomerData({ conversationId, params }) {
    return axios.post(`${this.url}/${conversationId}/update_customer`, params);
  }

  softDelete(conversationId) {
    return axios.delete(`${this.url}/${conversationId}/soft_delete`);
  }
}

export default new ConversationApi();
