import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import IntentsAPI from '../../api/intents';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { INTENT_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
  },
  currentIntent: null, // Store the currently fetched intent
};

export const getters = {
  fetchedIntents(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getCurrentIntent(_state) {
    return _state.currentIntent;
  },
};

export const actions = {
  revalidate: async function revalidate({ commit }, { newKey }) {
    try {
      const isExistingKeyValid = await IntentsAPI.validateCacheKey(newKey);
      if (!isExistingKeyValid) {
        const response = await IntentsAPI.refetchAndCommit(newKey);
        commit(types.SET_INTENTS, response.data);
      }
    } catch (error) {
      console.error('Error during revalidation:', error);
      // Handle error appropriately
    }
  },

  getById: async function getIntentById({ commit }, id) {
    commit(types.SET_INTENT_UI_FLAG, { isFetching: true });
    commit(types.SET_CURRENT_INTENT, null); // Clear previous intent data
    try {
      const response = await IntentsAPI.show(id);
      commit(types.SET_CURRENT_INTENT, response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching intent by ID:', error);
      throw error;
    } finally {
      commit(types.SET_INTENT_UI_FLAG, { isFetching: false });
    }
  },

  get: async function getIntents({ commit }) {
    commit(types.SET_INTENT_UI_FLAG, { isFetching: true });
    try {
      const response = await IntentsAPI.get(false);
      commit(types.SET_INTENTS, response.data.payload);
    } catch (error) {
      console.error('Error fetching intents:', error);
      throw error;
    } finally {
      commit(types.SET_INTENT_UI_FLAG, { isFetching: false });
    }
  },

  create: async function createIntents({ commit }, intentObj) {
    commit(types.SET_INTENT_UI_FLAG, { isCreating: true });
    try {
      const response = await IntentsAPI.create(intentObj);
      commit(types.ADD_INTENT, response.data);
      AnalyticsHelper.track(INTENT_EVENTS.CREATE);
      return response;
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      console.error('Error creating intent:', errorMessage);
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_INTENT_UI_FLAG, { isCreating: false });
    }
  },

  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_INTENT_UI_FLAG, { isUpdating: true });
    try {
      const response = await IntentsAPI.update(id, { intent: updateObj });
      commit(types.UPDATE_INTENT, response.data);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      console.error('Error updating intent:', errorMessage);
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_INTENT_UI_FLAG, { isUpdating: false });
    }
  },

  delete: async function deleteIntents({ commit }, id) {
    commit(types.SET_INTENT_UI_FLAG, { isDeleting: true });
    try {
      await IntentsAPI.delete(id);
      commit(types.DELETE_INTENT, id);
      AnalyticsHelper.track(INTENT_EVENTS.DELETED);
    } catch (error) {
      console.error('Error deleting intent:', error);
      throw new Error(error);
    } finally {
      commit(types.SET_INTENT_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_INTENT_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_INTENTS]: MutationHelpers.set,
  [types.ADD_INTENT]: MutationHelpers.create,
  [types.DELETE_INTENT]: MutationHelpers.destroy,
  [types.UPDATE_INTENT]: MutationHelpers.setSingleRecord,

  [types.SET_CURRENT_INTENT](_state, intent) {
    _state.currentIntent = intent; // Store the current intent in the state
  },

  // New mutation to set sorted intents
  [types.SET_SORTED_INTENTS](_state, sortedIntents) {
    _state.records = sortedIntents;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
