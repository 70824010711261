<template>
  <div>
    <!-- Badge -->
    <div class="relative mb-3">
      <div class="tag-wrapper tag-wrapper--badge">
        <div class="tag-wrapper__item">
          <div class="tag-wrapper__label">
            {{ order.fulfillment_status || 'unfulfilled' }}
          </div>
        </div>
        <div v-if="!!order.cancelled_at" class="tag-wrapper__item">
          <div class="tag-wrapper__label">
            <div class="tag_wrapper__label--warning">
              {{ $t('SHOPIFY_PANEL.CANCELLED') }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="link" class="shopify-icon">
        <a
          :href="getOrderLink(order.id)"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          <img :src="iconShopify" alt="Shopify Icon" />
        </a>
      </div>
    </div>

    <!-- KPI -->
    <div class="mb-3">
      <div class="flex gap-x-2">
        <div class="kpi">
          <div class="kpi__headline">
            {{ order.created_at }}
          </div>
          <div class="kpi__tagline">Order date</div>
        </div>
        <div class="kpi">
          <div class="kpi__headline">
            {{ amountWithCurrency(order.total_price) }}
          </div>
          <div class="kpi__tagline">Order total</div>
        </div>
      </div>
    </div>

    <!-- Tag -->
    <div class="mb-2">
      <div class="cell mb-3">
        <div class="cell__item">Tags</div>
        <div class="cell__item">
          <a class="cell__link" @click="toggleInputTag">
            {{
              showInputTag ? 'Cancel' : orderTags.length > 0 ? 'Edit' : 'Add'
            }}
          </a>
        </div>
      </div>

      <!-- Submitted tag -->
      <div v-show="!showInputTag" :class="!showInputTag ? 'mb-1' : undefined">
        <div class="tag-wrapper">
          <div v-for="tag in orderTags" :key="tag" class="tag-wrapper__item">
            <div class="tag-wrapper__label">
              {{ tag }}
            </div>
          </div>
        </div>
      </div>

      <!-- Edit tag -->
      <div v-show="showInputTag" class="mt-3 mb-4">
        <!-- TODO: new component for tag wrapper -->
        <div class="tag-wrapper">
          <div
            v-for="(tag, index) in orderTags"
            :key="index"
            class="tag-wrapper__item"
          >
            <div class="tag-wrapper__label">
              {{ tag }}
              <div class="tag-wrapper__icon" @click="removeTag(index)">
                <fluent-icon icon="dismiss" size="12" class="close--icon" />
              </div>
            </div>
          </div>
        </div>
        <input
          ref="tagField"
          v-model="newTag"
          type="text"
          placeholder="Type here and press Enter to save..."
          class="tag-input-field"
          @keyup.enter="addNewTag"
        />
        <!-- Loading state -->
        <div
          v-if="isLoading"
          class="text-xs text-center text-secondary dark:text-secondary-dark mt-2"
        >
          Updating Tags...
        </div>
      </div>
    </div>

    <!-- Shipment tracking list -->
    <div v-if="filteredShipment.length !== 0" class="tracking">
      <div class="tracking__title">
        {{ $t('SHOPIFY_PANEL.TRACKING') }}
      </div>
      <div
        v-for="(item, index) in order.shipments"
        :key="index"
        class="tracking__item"
      >
        <a :href="item.tracking_url" class="tracking__number" target="_blank">
          {{ item.tracking_number }}
        </a>
      </div>
    </div>

    <!-- Order List -->
    <shopify-order-items
      v-if="order.line_items"
      :items="order.line_items"
      :currency="order.currency"
    />

    <div class="-mx-4">
      <shopify-accordion-item
        v-if="order.refunds"
        :title="'Refunds'"
        stacked
        :is-open="isContactSidebarItemOpen(order.refunds)"
        @click="value => toggleSidebarUIState(order.refunds, value)"
      >
        <shopify-order-refunds
          :refunds="order.refunds"
          :currency="order.currency"
        />
      </shopify-accordion-item>

      <shopify-accordion-item
        v-if="order.shipping_address || order.shipments"
        title="Shipping"
        stacked
        :is-open="isContactSidebarItemOpen(order.shipping_address)"
        @click="value => toggleSidebarUIState(order.shipping_address, value)"
      >
        <shopify-order-shipping-address
          :shipping-address="order.shipping_address"
          :shipments="order.shipments"
        />
      </shopify-accordion-item>
    </div>

    <div class="order-actions">
      <woot-button
        :is-disabled="Boolean(order.refunds)"
        size="small"
        @click="openRefundModal(order.refunds)"
      >
        {{ $t('SHOPIFY_PANEL.REFUND') }}
      </woot-button>

      <woot-button
        :is-disabled="!isCancellable"
        size="small"
        @click="toggleCancelModal"
      >
        {{ $t('SHOPIFY_PANEL.CANCEL') }}
      </woot-button>

      <woot-button
        v-tooltip.top="'Coming soon'"
        variant="hollow"
        size="small"
        :disabled="true"
      >
        Duplicate
      </woot-button>
      <woot-button
        v-tooltip.top="'Coming soon'"
        variant="hollow"
        size="small"
        :disabled="true"
      >
        Edit
      </woot-button>
    </div>
    <shopify-refund-modal
      v-if="showRefundModal"
      :order="order"
      :show="showRefundModal"
      @close="toggleRefundModal"
    />

    <shopify-cancel-modal
      v-if="showCancelModal"
      :order="order"
      :show="showCancelModal"
      @close="toggleCancelModal"
    />
  </div>
</template>
<script>
import ShopifyOrderItems from './ShopifyOrderItems';
import ShopifyOrderShippingAddress from './ShopifyOrderShippingAddress';
import ShopifyOrderRefunds from './ShopifyOrderRefunds';
import ShopifyAccordionItem from 'dashboard/components/Accordion/ShopifyAccordionItem';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import shopifyMixin from 'dashboard/mixins/shopifyMixin';
import ShopifyRefundModal from './RefundModal.vue';
import ShopifyCancelModal from './CancelModal.vue';
import { mapGetters } from 'vuex';
import ShopifyIcon from '/public/dashboard/images/shopify/shopify-icon.svg';
import { SHOPIFY } from 'shared/constants/links';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    ShopifyOrderItems,
    ShopifyOrderShippingAddress,
    ShopifyOrderRefunds,
    ShopifyRefundModal,
    ShopifyAccordionItem,
    ShopifyCancelModal,
  },
  mixins: [uiSettingsMixin, shopifyMixin, alertMixin],
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    link: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showEditModal: false,
      showRefundModal: false,
      showCancelModal: false,
      showDuplicateModal: false,
      newTag: '',
      showInputTag: false,
      orderTags: this.order.tags,
      isLoading: false,
      iconShopify: ShopifyIcon,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      uiFlags: 'refund/getUIFlags',
    }),
    filteredShipment() {
      return (
        this.order?.shipments?.filter(res => res.tracking_url !== null) || []
      );
    },
    isCancellable() {
      return !this.order.cancelled_at;
    },
  },
  methods: {
    async updateOrderTags(updatedTags) {
      try {
        if (!this.currentChat.id || !this.order.id) {
          throw new Error('Current chat ID or order ID is missing.');
        }
        this.isLoading = true;

        const response = await this.$store.dispatch('updateOrderTags', {
          conversationId: this.currentChat.id,
          params: {
            order_id: this.order.id,
            tags: updatedTags,
          },
        });

        this.orderTags = response.tags
          .split(',')
          .map(tag => tag.trim())
          .filter(tag => tag !== '');
        this.isLoading = false;
        this.newTag = '';
        this.toggleInputTag();
      } catch (error) {
        this.showAlert('Error updating tags');
        this.showInputTag = false;
        this.isLoading = false;
      }
    },
    removeTag(index) {
      const updatedTags = [...this.orderTags];
      updatedTags.splice(index, 1);
      this.updateOrderTags(updatedTags);
    },
    addNewTag() {
      if (this.newTag.trim()) {
        const updatedTags = [...this.orderTags, this.newTag.trim()];
        this.updateOrderTags(updatedTags);
      } else {
        this.showAlert('New tag cannot be empty.');
      }
    },
    toggleInputTag() {
      this.showInputTag = !this.showInputTag;
      this.$nextTick(() => {
        this.$refs.tagField.focus();
      });
    },
    toggleRefundModal() {
      this.showRefundModal = !this.showRefundModal;
    },
    toggleCancelModal() {
      this.showCancelModal = !this.showCancelModal;
    },
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    toggleDuplicateModal() {
      this.showDuplicateModal = !this.showDuplicateModal;
    },
    amountWithCurrency(amount) {
      return `${this.currency_symbol(this.order.currency)}${this.moneyFormat(
        amount,
        this.order.currency
      )}`;
    },
    openRefundModal(refundData) {
      if (!refundData) {
        this.toggleRefundModal();
      }
    },
    openEditModal() {
      this.toggleEditModal();
    },
    openDuplicateModal() {
      this.toggleDuplicateModal();
    },
    getOrderLink(id) {
      if (!this.link) {
        return null;
      }
      const shopName = this.link.split('.')[0];
      return `${SHOPIFY.ADMIN_URL}/store/${shopName}/orders/${id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-wrapper {
  @apply flex flex-wrap;

  &__item {
    @apply flex items-center mr-3 mb-3;
  }

  &__label {
    @apply flex items-center bg-secondary-low dark:bg-secondary-dark-low text-primary dark:text-stategrey text-[13px] pl-2 pr-2 border-0 rounded-[2px] select-none;

    &--warning,
    &-cancelled {
      @apply dark:text-stategrey text-[#746D2D] bg-yellow-100 dark:bg-[#403D20];
    }
  }

  &__icon {
    @apply relative w-[10px] h-[10px] ml-2.5 p-[6px] transition-all ease-in-out duration-200 cursor-pointer rounded-[1px] text-primary dark:text-stategrey;

    &:hover {
      @apply bg-neutral-subtle dark:bg-[#25292B];
    }

    svg {
      @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }
  }

  &--badge {
    .tag-wrapper__item {
      @apply mr-2 mb-2;
    }
  }
}

.tag-input-field {
  @apply m-0 py-2.5 px-4 border border-[#E4E3E6] dark:border-slate-700/50 text-sm mt-2 rounded-lg;

  &:focus,
  &:active {
    @apply border border-accent;
  }

  &::placeholder {
    @apply text-sm;
  }
}

.shopify-icon {
  @apply inline-block p-[1px] rounded-[2px] border border-transparent w-[24px] h-[24px] transition-all ease-in-out duration-200 absolute top-0 right-0;

  img {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
  }

  &:hover {
    @apply bg-neutral-subtle border-[#E4E3E6] dark:bg-[#25292B] dark:border-transparent;
  }
}

.kpi {
  @apply flex flex-col basis-1 grow bg-neutral-faint dark:bg-neutral-dark-faint rounded-lg px-3 py-2;

  &__headline {
    @apply text-xl text-primary dark:text-stategrey;
  }

  &__tagline {
    @apply text-[13px] text-secondary dark:text-secondary-dark;
  }
}

.cell {
  @apply flex items-center justify-between;

  &__item {
    @apply basis-[35%] text-sm text-secondary dark:text-secondary-dark leading-tight;

    &:last-child {
      @apply text-right basis-[65%];
    }
  }

  &__link {
    @apply text-accent text-sm transition-all ease-in-out duration-200 select-none font-normal inline-block;

    &:hover {
      @apply text-primary dark:text-stategrey underline;
    }
  }
}

.order-actions {
  // TODO: need to update the border color because the dark border is not use neutral-high dark mode version
  @apply -mx-4 px-4 -mb-6 py-4 flex flex-wrap gap-x-2 border-t border-neutral-high dark:border-neutral-dark-medium;

  .button {
    @apply mb-2;
  }
}

.tracking {
  &__title {
    @apply text-sm text-secondary dark:text-secondary-dark leading-tight mb-3;
  }
  &__item {
    @apply flex items-center mb-3;
  }
  &__number {
    @apply text-sm text-accent dark:text-accent-dark transition-all ease-in-out duration-200 font-normal whitespace-nowrap overflow-hidden overflow-ellipsis;

    &:hover {
      @apply text-primary dark:text-stategrey underline;
    }
  }
}
</style>
