import ConversationsAPI from '../../api/conversations';
import types from '../mutation-types';

export const state = {
  generatedMessage: null,
  detectedIntent: null,
  uiFlags: {
    isFetchingGeneratedMessage: false,
  },
  isDetectingIntent: false,
};

export const getters = {
  getGeneratedMessage(_state) {
    return _state.generatedMessage;
  },
  getGeneratedMessageUIFlags(_state) {
    return _state.uiFlags;
  },
  getDetectedIntent(_state) {
    return _state.detectedIntent; 
  },
  getIsDetectingIntent(_state) {
    return _state.isDetectingIntent;
  },
};

export const actions = {
  async fetchGeneratedMessage({ commit }, { conversationId, intentId, regenerate = null }) {
    commit(types.CLEAR_GENERATED_MESSAGE);
    commit(types.SET_GENERATED_MESSAGE_UI_FLAG, {
      isFetchingGeneratedMessage: true,
    });
    try {
      const response = await ConversationsAPI.getGeneratedMessage(
        conversationId,
        intentId,
        regenerate
      );

      if (response.status === 204) {
        commit(types.SET_GENERATED_MESSAGE, null);
      } else {
        commit(types.SET_GENERATED_MESSAGE, response.data);
      }
    } catch (error) {
      console.error('Error fetching generated message:', error);
      throw error;
    } finally {
      commit(types.SET_GENERATED_MESSAGE_UI_FLAG, {
        isFetchingGeneratedMessage: false,
      });
    }
  },
  async detectIntent({ commit }, { conversationId, beforeMessageId }) {
    commit(types.SET_IS_DETECTING_INTENT, true);
    try {
      const response = await ConversationsAPI.detectIntent(
        conversationId,
        beforeMessageId
      );

      const intentData = response.data?.intent || {};
      intentData.icon = intentData.icon || 'memory';


      commit(types.SET_DETECTED_INTENT, intentData);
    } catch (error) {
      console.error('Error detecting intent: ', error);
      throw error;
    } finally {
      commit(types.SET_IS_DETECTING_INTENT, false);
    }
  },
};

export const mutations = {
  [types.SET_GENERATED_MESSAGE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_GENERATED_MESSAGE](_state, data) {
    _state.generatedMessage = data;
  },
  [types.CLEAR_GENERATED_MESSAGE](_state) {
    _state.generatedMessage = null;
  },
  [types.SET_DETECTED_INTENT](_state, data) {
    _state.detectedIntent = data;
  },
  [types.SET_IS_DETECTING_INTENT](_state, data  ) {
    _state.isDetectingIntent = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
