<template>
  <div class="intent-bubble">
    <slot name="wrapper">
      <div v-if="isLoading" class="loading">
        <span>Loading intent...</span>
      </div>
      <template v-else-if="safeIntent">
        <div class="intent-bubble__icon">
          <slot name="icon">
            <fluent-icon :icon="safeIcon" size="16" view-box="0 0 16 16" color="#5647FF" class="icon-center" />
          </slot>
        </div>
        <div class="intent-bubble__title">
          <slot name="title">
            {{ safeIntent.title || 'Unknown intent' }}
          </slot>
        </div>
      </template>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'IntentBubble',
  props: {
    intent: {
      type: Object,
      default: () => ({
        id: '',
        title: '',
        description: '',
        instructions: '',
        icon: 'memory',
      }),
    },
    isLoading: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    safeIntent() {
      return this.intent || {
        id: '',
        title: '',
        description: '',
        instructions: '',
        icon: 'memory',
      };
    },
    safeIcon() {
      return this.safeIntent.icon || 'memory';
    }
  }
};
</script>

<style lang="scss" scoped>
.intent-bubble {
  @apply bg-white dark:bg-[#121217] rounded-lg py-1 px-3 flex items-center;
  min-width: 50px;
  width: fit-content;
  gap: 2px;
  
  // Drop shadows
  box-shadow: 
    0px 1px 3px 0px rgba(18, 18, 23, 0.1),
    0px 1px 2px 0px rgba(18, 18, 23, 0.06);

  &__icon {
    @apply w-4 h-4 pt-0.5;

    :deep(.icon-center) {
      @apply flex items-center justify-center;
      
      svg {
        @apply w-full h-full;
        
        path {
          // Adjust the transform to center the path
          transform: translate(2px, 2px) scale(0.75);
          transform-origin: center;
          stroke-width: 1.5;
        }
      }
    }
  }

  &__title {
    @apply text-xs font-normal text-primary dark:text-primary-dark;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }

  .loading {
    @apply text-slate-500 dark:text-slate-100 italic text-xs;
  }
}
</style> 