<template>
  <div class="flex items-center justify-center p-8">
    <h6
      class="block text-xs text-center w-100 text-secondary dark:text-secondary-dark"
    >
      <span v-if="message" class="mr-1">{{ message }}</span>
      <spinner :size="size" :color-scheme="colorScheme" />
    </h6>
  </div>
</template>
<script>
import Spinner from 'shared/components/Spinner';
export default {
  components: {
    Spinner,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
    colorScheme: {
      type: String,
      default: '',
    },
  },
};
</script>
