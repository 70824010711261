<template>
  <div
    ref="intentWrapper"
    class="flex flex-row gap-8 flex-shrink min-w-0 p-6 overflow-auto h-full"
  >
    <div class="w-[60%]">
      <template v-if="fetchedIntents.length > 0">
        <div class="w-full mb-4 relative">
          <search-bar
            ref="searchBar"
            placeholder="Search intents"
            @search="handleSearch"
            @clear="onClearSearch"
          />
        </div>
        <div class="flex items-center gap-4 mt-6 mb-3 w-full px-4">
          <div class="w-[60%] text-sm uppercase font-medium">
            <div
              v-tooltip="{
                content: 'Sort by intent title',
              }"
              class="w-[initial] inline-flex items-center select-none cursor-pointer"
              @click="sortByTitle"
            >
              {{ $t('SIDEBAR.INTENT.TABLE.TITLE') }}
              <fluent-icon
                class="ml-1 text-secondary dark:text-secondary-dark"
                icon="sort-icon"
                type="outline"
                size="18"
              />
            </div>
          </div>
          <div
            class="w-[40%] flex items-center text-sm uppercase font-medium sm:justify-start 2xl:justify-center"
          >
            <div
              v-tooltip="{
                content: 'Sort by last edit',
              }"
              class="w-[initial] inline-flex items-center select-none cursor-pointer"
              @click="sortByUpdatedAt"
            >
              {{ $t('SIDEBAR.INTENT.TABLE.LAST_EDIT') }}
              <fluent-icon
                class="ml-1 text-secondary dark:text-secondary-dark"
                icon="sort-icon"
                type="outline"
                size="18"
              />
            </div>
          </div>
        </div>

        <!-- intent search loading state -->
        <woot-loading-state v-if="isLoading" message="Searching" />

        <!-- intent search empty state -->
        <template
          v-else-if="searchResultData.length === 0 && searchQuery.length > 0"
        >
          <div class="w-full p-8 text-sm text-center">
            <fluent-icon
              class="mx-auto text-secondary dark:text-secondary-dark"
              icon="info-new"
              type="outline"
              size="24"
            />
            <div class="mb-1">"{{ searchQuery }}" not found.</div>
            <div class="text-secondary dark:text-secondary-dark mb-1">
              We couldn't find any intents matching your search criteria.
            </div>
            <div
              class="cursor-pointer inline-block text-accent dark:text-accent hover:hover:text-accent-dark-low dark:hover:text-accent-dark-medium"
              @click="onClearSearch"
            >
              Clear search
            </div>
          </div>
        </template>
        <template v-else>
          <div class="pb-4">
            <intent-item
              v-for="intent in paginatedIntents"
              :key="intent.id"
              class="mb-2"
              :question="intent.title"
              :respond="intent.description"
              :created-at="intent.created_at"
              :modified-at="intent.updated_at"
              :respond-char-limit="100"
              @click-title="onEditIntent(intent.id)"
            >
              <!-- Toggle switch component -->
              <template #switch>
                <toggle
                  :value="intent.enabled"
                  size="medium"
                  @input="value => handleToggleChange(intent.id, value)"
                />
              </template>
              <template #menu>
                <!-- Popover (dropdown menu) -->
                <popover
                  :menu-items="menuItems"
                  @click="name => handleMenuClick(intent.id, name)"
                />
              </template>
            </intent-item>
          </div>
          <div class="flex items-center justify-center pb-4">
            <pagination
              :pagination="pagination"
              @pageChange="handlePageChange"
            />
          </div>
        </template>
      </template>
      <template v-else>
        <div class="flex items-center justify-center w-full h-[155px]">
          <!-- initial fetching intent -->
          <template v-if="uiFlags.isFetching">
            <woot-loading-state message="Fetching intents" />
          </template>
          <!-- is fetching error -->
          <template v-else-if="isIntentFetchingError">
            <p>{{ $t('SIDEBAR.INTENT.FETCHING_ERROR') }}</p>
          </template>
          <!-- is intents empty -->
          <template v-else-if="fetchedIntents.length === 0">
            <p>{{ $t('SIDEBAR.INTENT.NOT_FOUND') }}</p>
          </template>
        </div>
      </template>
    </div>
    <div class="w-[34%]">
      <span v-dompurify-html="$t('SIDEBAR.INTENT.INTRO')" />
    </div>
  </div>
</template>

<script>
import Toggle from 'dashboard/components/ui/Switch.vue';
import IntentItem from '../components/IntentItem.vue';
import Pagination from '../components/Pagination.vue';
import Popover from '../components/Popover.vue';
import accountMixin from 'dashboard/mixins/account';
import { mapActions, mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import SearchBar from '../components/SearchBar.vue';
import { debounce } from '@chatwoot/utils';

export default {
  components: {
    IntentItem,
    Toggle,
    Pagination,
    Popover,
    SearchBar,
  },
  mixins: [accountMixin, alertMixin],
  data() {
    return {
      menuItems: [
        { name: 'edit', type: '' },
        { name: 'delete', type: 'danger' },
      ],
      pagination: {
        currentPage: 1,
        limit: 10, // total item per page
        total: 0, // total data (intents)
        totalPages: 1,
      },
      searchResultData: [],
      searchQuery: '',
      isLoading: false,
      sortTitleAsc: true,
      sortUpdatedAtAsc: true,
      isIntentFetchingError: false,
    };
  },
  computed: {
    ...mapGetters('intents', {
      fetchedIntents: 'fetchedIntents',
      uiFlags: 'getUIFlags',
    }),
    paginatedIntents() {
      const start = (this.pagination.currentPage - 1) * this.pagination.limit;
      const end = start + this.pagination.limit;

      // Use searchResultData if it's not empty, otherwise use fetchedIntents
      const sourceData =
        this.searchResultData.length > 0
          ? this.searchResultData
          : this.fetchedIntents;

      return sourceData.slice(start, end);
    },
  },
  watch: {
    fetchedIntents() {
      // if intents length change, update pagination
      this.updatePagination();
    },

    // Re-run the search results to update the list when the user finishes editing
    $route(to, from) {
      if (from.name === 'ai_intents_edit') {
        if (this.searchQuery) {
          this.performSearch(this.searchQuery);
        }
      }
    },
  },
  mounted() {
    this.getIntents();
  },
  created() {
    this.debouncedSearch = debounce(this.performSearch, 300);
  },
  methods: {
    ...mapActions('intents', {
      fetchIntents: 'get',
      fetchIntentById: 'getById',
      updateIntentStatus: 'update',
      deleteIntent: 'delete',
    }),
    async getIntents() {
      try {
        this.isIntentFetchingError = false;
        await this.fetchIntents();
      } catch (error) {
        this.isIntentFetchingError = true;
        // eslint-disable-next-line no-console
        console.error('Error fetching intents:', error);
      }
    },
    async handleMenuClick(intentId, name) {
      if (name === 'edit') {
        await this.onEditIntent(intentId);
      } else if (name === 'delete') {
        // delete intent
        await this.deleteIntent(intentId).then(() => {
          // remove deleted intent from the search result
          const searchIndex = this.searchResultData.findIndex(
            item => item.id === intentId
          );
          if (searchIndex !== -1) {
            this.searchResultData.splice(searchIndex, 1);
          }
        });
        this.showAlert('Successfully deleted intent');
      }
    },
    async onEditIntent(intentId) {
      try {
        await this.fetchIntentById(intentId);
        this.$router.push({
          name: 'ai_intents_edit',
          params: { intent_id: intentId },
        });
      } catch (error) {
        this.showAlert('Failed to fetch intent for editing');
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    async handleToggleChange(id, val) {
      const intent = this.fetchedIntents?.find(int => int.id === id) || [];
      if (intent) {
        await this.updateIntentStatus({
          id: intent.id,
          enabled: val,
        });

        // Update searchResultData if we're in search mode
        if (this.searchResultData.length > 0) {
          const searchIndex = this.searchResultData.findIndex(
            item => item.id === id
          );
          if (searchIndex !== -1) {
            this.$set(this.searchResultData[searchIndex], 'enabled', val);
          }
        }
      }
    },
    updatePagination() {
      // Set the total number of items to paginate
      const sourceData =
        this.searchResultData.length > 0
          ? this.searchResultData
          : this.fetchedIntents;

      this.pagination.total = sourceData.length;

      // Calculate the total number of pages needed
      // Rounds up to ensure partial pages are counted as full pages
      this.pagination.totalPages = Math.ceil(
        this.pagination.total / this.pagination.limit
      );

      // Check if the current page number is greater than the total number of pages
      if (this.pagination.currentPage > this.pagination.totalPages) {
        // Adjust current page to last valid page or first page if no pages exist
        this.pagination.currentPage = Math.max(1, this.pagination.totalPages);
      }
    },
    handlePageChange(newPage) {
      this.scrollToTop();
      this.pagination.currentPage = newPage;
    },
    handleSearch(query) {
      this.searchQuery = query;
      this.isLoading = true;
      this.debouncedSearch();
    },
    onClearSearch() {
      // Clear search query in SearchBar component
      this.$refs.searchBar.resetSearchQuery();

      // Reset other data
      this.searchQuery = '';
      this.searchResultData = [];
      this.pagination.currentPage = 1;
      this.updatePagination();
    },
    async performSearch() {
      try {
        this.searchResultData = this.fetchedIntents.filter(
          res =>
            res.title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            res.description
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
        );

        // If no search results are found, reset pagination to page 1
        if (this.searchResultData.length === 0) {
          this.pagination.currentPage = 1;
        }

        // Update pagination based on search results
        this.updatePagination();
        this.pagination.currentPage = 1;

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        // eslint-disable-next-line no-console
        console.error('Error during search:', error);
      }
    },
    sortByTitle() {
      // Toggle the flag
      this.sortTitleAsc = !this.sortTitleAsc;
      this.pagination.currentPage = 1;

      const sourceData =
        this.searchResultData.length > 0
          ? [...this.searchResultData]
          : [...this.fetchedIntents];

      const sortedData = sourceData.sort((a, b) => {
        return this.sortTitleAsc
          ? a.title.localeCompare(b.title)
          : b.title.localeCompare(a.title);
      });

      if (this.searchResultData.length > 0) {
        this.searchResultData = sortedData;
      } else {
        // Use mutation for fetchedIntents
        this.$store.commit('intents/SET_SORTED_INTENTS', sortedData);
      }
    },
    sortByUpdatedAt() {
      // Toggle the flag
      this.sortUpdatedAtAsc = !this.sortUpdatedAtAsc;
      this.pagination.currentPage = 1;

      const sourceData =
        this.searchResultData.length > 0
          ? [...this.searchResultData]
          : [...this.fetchedIntents];

      const sortedData = sourceData.sort((a, b) => {
        const updatedA = Number(a.updated_at);
        const updatedB = Number(b.updated_at);

        return this.sortUpdatedAtAsc
          ? updatedA - updatedB
          : updatedB - updatedA;
      });

      if (this.searchResultData.length > 0) {
        this.searchResultData = sortedData;
      } else {
        // Use mutation for fetchedIntents
        this.$store.commit('intents/SET_SORTED_INTENTS', sortedData);
      }
    },
    scrollToTop() {
      const intentWrapper = this.$refs.intentWrapper;
      if (intentWrapper) {
        intentWrapper.scrollTop = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.intro {
  @apply flex items-center gap-4 mb-10 w-full uppercase;

  &__left {
    @apply w-[60%];
  }

  &__right {
    @apply w-[40%] justify-end gap-4;
  }
}

.intents-pagintation {
  @apply min-h-[calc(100vh-195px)] max-h-[calc(100vh-195px)] overflow-y-auto px-4 pb-4;
}
</style>
