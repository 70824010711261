<template>
  <div class="search-bar">
    <div class="search-bar__prefix">
      <fluent-icon
        icon="search"
        type="outline"
        class="search-bar__prefix-icon"
        size="16"
      />
    </div>
    <input
      ref="searchbar"
      v-model="searchQuery"
      class="search-bar__input !rounded-[0.3125rem]"
      type="text"
      :placeholder="placeholder"
      @input="handleInput"
    />
    <div v-if="searchQuery" class="search-bar__suffix" @click="clearSearch">
      <fluent-icon
        icon="dismiss"
        type="outline"
        class="search-bar__suffix-icon"
        size="16"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Search intents',
    },
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  methods: {
    handleInput(e) {
      this.searchQuery = e.target.value;
      this.$emit('search', this.searchQuery);
    },
    clearSearch() {
      this.searchQuery = '';
      this.$emit('clear');
    },
    resetSearchQuery() {
      this.searchQuery = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar {
  @apply w-full mb-4 relative;

  &__prefix {
    @apply absolute top-1/2 left-2 transform -translate-y-1/2  w-[24px] h-[24px];

    &-icon {
      @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none text-secondary dark:text-secondary-dark;
    }
  }

  &__suffix {
    @apply absolute top-1/2 right-2 transform -translate-y-1/2 w-[24px] h-[24px] cursor-pointer hover:bg-secondary-medium dark:hover:bg-secondary-dark-medium rounded-[0.3125rem];

    &-icon {
      @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-accent dark:text-accent-dark;
    }
  }

  &__input {
    @apply m-0 w-full border h-[38px] text-sm text-primary dark:text-primary-dark rounded-md focus:border-accent dark:focus:border-accent-dark bg-white dark:bg-primary border-neutral-medium dark:border-neutral-dark-medium pl-8 pr-8;

    &:focus {
      @apply transition-all duration-100 ease-in;
    }
  }
}
</style>
