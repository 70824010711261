<template>
  <div class="relative">
    <div class="text-left rtl:text-right">
      <!-- TODO: new component for cell -->
      <div class="cell mb-3">
        <div class="cell__item">Tags</div>
        <div class="cell__item">
          <a class="cell__link" @click="toggleInputTag">
            {{
              showInputTag ? 'Cancel' : customerTags.length > 0 ? 'Edit' : 'Add'
            }}
          </a>
        </div>
      </div>

      <!-- Submitted tag -->
      <!-- TODO: new component for add and edit tag -->
      <div v-show="!showInputTag" :class="!showInputTag ? 'mb-1' : undefined">
        <!-- TODO: new component for tag wrapper -->
        <div class="tag-wrapper">
          <div v-for="tag in customerTags" :key="tag" class="tag-wrapper__item">
            <div class="tag-wrapper__label">
              {{ tag }}
            </div>
          </div>
        </div>
      </div>

      <!-- Edit tag -->
      <div v-show="showInputTag" class="mt-3 mb-4">
        <!-- TODO: new component for tag wrapper -->
        <div class="tag-wrapper">
          <div
            v-for="(tag, index) in customerTags"
            :key="index"
            class="tag-wrapper__item"
          >
            <div class="tag-wrapper__label">
              {{ tag }}
              <div class="tag-wrapper__icon" @click="removeTag(index)">
                <fluent-icon icon="dismiss" size="12" class="close--icon" />
              </div>
            </div>
          </div>
        </div>
        <input
          ref="tagField"
          v-model="newTag"
          type="text"
          placeholder="Type here and press Enter to save..."
          class="tag-input-field"
          @keyup.enter="addNewTag"
        />
        <!-- Loading state -->
        <div v-if="isLoading" class="text-xs text-center text-[#868E96] mt-2">
          Updating tags...
        </div>
      </div>

      <!-- Notes -->
      <div class="cell" :class="showInputNote ? 'mb-3' : 'mb-1'">
        <div class="cell__item">Notes</div>
        <div class="cell__item">
          <a class="cell__link" @click="toggleInputNote">
            {{
              showInputNote
                ? 'Cancel'
                : customerNote.trim() === ''
                ? 'Add'
                : 'Edit'
            }}
          </a>
        </div>
      </div>
      <div class="mb-4">
        <div class="note-input-field">
          <div v-if="!showInputNote" class="note-input-field__title">
            {{ customerNote }}
          </div>
          <textarea
            v-show="showInputNote"
            ref="noteField"
            v-model="newCustomerNote"
            class="note-input-field__input"
            placeholder="Type here and press Enter to save"
            @keyup.enter="addNewNote"
          />
          <!-- Loading state -->
          <div
            v-if="isLoadingNote"
            class="text-xs text-center text-[#868E96] mt-2"
          >
            Updating notes...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import timeMixin from 'dashboard/mixins/time';
import { mapGetters } from 'vuex';
import shopifyMixin from 'dashboard/mixins/shopifyMixin';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [timeMixin, shopifyMixin, alertMixin],
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const tags = this.customer.tags === null ? '' : this.customer.tags;
    const note = this.customer.note === null ? '' : this.customer.note;
    return {
      newTag: '',
      showInputTag: false,
      showInputNote: false,
      customerTags: tags,
      isLoading: false,
      customerNote: note,
      newCustomerNote: this.customer.note,
      isLoadingNote: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
  },
  methods: {
    toggleInputTag() {
      this.showInputTag = !this.showInputTag;
      this.$nextTick(() => {
        this.$refs.tagField.focus();
      });
    },
    toggleInputNote() {
      this.showInputNote = !this.showInputNote;
      this.newCustomerNote = this.customerNote.trim();
      this.$nextTick(() => {
        this.$refs.noteField.focus();
      });
    },
    removeNewLine(text) {
      const lines = text.split(/\r?\n/);
      const resultLines = [];

      for (let i = 0; i < lines.length; i += 1) {
        const trimmedLine = lines[i].trim();
        if (trimmedLine !== '') {
          resultLines.push(lines[i]);
        }
      }
      return resultLines.join('\n');
    },
    async updateCustomerTag(updatedTags) {
      try {
        if (!this.currentChat.id || !this.customer.id) {
          throw new Error('Current chat ID or customer ID is missing.');
        }
        this.isLoading = true;

        const response = await this.$store.dispatch('updateCustomerData', {
          conversationId: this.currentChat.id,
          params: {
            customer_id: this.customer.id,
            tags: updatedTags,
          },
        });

        this.customerTags = response.tags
          .split(',')
          .map(tag => tag.trim())
          .filter(tag => tag !== '');
        this.isLoading = false;
        this.newTag = '';
        this.toggleInputTag();
      } catch (error) {
        this.showAlert('Error updating tags');
        this.showInputTag = false;
        this.isLoading = false;
      }
    },
    async updateCustomerNote(updatedNotes) {
      try {
        if (!this.currentChat.id || !this.customer.id) {
          throw new Error('Current chat ID or customer ID is missing.');
        }

        this.isLoadingNote = true;

        const response = await this.$store.dispatch('updateCustomerData', {
          conversationId: this.currentChat.id,
          params: {
            customer_id: this.customer.id,
            note: updatedNotes || '',
          },
        });

        this.customerNote = response.note;
        this.isLoadingNote = false;
        this.toggleInputNote();
        this.newCustomerNote = '';
      } catch (error) {
        this.showAlert('Error updating note');
        this.isLoadingNote = false;
        this.showInputNote = false;
      }
    },
    removeTag(index) {
      const updatedTags = [...this.customerTags];
      updatedTags.splice(index, 1);
      this.updateCustomerTag(updatedTags);
    },
    addNewTag() {
      if (this.newTag.trim()) {
        const updatedTags = [...this.customerTags, this.newTag.trim()];
        this.updateCustomerTag(updatedTags);
      } else {
        this.showAlert('New tag cannot be empty.');
      }
    },
    addNewNote() {
      const updatedNote = this.removeNewLine(this.newCustomerNote);
      this.updateCustomerNote(updatedNote);
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-wrapper {
  @apply flex flex-wrap;

  &__item {
    @apply flex items-center mr-3 mb-3;
  }

  &__label {
    @apply flex items-center bg-secondary-low dark:bg-secondary-dark-low text-primary dark:text-stategrey text-[13px] pl-2 pr-2 border-0 rounded-[2px] select-none;
  }

  &__icon {
    @apply relative w-[10px] h-[10px] ml-2.5 p-[6px] transition-all ease-in-out duration-200 cursor-pointer rounded-[1px] text-primary dark:text-stategrey;

    &:hover {
      @apply bg-neutral-subtle dark:bg-[#25292B];
    }

    svg {
      @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }
  }
}

.tag-input-field {
  @apply m-0 py-2.5 px-4 border border-[#E4E3E6] dark:border-slate-700/50 text-sm mt-2 rounded-lg;

  &:focus,
  &:active {
    @apply border border-accent;
  }

  &::placeholder {
    @apply text-sm;
  }
}

.note-input-field {
  &__title {
    @apply text-primary text-sm dark:text-stategrey;
  }
  &__input {
    @apply resize-none min-h-[90px] border-[#E4E3E6] dark:border-slate-700/50 py-2.5 px-3 text-sm mb-0;

    &:focus,
    &:active {
      @apply border border-accent;
    }
  }
}

.cell {
  @apply flex items-center justify-between;

  &__item {
    @apply basis-[35%] text-sm text-[#868E96] leading-tight;

    &:last-child {
      @apply text-right basis-[65%];
    }
  }

  &__link {
    @apply text-accent text-xs transition-all ease-in-out duration-200 select-none font-normal inline-block;

    &:hover {
      @apply text-primary dark:text-stategrey underline;
    }
  }
}
</style>
