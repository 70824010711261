<template>
  <div class="flex h-full min-h-0 overflow-hidden flex-1 px-0">
    <keep-alive v-if="keepAlive">
      <router-view />
    </keep-alive>
    <router-view v-else />
  </div>
</template>

<script>
export default {
  props: {
    keepAlive: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
