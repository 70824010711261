<template>
  <div class="resolve-actions relative flex items-center justify-end">
    <div class="button-group">
      <woot-button
        v-if="isOpen"
        class-names="resolve"
        color-scheme="success"
        icon="checkmark"
        emoji="✅"
        size="small"
        :disabled="isMerged"
        :is-loading="isLoading"
        @click="onCmdResolveConversation"
      >
        {{ isMobile ? '' : $t('CONVERSATION.HEADER.RESOLVE_ACTION') }}
      </woot-button>
      <woot-button
        v-else-if="isResolved"
        class-names="resolve"
        color-scheme="warning"
        icon="arrow-redo"
        emoji="👀"
        size="small"
        :disabled="isMerged"
        :is-loading="isLoading"
        @click="onCmdOpenConversation"
      >
        {{ isMobile ? '' : $t('CONVERSATION.HEADER.REOPEN_ACTION') }}
      </woot-button>
      <woot-button
        v-else-if="showOpenButton"
        class-names="resolve"
        color-scheme="primary"
        icon="person"
        size="small"
        :disabled="isMerged"
        :is-loading="isLoading"
        @click="onCmdOpenConversation"
      >
        {{ isMobile ? '' :$t('CONVERSATION.HEADER.OPEN_ACTION') }}
      </woot-button>
      <woot-button
        v-if="showAdditionalActions"
        ref="arrowDownButton"
        :color-scheme="buttonClass"
        :disabled="isLoading || isMerged"
        icon="chevron-down"
        emoji="🔽"
        size="small"
        class="border-l-0"
        @click="openDropdown"
      />
    </div>
    <div
      v-if="showActionsDropdown"
      v-on-clickaway="closeDropdown"
      class="dropdown-pane dropdown-pane--open"
    >
      <woot-dropdown-menu>
        <woot-dropdown-item v-if="isCsatEnabled && !isResolved">
          <woot-button
            variant="clear"
            color-scheme="secondary"
            size="small"
            icon="checkmark"
            @click="() => toggleStatus(STATUS_TYPE.RESOLVED, undefined, true)"
          >
            <div class="font-normal">
              {{ $t('CONVERSATION.RESOLVE_DROPDOWN.RESOLVE_NO_CSAT') }}
            </div>
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="!isPending">
          <woot-button
            variant="clear"
            color-scheme="secondary"
            size="small"
            icon="snooze"
            @click="() => openSnoozeModal()"
          >
            <div class="font-normal">
              {{ $t('CONVERSATION.RESOLVE_DROPDOWN.SNOOZE_UNTIL') }}
            </div>
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="!isPending">
          <woot-button
            v-if="!currentChat.muted"
            variant="clear"
            size="small"
            color-scheme="secondary"
            icon="speaker-mute"
            @click="mute"
          >
            <div class="font-normal">
              {{ $t('CONTACT_PANEL.MUTE_CONTACT') }}
            </div>
          </woot-button>
          <woot-button
            v-else
            variant="clear"
            size="small"
            color-scheme="secondary"
            icon="speaker-unmute"
            @click="unmute"
          >
            <div class="font-normal">
              {{ $t('CONTACT_PANEL.UNMUTE_CONTACT') }}
            </div>
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="!isPending">
          <woot-button
            variant="clear"
            size="small"
            color-scheme="secondary"
            icon="share"
            @click="toggleEmailActionsModal"
          >
            <div class="font-normal">
              {{ $t('CONTACT_PANEL.SEND_TRANSCRIPT') }}
            </div>
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item
          v-if="$featureFlags['merge-conversations'] && !isMetaInbox"
        >
          <woot-button
            variant="clear"
            size="small"
            icon-view-box="-2 -0.5 14 14"
            icon="merge-new"
            color-scheme="secondary"
            @click="toggleMergeModal"
          >
            <div class="font-normal">
              {{ $t('CONVERSATION.RESOLVE_DROPDOWN.MERGE_CONVERSATION') }}
            </div>
          </woot-button>
        </woot-dropdown-item>
      </woot-dropdown-menu>
    </div>
    <woot-modal
      :show.sync="showCustomSnoozeModal"
      :on-close="hideCustomSnoozeModal"
    >
      <custom-snooze-modal
        @close="hideCustomSnoozeModal"
        @choose-time="chooseSnoozeTime"
      />
    </woot-modal>
    <email-transcript-modal
      v-if="showEmailActionsModal"
      :show="showEmailActionsModal"
      :current-chat="currentChat"
      @cancel="toggleEmailActionsModal"
    />
    <merge-conversation-modal
      :show="showMergeModal"
      @cancel="toggleMergeModal"
      @merge="onMergeReview"
    />
    <merge-confirmation-modal
      :show="showMergeConfirmModal"
      :is-loading="isMergeLoading"
      @submit="onMergeSubmit"
      @cancel="toggleMergeConfirmModal"
    />
  </div>
</template>

<script>
import { getUnixTime } from 'date-fns';
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import CustomSnoozeModal from 'dashboard/components/CustomSnoozeModal.vue';
import { findSnoozeTime } from 'dashboard/helper/snoozeHelpers';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';
import wootConstants from 'dashboard/constants/globals';
import EmailTranscriptModal from 'dashboard/components/widgets/conversation/EmailTranscriptModal.vue';
import MergeConversationModal from 'dashboard/components/widgets/conversation/MergeConversationModal.vue';
import MergeConfirmationModal from 'dashboard/components/widgets/conversation/MergeConfirmationModal.vue';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';

import {
  CMD_REOPEN_CONVERSATION,
  CMD_RESOLVE_CONVERSATION,
  CMD_SNOOZE_CONVERSATION,
  CMD_MUTE_CONVERSATION,
  CMD_SEND_TRANSCRIPT,
  CMD_UNMUTE_CONVERSATION,
  CMD_MERGE_CONVERSATION,
} from '../../routes/dashboard/commands/commandBarBusEvents';

export default {
  components: {
    WootDropdownItem,
    WootDropdownMenu,
    CustomSnoozeModal,
    EmailTranscriptModal,
    MergeConversationModal,
    MergeConfirmationModal,
  },
  mixins: [clickaway, alertMixin],
  inject: ['uiCommandBus'],
  props: {
    conversationId: {
      type: [String, Number],
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      showActionsDropdown: false,
      STATUS_TYPE: wootConstants.STATUS_TYPE,
      showCustomSnoozeModal: false,
      showEmailActionsModal: false,
      showMergeModal: false,
      showMergeConfirmModal: false,
      mergeData: {},
      isMergeLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    isOpen() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.OPEN;
    },
    isPending() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.PENDING;
    },
    isResolved() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.RESOLVED;
    },
    isSnoozed() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.SNOOZED;
    },
    isMerged() {
      const isMergeEnabled = this.$featureFlags['merge-conversations'];
      const isStatusMerged =
        this.currentChat.status === wootConstants.STATUS_TYPE.MERGED;

      return isMergeEnabled && isStatusMerged;
    },
    isCsatEnabled() {
      return this.$store.getters['inboxes/getInbox'](this.currentChat.inbox_id)
        ?.csat_survey_enabled;
    },
    buttonClass() {
      if (this.isPending) return 'primary';
      if (this.isOpen) return 'success';
      if (this.isResolved) return 'warning';
      if (this.isMerged) return 'primary';
      return '';
    },
    showAdditionalActions() {
      return !this.isPending && !this.isSnoozed && !this.isMerged;
    },
    isMetaInbox() {
      return this.currentChat.meta.channel === INBOX_TYPES.FB;
    },
    isInsideFolder() {
      return this.$route.name === 'conversations_through_folders';
    },

  },
  mounted() {
    bus.$on(CMD_SNOOZE_CONVERSATION, this.onCmdSnoozeConversation);
    bus.$on(CMD_REOPEN_CONVERSATION, this.onCmdOpenConversation);
    bus.$on(CMD_RESOLVE_CONVERSATION, this.onCmdResolveConversation);

    // keyboard shortcut for mute and transcript
    bus.$on(CMD_MUTE_CONVERSATION, this.mute);
    bus.$on(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$on(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);

    // keyboard shortcut for merge conversation
    bus.$on(CMD_MERGE_CONVERSATION, this.toggleMergeModal);

    this.uiCommandBus.$on('command', this.handleUiCommand);
  },
  destroyed() {
    this.uiCommandBus.$off('command', this.handleUiCommand);

    bus.$off(CMD_SNOOZE_CONVERSATION, this.onCmdSnoozeConversation);
    bus.$off(CMD_REOPEN_CONVERSATION, this.onCmdOpenConversation);
    bus.$off(CMD_RESOLVE_CONVERSATION, this.onCmdResolveConversation);

    // keyboard shortcut for mute and transcript
    bus.$off(CMD_MUTE_CONVERSATION, this.mute);
    bus.$off(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$off(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);

    // keyboard shortcut for merge conversation
    bus.$off(CMD_MERGE_CONVERSATION, this.toggleMergeModal);
  },
  methods: {
    onCmdSnoozeConversation(snoozeType) {
      if (this.isMerged) {
        return;
      }
      if (snoozeType === wootConstants.SNOOZE_OPTIONS.UNTIL_CUSTOM_TIME) {
        this.showCustomSnoozeModal = true;
      } else {
        this.toggleStatus(
          this.STATUS_TYPE.SNOOZED,
          findSnoozeTime(snoozeType) || null
        );
      }
    },
    chooseSnoozeTime(customSnoozeTime) {
      this.showCustomSnoozeModal = false;
      if (customSnoozeTime) {
        this.toggleStatus(
          this.STATUS_TYPE.SNOOZED,
          getUnixTime(customSnoozeTime)
        );
      }
    },
    hideCustomSnoozeModal() {
      this.showCustomSnoozeModal = false;
    },
    onCmdOpenConversation() {
      if (this.isMerged) {
        return;
      }
      if (this.isOpen) {
        this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS_FAILED'));
        return;
      }
      
      this.resolveCurrentAndMoveToNext();
    },
    onCmdResolveConversation() {
      if (this.isMerged) {
        return;
      }
      if (this.isResolved) {
        this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS_FAILED'));
        return;
      }

      this.resolveCurrentAndMoveToNext();
    },
    showOpenButton() {
      return this.isResolved || this.isSnoozed;
    },
    closeDropdown() {
      this.showActionsDropdown = false;
    },
    openDropdown() {
      this.showActionsDropdown = true;
    },
    toggleStatus(status, snoozedUntil, noCsat) {
      this.closeDropdown();
      this.isLoading = true;
      this.$store
        .dispatch('toggleStatus', {
          conversationId: this.currentChat.id,
          status,
          snoozedUntil,
          noCsat,
        })
        .then(() => {
          this.showAlert(this.$t('CONVERSATION.CHANGE_STATUS'));
          this.isLoading = false;
        });
    },
    openSnoozeModal() {
      const ninja = document.querySelector('ninja-keys');
      ninja.open({ parent: 'snooze_conversation' });
    },
    async resolveCurrentAndMoveToNext(
      // status = wootConstants.STATUS_TYPE.RESOLVED,
      snoozedUntil = null
    ) {
      const allConversations = document.querySelectorAll(
        '.conversations-list .conversation'
      );
      const activeConversation = document.querySelector(
        'div.conversations-list div.conversation.active'
      );
      const activeConversationIndex = [...allConversations].indexOf(
        activeConversation
      );
      const lastConversationIndex = allConversations.length - 1;


      // if current conversation is open, then resolve it
      // if current conversation is resolved/snoozed, then open it
      const nextStatus = this.isOpen ? wootConstants.STATUS_TYPE.RESOLVED : wootConstants.STATUS_TYPE.OPEN;
      try {
        await this.toggleStatus(
          nextStatus,
        snoozedUntil
      );
      } catch (error) {
        // error
      }

      if (activeConversationIndex < lastConversationIndex) {
        allConversations[activeConversationIndex + 1].click();
      } else if (allConversations.length > 1) {
        allConversations[0].click();
        document.querySelector('.conversations-list').scrollTop = 0;
      }
    },
    handleUiCommand(command) {
      if (command === 'resolve-conversation') this.onCmdResolveConversation();
      else if (command === 'snooze-conversation') this.openSnoozeModal();
    },
    mute() {
      this.$store.dispatch('muteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.MUTED_SUCCESS'));
    },
    unmute() {
      this.$store.dispatch('unmuteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.UNMUTED_SUCCESS'));
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
      this.showActionsDropdown = false;
    },
    toggleMergeModal() {
      this.showMergeModal = !this.showMergeModal;
      this.showActionsDropdown = false;
    },
    toggleMergeConfirmModal() {
      if (this.isMergeLoading) {
        return;
      }
      this.showMergeConfirmModal = !this.showMergeConfirmModal;
    },
    onMergeReview(data) {
      this.mergeData = data;
      this.showMergeConfirmModal = !this.showMergeConfirmModal;
    },
    async onMergeSubmit() {
      this.isMergeLoading = true;
      try {
        const response = await this.$store.dispatch('mergeConversations', {
          primary_id: this.mergeData.primaryId,
          secondary_id: this.mergeData.secondaryId,
        });

        if (response.error) {
          this.showAlert(response.error);
        } else {
          // set status to merged on each conversation ID
          this.$store.dispatch('toggleStatus', {
            conversationId: this.mergeData.secondaryId,
            status: wootConstants.STATUS_TYPE.MERGED,
          });
          this.showAlert(this.$t('CONVERSATION.MERGE_CONFIRMATION.SUCCESS'));
        }
      } catch (error) {
        this.showAlert(this.$t('CONVERSATION.MERGE_CONFIRMATION.FAILED'));
      } finally {
        this.isMergeLoading = false;
        this.toggleMergeConfirmModal();
        this.toggleMergeModal();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.resolve-actions {
  ::v-deep {
    .button.resolve,
    .button.button--only-icon {
      @apply h-[32px];
    }
  }
}

.dropdown-pane {
  @apply left-auto top-[2rem] mt-0.5 right-0 max-w-[12.5rem] min-w-[9.75rem];

  .dropdown-menu__item {
    @apply mb-0;
  }

  .button.clear.secondary {
    @apply text-primary dark:text-primary-dark;
  }
}
</style>
