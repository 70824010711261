export const isHtmlContent = content => {
    // Guard against null/undefined
    if (!content || typeof content !== 'string') return false;
  
    try {
      // Use DOMParser to properly parse the content
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');
  
      // Check if parsing resulted in any HTML elements in the body
      const hasElements = Array.from(doc.body.childNodes).some(
        node => node.nodeType === Node.ELEMENT_NODE
      );
  
      // Check if content has HTML entities
      const hasHtmlEntities = /&(?:[a-z\d]+|#\d+|#x[a-f\d]+);/i.test(content);
  
      // Check if the content matches our allowed HTML tags
      const allowedTags = [
        'p', 'div', 'span', 'a', 'strong', 'em', 'u', 'i', 'b',
        'ul', 'ol', 'li', 'blockquote', 'pre', 'code', 'br', 'img',
        'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'table', 'tr', 'td', 'th'
      ];
      
      const tagPattern = new RegExp(
        `<(${allowedTags.join('|')})(\\s[^>]*)?>(.*?)</\\1>|<(${allowedTags.join('|')})(\\s[^>]*)?/>`,
        'i'
      );
  
      return hasElements && (hasHtmlEntities || tagPattern.test(content));
  
    } catch (error) {
      console.error('Error detecting HTML content: ', error);
      return false;
    }
};