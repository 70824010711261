<template>
  <div class="intent-item">
    <div class="intent-item__head">
      <div
        v-tooltip="{
          content: 'Edit intent',
        }"
        class="mb-2 text-sm font-medium cursor-pointer hover:text-neutral-dark-medium hover:dark:text-neutral-subtle"
        @click="onClickTitle"
      >
        {{ question }}
      </div>
      <div class="text-sm text-secondary dark:text-secondary-dark">
        <show-more :char-limit="respondCharLimit" :text="respond" />
      </div>
    </div>
    <div class="intent-item__body">
      <div class="intent-item__body-item">
        <time-ago :last-activity-timestamp="modifiedAt" />
      </div>
      <div class="intent-item__body-item">
        <!-- Slot for toggle/switch component -->
        <slot name="switch" />
      </div>
      <div class="intent-item__body-item">
        <!-- Slot for popover menu -->
        <slot name="menu" />
      </div>
    </div>
  </div>
</template>

<script>
import ShowMore from 'shared/components/ShowMore.vue';
import TimeAgo from '../components/TimeAgo.vue';

export default {
  components: {
    ShowMore,
    TimeAgo,
  },
  props: {
    question: {
      type: String,
      default: '',
    },
    respond: {
      type: String,
      default: '',
    },
    createdAt: {
      type: String,
      default: '',
    },
    modifiedAt: {
      type: String,
      default: '',
    },
    respondCharLimit: {
      type: Number,
      default: 200,
    },
  },
  methods: {
    onClickTitle(intentId) {
      this.$emit('click-title', intentId);
    },
  },
};
</script>

<style lang="scss" scoped>
.intent-item {
  @apply p-4 border border-neutral-medium dark:border-neutral-dark-medium bg-white dark:bg-primary flex items-center rounded-xl gap-4;
  &__head {
    @apply w-[60%] flex items-start flex-col text-sm;
  }

  &__body {
    @apply flex items-center w-[40%] justify-end text-sm gap-4;
  }

  &__body-item {
    @apply flex items-center justify-center gap-2;

    &:first-child {
      @apply sm:justify-start md:w-[100%] 2xl:justify-center 2xl:w-[calc(100%-180px)];
    }
  }
}
</style>
