<template>
  <div class="flex flex-col flex-1">
    <section class="conversation-page bg-white dark:bg-slate-900 pb-0">
      <chat-list
        is-inside-playground
        :conversation-inbox="inboxId"
        @conversation-load="onConversationLoad"
      />
      <conversation-box
        is-inside-playground
        :inbox-id="inboxId"
        :is-contact-panel-open="isContactPanelOpen"
        @contact-panel-toggle="onToggleContactPanel"
      />
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import ChatList from 'dashboard/components/ChatList.vue';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import ConversationBox from 'dashboard/components/widgets/conversation/ConversationBox.vue';

export default {
  components: {
    ChatList,
    ConversationBox,
  },
  mixins: [uiSettingsMixin],
  provide() {
    return {
      uiCommandBus: this.uiCommandBus,
    };
  },
  props: {
    inboxId: {
      type: [String, Number],
      default: 0,
    },
    conversationId: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      uiCommandBus: new Vue(),
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      chatList: 'getAllConversations',
      currentChat: 'getSelectedChat',
    }),
    isContactPanelOpen() {
      if (this.currentChat.id) {
        const { is_contact_sidebar_open: isContactSidebarOpen } =
          this.uiSettings;
        return isContactSidebarOpen;
      }
      return false;
    },
  },
  watch: {
    conversationId() {
      this.fetchConversationIfUnavailable();
    },
  },
  mounted() {
    this.initialize();
    this.$watch('$store.state.route', () => this.initialize());

    // set active conversation on window reload
    this.$watch('chatList.length', () => {
      this.setActiveChat();
    });
  },
  methods: {
    initialize() {
      this.$store.dispatch('setActiveInbox', this.inboxId);
      this.setActiveChat();
    },
    setActiveChat() {
      if (this.conversationId) {
        const selectedConversation = this.findConversation();
        // If conversation doesn't exist or selected conversation is same as the active
        // conversation, don't set active conversation.
        if (
          !selectedConversation ||
          selectedConversation.id === this.currentChat.id
        ) {
          return;
        }
        const { messageId } = this.$route.query;
        this.$store
          .dispatch('setActiveChat', {
            data: selectedConversation,
            after: messageId,
          })
          .then(() => {
            bus.$emit(BUS_EVENTS.SCROLL_TO_MESSAGE, { messageId });
          });
      } else {
        this.$store.dispatch('clearSelectedState');
      }
    },
    onToggleContactPanel() {
      this.updateUISettings({
        is_contact_sidebar_open: !this.isContactPanelOpen,
      });
    },
    onConversationLoad() {
      this.fetchConversationIfUnavailable();
    },
    async fetchConversationIfUnavailable() {
      if (!this.conversationId) {
        return;
      }
      const chat = this.findConversation();
      if (!chat) {
        try {
          await this.$store.dispatch('getConversation', this.conversationId);
        } catch (error) {
          if(error.response.status === 404) {
            this.$router.push({ name: 'ai_playground' });
          }
        }
      }
    },
    findConversation() {
      const conversationId = parseInt(this.conversationId, 10);
      const [chat] = this.chatList.filter(c => c.id === conversationId);
      return chat;
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation-page {
  display: flex;
  width: 100%;
  height: 100%;
}
</style>
