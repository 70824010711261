<template>
  <div
    class="relative flex items-start flex-grow-0 flex-shrink-0 w-auto max-w-full px-4 py-0 border-t-0 border-b-0 border-l-2 border-r-0 border-transparent border-solid cursor-pointer conversation hover:bg-slate-25 dark:hover:bg-slate-800 group"
    :class="{
      'active bg-slate-25 dark:bg-slate-800 border-woot-500': isActiveChat,
    }"
    @click="onCardClick(chat.account_id, chat.id, chat.inbox_id)"
    @contextmenu="openContextMenu($event)"
  >
    <thumbnail
      class="columns mt-4"
      :username="chat.meta.sender.name"
      size="40px"
    />
    <div
      class="px-0 py-3 border-b group-last:border-transparent group-hover:border-transparent border-slate-50 dark:border-slate-800/75 columns"
    >
      <h4
        class="conversation--user text-sm my-0 mx-2 capitalize pt-0.5 text-ellipsis overflow-hidden whitespace-nowrap w-[60%] text-slate-900 dark:text-slate-100"
      >
        {{ chat.meta.sender.name }}
      </h4>
      <message-preview
        v-if="lastMessageInChat"
        :message="lastMessageInChat"
        class="conversation--message my-0 mx-2 leading-6 h-6 max-w-[72%] w-[16.875rem] text-sm text-slate-700 dark:text-slate-200"
      />
    </div>
    <div class="absolute flex flex-col conversation--meta right-4 top-4">
      <span class="ml-auto font-normal leading-4 text-black-600 text-xxs">
        <time-ago
          :last-activity-timestamp="chat.timestamp"
          :created-at-timestamp="chat.created_at"
        />
      </span>
    </div>
    <woot-context-menu
      v-if="showContextMenu"
      ref="menu"
      :x="contextMenu.x"
      :y="contextMenu.y"
      @close="closeContextMenu"
    >
      <playground-context-menu v-if="$featureFlags['soft-delete-conversations'] && isAdmin" @delete="onDeleteConversation" />
    </woot-context-menu>
  </div>
</template>
<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import conversationMixin from 'dashboard/mixins/conversations';
import MessagePreview from 'dashboard/components/widgets/conversation/MessagePreview.vue';
import TimeAgo from 'dashboard/components/ui/TimeAgo.vue';
import PlaygroundContextMenu from './PlaygroundContextMenu.vue';
import alertMixin from 'shared/mixins/alertMixin';
import adminMixin from 'dashboard/mixins/isAdmin';

export default {
  components: {
    Thumbnail,
    MessagePreview,
    TimeAgo,
    PlaygroundContextMenu,
  },
  mixins: [conversationMixin, adminMixin, alertMixin],
  props: {
    chat: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showContextMenu: false,
      contextMenu: {
        x: null,
        y: null,
      },
    };
  },
  computed: {
    lastMessageInChat() {
      return this.lastMessage(this.chat);
    },
    currentId() {
      return Number(this.$route.params.id);
    },
    isActiveChat() {
      return this.currentId === this.chat.id;
    },
  },
  methods: {
    onCardClick(accountId, id, inboxId) {
      if (!accountId || !inboxId || !id) return;

      const newPath = `/app/accounts/${accountId}/ai/playground/inbox/${inboxId}/conversations/${id}`;
      if (newPath !== this.$route.path) {
        this.$router.push(newPath);
      }
    },
    openContextMenu(e) {
      e.preventDefault();
      this.$emit('context-menu-toggle', true);
      this.contextMenu.x = e.pageX || e.clientX;
      this.contextMenu.y = e.pageY || e.clientY;
      this.showContextMenu = true;
    },
    closeContextMenu() {
      this.$emit('context-menu-toggle', false);
      this.showContextMenu = false;
      this.contextMenu.x = null;
      this.contextMenu.y = null;
    },
    onDeleteConversation() {
      this.$emit('delete-conversation');
      this.closeContextMenu();
    }
  },
};
</script>
<style lang="scss" scoped>
.conversation {
  &.unread-chat {
    .unread {
      @apply block;
    }

    .conversation--message {
      @apply font-semibold;
    }

    .conversation--user {
      @apply font-semibold;
    }
  }

  &.compact {
    @apply pl-0;
    .conversation--details {
      @apply rounded-sm ml-0 pl-5 pr-2;
    }
  }

  &::v-deep .user-thumbnail-box {
    @apply mt-4;
  }

  &.conversation-selected {
    @apply bg-slate-25 dark:bg-slate-800;
  }

  &.has-inbox-name {
    &::v-deep .user-thumbnail-box {
      @apply mt-8;
    }

    .checkbox-wrapper {
      @apply mt-8;
    }

    .conversation--meta {
      @apply mt-4;
    }
  }

  .checkbox-wrapper {
    @apply h-10 w-10 flex items-center justify-center rounded-full cursor-pointer mt-4 hover:bg-woot-100 dark:hover:bg-woot-800;

    input[type='checkbox'] {
      @apply m-0 cursor-pointer;
    }
  }

  .moderation {
    @apply flex items-center justify-end gap-0.5 relative mt-1.5 select-none mr-[-5px];

    &__item {
      @apply flex items-center justify-center rounded-sm w-[24px] h-[24px] relative;
    }

    &__icon {
      @apply w-[15px] m-auto text-[#5647FF] dark:text-[#9389fe];
    }

    &__icon--action {
      @apply text-secondary dark:text-secondary-dark;
    }

    &__spinner {
      @apply ml-0 mt-0 p-0;

      &:before {
        @apply ml-[-8px];
      }
    }
  }
}

.context-menu--delete-modal {
  ::v-deep {
    .modal-container {
      @apply max-w-[30rem];

      h2 {
        @apply font-medium text-base;
      }
    }

    .modal-footer {
      @apply pt-4 pb-8 px-8;
    }
  }
}
</style>
