<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.GMAIL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.GMAIL.DESC')"
    />
    <woot-loading-state
      v-if="isLoading"
      :message="$t('INBOX_MGMT.ADD.GMAIL.LOADING_MESSAGE')"
    />
    <div v-if="hasError" class="gmail-error-state">
      {{ errorStateMessage }}
    </div>
    <button
      v-if="!hasLoginStarted && !isLoading"
      class="login-with-google-btn mt-2"
      @click="initGmailAuth"
    >
      {{ $t('INBOX_MGMT.ADD.GMAIL.LOAD_BUTTON') }}
    </button>
    <form
      v-if="gmailAuthorized && !isLoading"
      class="mx-0 flex flex-wrap"
      @submit.prevent="createChannel"
    >
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.shopId.$error }">
          {{ $t('INBOX_MGMT.ADD.SHOPIFY.CONNECTED_SHOP') }}
          <select v-model="shopId">
            <option
              v-for="shop in account.shops"
              :key="shop.shopify_domain"
              :value="shop.id"
            >
              {{ shop.shopify_domain }}
            </option>
          </select>
        </label>
      </div>
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.inboxName.$error }">
          {{ $t('INBOX_MGMT.ADD.GMAIL.INBOX_NAME.LABEL') }}
          <input
            v-model.trim="inboxName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.GMAIL.INBOX_NAME.PLACEHOLDER')"
          />
        </label>
      </div>
      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.GMAIL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import router from '../../../../../index';
import PageHeader from '../../../SettingsSubPageHeader.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'Gmail',
  components: {
    PageHeader,
  },
  mixins: [alertMixin],

  data() {
    return {
      hasLoginStarted: false,
      hasError: false,
      isLoading: false,
      errorStateMessage: '',
      gmailAuthorized: false,
      inboxName: '',
      code: '',
      shopId: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      accountId: 'getCurrentAccountId',
    }),
    account() {
      return this.$store.getters['accounts/getAccount'](this.accountId);
    },
  },
  validations: {
    inboxName: { required },
    shopId: { required },
  },
  mounted() {
    this.loadGoogleLibrary()
      .then(() => console.log('Google library loaded successfully'))
      .catch(error => {
        this.hasError = true;
        this.errorStateMessage = 'Failed to load Google library';
        console.error(this.errorStateMessage, error);
      });
  },
  methods: {
    loadGoogleLibrary() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.isLoading = true;
      try {
        await this.exchangeAuthCode(this.code, this.inboxName, this.shopId);
      } catch (error) {
        this.hasError = true;
        this.gmailAuthorized = false;
        this.errorStateMessage = error?.message;
        this.isLoading = false;
        this.hasLoginStarted = false;
        this.showAlert(this.errorStateMessage);
      }
    },
    async initGmailAuth() {
      try {
        this.hasLoginStarted = true;
        this.isLoading = true;

        const client = google.accounts.oauth2.initCodeClient({
          client_id: window.chatwootConfig.gmailOAuthClientId,
          scope:
            'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send',
          ux_mode: 'popup',
          include_granted_scopes: false,
          callback: this.handleAuthResponse,
        });

        client.requestCode();
        this.initializeSelectedShopId();
      } catch (error) {
        this.hasError = true;
        this.gmailAuthorized = false;
        this.hasLoginStarted = false;
        this.errorStateMessage = 'Initialization failed';
        this.isLoading = false;
      }
    },
    handleAuthResponse(response) {
      if (response.code) {
        this.code = response.code;
        this.gmailAuthorized = true;
        this.isLoading = false;
      } else {
        this.hasError = true;
        this.hasLoginStarted = false;
        this.gmailAuthorized = false;
        this.errorStateMessage = 'Authentication failed';
        this.showAlert(this.errorStateMessage);
      }
    },
    async exchangeAuthCode(code, inbox_name, shop_id) {
      const inbox = await this.$store.dispatch('inboxes/createGMailChannel', {
        code,
        inbox_name,
        shop_id,
      });
      this.isLoading = false;
      router.replace({
        name: 'settings_inboxes_add_agents',
        params: {
          page: 'new',
          inbox_id: inbox.id,
        },
      });
    },
    initializeSelectedShopId() {
      this.shopId = this.account?.shops[0]?.id ?? '';
    }
  },
};
</script>
<style lang="scss">
.login-with-google-btn {
  padding: 12px 16px 12px 42px;

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;

  @apply border border-neutral-medium border-solid dark:border-neutral-dark-medium rounded-md transition-all ease-in-out duration-200;

  &:hover {
    box-shadow:
      0 -1px 0 rgba(0, 0, 0, 0.04),
      0 2px 4px rgba(0, 0, 0, 0.25);
    @apply border-accent dark:border-neutral-dark-medium shadow-medium cursor-pointer;
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow:
      0 -1px 0 rgba(0, 0, 0, 0.04),
      0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow:
      0 -1px 0 rgba(0, 0, 0, 0.04),
      0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}

.gmail-error-state {
  @apply border bg-error bg-opacity-[0.2] border-error-medium dark:border-error-dark-medium p-2 px-3 mb-3 text-sm rounded-sm;
}
</style>
