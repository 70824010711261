<template>
  <div
    class="flex flex-col items-center justify-center h-full m-auto"
    :class="isInsidePlayground ? 'w-[400px]' : 'w-[355px]'"
  >
    <img
      class="m-4 hidden dark:block"
      :class="isInsidePlayground ? 'w-[365px]' : 'w-[196px]'"
      :src="isInsidePlayground ? imgDarkPlayground : imgSrcDark"
      alt="No Chat dark"
    />
    <img
      class="m-4 block dark:hidden"
      :class="isInsidePlayground ? 'w-[365px]' : 'w-[196px]'"
      :src="isInsidePlayground ? imgLightPlayground : imgSrc"
      alt="No Chat"
    />
    <span
      v-dompurify-html="message"
      class="text-sm text-primary dark:text-primary-dark font-medium text-center"
    />
    <!-- Cmd bar, keyboard shortcuts placeholder -->
    <feature-placeholder :shortcuts="shortcuts" />
  </div>
</template>

<script>
import FeaturePlaceholder from './FeaturePlaceholder.vue';
import darkImage from 'dashboard/assets/images/no-chat-dark.svg';
import lightImage from 'dashboard/assets/images/no-chat.svg';
import darkPlayground from 'dashboard/assets/images/playground-dark.svg';
import lightPlayground from 'dashboard/assets/images/playground-light.svg';

export default {
  components: { FeaturePlaceholder },
  props: {
    message: {
      type: String,
      required: true,
    },
    imgSrc: {
      type: String,
      default: () => lightImage,
    },
    imgSrcDark: {
      type: String,
      default: () => darkImage,
    },
    shortcuts: {
      type: Array,
      default: () => [],
    },
    imgDarkPlayground: {
      type: String,
      default: () => darkPlayground,
    },
    imgLightPlayground: {
      type: String,
      default: () => lightPlayground,
    },
    isInsidePlayground: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
