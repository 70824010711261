export const state = {
  copiedData: null,
};

export const getters = {
  getCopiedData: _state => _state.copiedData,
};

export const actions = {
  copyData({ commit }, data) {
    // const payloadArray = Array.isArray(data) ? data : [data];
    // commit('setCopiedData', payload);
    commit('setCopiedData', data);
  },
  clearCopiedData({ commit }) {
    commit('clearCopiedData');
  },
};

export const mutations = {
  setCopiedData(_state, data) {
    _state.copiedData = data;
  },
  clearCopiedData(_state) {
    _state.copiedData = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
