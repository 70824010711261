<template>
  <div
    ref="helpElement"
    v-tooltip.right="tooltipOptions"
    class="help-menu"
    :class="{
      'justify-center relative': !isSidebarExpanded,
      'help-menu--active': isShowDropdown,
    }"
    @click="handleShowDropdown"
  >
    <div
      class="help-menu__prefix"
      :class="{ 'mr-2 rtl:mr-0 rtl:ml-2': isSidebarExpanded }"
    >
      <Icon
        size="18"
        view-box="0 -0.3 14 15"
        icon="question"
        type="outline"
        :icons="iconLib"
        is-in-sidebar
      />
    </div>
    <template v-if="isSidebarExpanded">
      <div class="help-menu__text">Help</div>
    </template>

    <!-- primary menu dropdown -->
    <div
      v-if="isShowDropdown"
      class="help-menu__wrapper"
      :style="dropdownStyle"
    >
      <div class="help-menu__body">
        <div
          v-for="(item, index) in helpMenuItems"
          :ref="`menuitem-${item.id}`"
          :key="index"
          class="nav-item"
          :class="{
            'flex items-center hover:bg-neutral-low dark:hover:bg-primary':
              !item.child,
            '!cursor-default': item.child,
          }"
          @click.stop="handleClick(item)"
        >
          <div class="flex items-center">
            <Icon
              class="nav-item-icon"
              :size="item.iconSize"
              :icon="item.icon"
              type="outline"
              :icons="iconLib"
              :view-box="item.viewBox"
            />
            <span>{{ item.label }}</span>
          </div>

          <!-- child menu for contact us -->
          <div class="child-menu">
            <div
              v-for="(childItem, childIndex) in item.child"
              :key="childIndex"
              class="child-menu__item hover:bg-neutral-low dark:hover:bg-primary cursor-pointer"
            >
              <div @click.stop="handleChildClick(childItem)">
                <span>{{ childItem.label }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'shared/components/FluentIcon/Icon.vue';
import icons from 'shared/components/FluentIcon/sidebar-icons.json';
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import alertMixin from 'shared/mixins/alertMixin';

const initializeFeaturebase = (win = window) => {
  if (typeof win.Featurebase !== 'function') {
    win.Featurebase = function featurebase() {
      (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
    };
  }
  return win.Featurebase;
};

export default {
  components: {
    Icon,
  },
  mixins: [alertMixin],
  props: {
    isSidebarExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowDropdown: false,
      dropdownStyle: {
        position: 'fixed',
        top: '0px',
        left: '0px',
      },
      helpMenuItems: [
        {
          id: 'docs',
          icon: 'book',
          iconSize: '16',
          viewBox: '0 0 12 13',
          label: 'Help docs',
          link: 'https://app.commslayer.com/hc/help-center/en/',
        },
        {
          id: 'featurebase',
          icon: 'board',
          iconSize: '17',
          label: 'Feature requests',
          viewBox: '0 0 14 13',
        },
        {
          id: 'contact',
          icon: 'contact',
          iconSize: '19',
          label: 'Contact us',
          viewBox: '0 0 11 13',
          child: [
            {
              id: 0,
              label: 'Copy email address',
              link: 'karri@commslayer.com',
            },
            {
              id: 1,
              label: 'Send email from app',
              link: 'mailto:karri@commslayer.com',
            },
          ],
        },
      ],
    };
  },
  computed: {
    iconLib() {
      return icons;
    },
    tooltipOptions() {
      if (this.isSidebarExpanded || this.isShowDropdown) {
        return { content: '', classes: '' };
      }
      return {
        content: 'Help',
        classes: 'new-sidebar-tooltip',
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.updateDropdownPosition);
      this.updateDropdownPosition();
    });
    window.addEventListener('mouseup', this.onOutsideClick);
    document.body.addEventListener('click', this.handleBodyClick);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDropdownPosition);
    window.removeEventListener('mouseup', this.onOutsideClick);
    document.body.addEventListener('click', this.handleBodyClick);
  },
  methods: {
    handleShowDropdown() {
      this.isShowDropdown = !this.isShowDropdown;
      this.$nextTick(this.updateDropdownPosition);
    },
    handleCloseDropdown() {
      this.isShowDropdown = false;
    },
    onOutsideClick(e) {
      if (
        this.isShowDropdown &&
        e.target !== this.$refs.helpElement &&
        !this.$refs.helpElement.contains(e.target) &&
        !this.$refs.helpElement.contains(e.target)
      ) {
        this.handleCloseDropdown();
      }
    },
    updateDropdownPosition() {
      if (this.$refs.helpElement) {
        const parentRect = this.$refs.helpElement.getBoundingClientRect();
        this.dropdownStyle.top = `${parentRect.top - 195}px`;
        this.dropdownStyle.left = `${parentRect.left}px`;
      }
    },
    featurebaseAction(action) {
      initializeFeaturebase()(`${action}_widget`);
    },
    handleBodyClick(e) {
      const featurebaseTrigger = this.$refs['menuitem-featurebase'];
      if (e.target !== featurebaseTrigger) {
        this.featurebaseAction('close');
      }
    },
    handleClick(item) {
      this.handleCloseDropdown();

      const featurebaseTrigger = this.$refs['menuitem-featurebase'];
      if (featurebaseTrigger && item.id === 'featurebase') {
        this.featurebaseAction('open');
      }

      if (!item.child && item.link) {
        window.open(item.link, '_blank');
      }
    },
    handleChildClick(childItem) {
      this.handleCloseDropdown();

      if (childItem.link.startsWith('mailto:')) {
        window.location.href = childItem.link;
      } else {
        this.onCopy(childItem.link);
      }
    },
    async onCopy(attributeValue) {
      await copyTextToClipboard(attributeValue);
      this.showAlert('Copied to clipboard successfully');
    },
  },
};
</script>

<style lang="scss" scoped>
.help-menu {
  @apply flex
  items-center
  rounded-[4px]
  leading-4
  font-medium
  text-sm
  p-2
  mt-2
  h-[33px]
  text-secondary
  dark:text-secondary
  hover:text-secondary
  hover:bg-neutral-medium
  dark:hover:bg-[#222527]
  border
  border-transparent
  cursor-pointer;

  &--active {
    @apply bg-neutral-medium dark:bg-[#222527];
  }

  &__prefix {
    @apply w-[18px] h-[19px] relative;
  }
  &__text {
    @apply leading-none w-[80%];
  }

  &__wrapper {
    @apply w-[245px] bg-white dark:bg-[#222526] z-10 shadow-dropdown rounded-lg overflow-hidden border border-neutral-medium dark:border-neutral-dark-medium;
  }

  &__body {
    @apply h-[185px] overflow-y-auto px-2 py-1;
  }
}

.nav-item {
  @apply text-primary
  dark:text-primary-dark
  rounded-[4px]
  leading-4
  m-0
  mt-0.5
  mb-0.5
  text-sm
  h-[33px]
  p-2
  relative
  w-full
  font-normal
  select-none;
}
.nav-item-icon {
  @apply mr-2;
}
.nav-item-active {
  @apply bg-neutral-low dark:bg-primary;
}
.nav-item-icon-active {
  @apply text-primary dark:text-primary-dark;
}

.child-menu {
  @apply mt-2 mb-2 ml-[9px] pl-[9px] border-l border-neutral-medium dark:border-neutral-dark-medium;

  &__item {
    @apply text-primary
    dark:text-primary-dark
    rounded-[4px]
    leading-4
    m-0
    mt-0.5
    mb-0.5
    text-sm
    h-[33px]
    p-2
    relative
    w-full
    font-normal
    select-none;
  }
}
</style>
