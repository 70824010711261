<template>
  <form class="create-conversation-form w-full" @submit.prevent>
    <div class="flex flex-col px-4 py-3 flex-1 overflow-y-auto">
        <!-- Subject -->
        <div class="w-full subject-field">
          <label class="font-medium" :class="{ error: $v.subject.$error }">
            {{ $t('NEW_CREATE_CONVERSATION.SUBJECT.LABEL') }}
            <input
              v-model="subject"
              type="text"
              :placeholder="$t('NEW_CREATE_CONVERSATION.SUBJECT.PLACEHOLDER')"
              @input="$v.subject.$touch"
            />
            <span v-if="$v.subject.$error" class="message">
              {{ $t('NEW_CREATE_CONVERSATION.SUBJECT.ERROR') }}
            </span>
          </label>
        </div>
        <!-- Inbox selection -->
        <div v-if="!isEditForwardType" class="w-full pt-3 pb-2">
          <label>
            {{ $t('NEW_CREATE_CONVERSATION.INBOX.LABEL') }}
          </label>
          <div
            class="multiselect-wrap--small"
            :class="{ 'has-multi-select-error': $v.targetInbox.$error }"
          >
            <multiselect
              v-model="targetInbox"
              track-by="id"
              label="name"
              :placeholder="$t('FORMS.MULTISELECT.SELECT')"
              selected-label=""
              select-label=""
              deselect-label=""
              :max-height="160"
              :close-on-select="true"
              :options="[...inboxes]"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                <inbox-dropdown-item
                  v-if="option.name"
                  :name="option.name"
                  :inbox-identifier="computedInboxSource(option)"
                  :channel-type="option.channel_type"
                  :is-show-inbox-identifier="false"
                />
              </template>
              <template slot="option" slot-scope="{ option }">
                <inbox-dropdown-item
                  :name="option.name"
                  :is-show-inbox-identifier="false"
                  :inbox-identifier="computedInboxSource(option)"
                  :channel-type="option.channel_type"
                />
              </template>
            </multiselect>
          </div>
        </div>
        <div class="editor-wrapper">
          <!-- Emoji selection popover -->
          <emoji-input
            v-if="showEmojiPicker"
            v-on-clickaway="hideEmojiPicker"
            :class="emojiDialogClassOnExpandedLayoutAndRTLView"
            :on-click="addIntoEditor"
          />
          <recipients
            ref="emailHeadEl"
            :contacts="contacts"
            is-field-taggable
            :cc-emails.sync="ccEmails"
            :bcc-emails.sync="bccEmails"
            :to-emails.sync="toEmails"
            @showBcc="handleBccVisibility"
          />
          <woot-audio-recorder
            v-if="showAudioRecorderEditor"
            ref="audioRecorderInput"
            :audio-record-format="audioRecordFormat"
            @state-recorder-progress-changed="onStateProgressRecorderChanged"
            @state-recorder-changed="onStateRecorderChanged"
            @finish-record="onFinishRecorder"
          />
          <woot-message-editor
            v-else
            ref="messageEditor"
            v-model="message"
            editor-id="createConversationFormEditor"
            class="input"
            :placeholder="$t('NEW_CREATE_CONVERSATION.CONTENT.PLACEHOLDER')"
            :update-selection-with="updateEditorSelectionWith"
            :enable-variables="true"
            :variables="messageVariables"
            :signature="signatureToApply"
            :allow-signature="true"
            :channel-type="channelType"
            :reply-email-head-height="replyEmailHeadHeight"
            @typing-off="onTypingOff"
            @typing-on="onTypingOn"
            @focus="onFocus"
            @blur="onBlur"
            @toggle-user-mention="toggleUserMention"
            @toggle-canned-menu="toggleCannedMenu"
            @toggle-variables-menu="toggleVariablesMenu"
            @clear-selection="clearEditorSelection"
          />
          <div class="bottom-0 left-0 w-full pointer-events-none sticky">
            <div
              v-if="hasAttachments"
              class="attachment-preview-box"
              @paste="onPaste"
            >
              <attachment-preview
                class="flex-col mt-4 pointer-events-auto"
                :attachments="attachedFiles"
                :remove-attachment="removeAttachment"
              />
            </div>
            <message-signature-missing-alert
              v-if="isSignatureEnabledForInbox && !signatureToApply"
            />
            <reply-bottom-panel
              class="pointer-events-auto"
              show-file-upload
              :conversation-id="conversationId"
              :inbox="inbox"
              :is-show-send-button="false"
              :is-recording-audio="isRecordingAudio"
              :mode="replyType"
              :on-file-upload="onFileUpload"
              :recording-audio-duration-text="recordingAudioDurationText"
              :recording-audio-state="recordingAudioState"
              :show-audio-recorder="showAudioRecorder"
              :show-editor-toggle="isAPIInbox"
              :show-emoji-picker="showEmojiPicker"
              :toggle-audio-recorder-play-pause="toggleAudioRecorderPlayPause"
              :toggle-audio-recorder="toggleAudioRecorder"
              :toggle-emoji-picker="toggleEmojiPicker"
              :message="message"
              :portal-slug="connectedPortalSlug"
              @toggle-editor="toggleRichContentEditor"
              @toggle-insert-article="toggleInsertArticle"
            />
        </div>
      </div>
    </div>
    <div class="footer border-t border-neutral-medium dark:border-neutral-dark-medium">
      <woot-button
        variant="clear"
        color-scheme="secondary"
        type="button"
        @click="$emit('cancel')"
      >
        {{ $t('FOOTER.CANCEL') }}
      </woot-button>
      <woot-button type="button" @click="onFormSubmit">
        {{ $t('FOOTER.SEND') }}
      </woot-button>
    </div>
    <transition v-if="isEmailOrWebWidgetInbox" name="modal-fade">
      <div
        v-show="$refs.uploadAttachment && $refs.uploadAttachment.dropActive"
        class="flex top-0 bottom-0 z-30 gap-2 right-0 left-0 items-center justify-center flex-col absolute w-full h-full bg-white/80 dark:bg-slate-700/80"
      >
        <fluent-icon icon="cloud-backup" size="40" />
        <h4 class="page-sub-title text-slate-600 dark:text-slate-200">
          {{ $t('CONVERSATION.REPLYBOX.DRAG_DROP') }}
        </h4>
      </div>
    </transition>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import FileUpload from 'vue-upload-component';
import { mixin as clickaway } from 'vue-clickaway';
import { required, requiredUnless } from 'vuelidate/lib/validators';

// mixins
import rtlMixin from 'shared/mixins/rtlMixin';
import fileUploadMixin from 'dashboard/mixins/fileUploadMixin';
import alertMixin from 'shared/mixins/alertMixin';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import inboxMixin from 'shared/mixins/inboxMixin';

// helpers and constants
import { REPLY_EDITOR_MODES } from 'dashboard/components/widgets/WootWriter/constants';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';
import { ALLOWED_FILE_TYPES } from 'shared/constants/messages';
import { getInboxSource } from 'dashboard/helper/inbox';
import {
  appendSignature,
  removeSignature,
} from 'dashboard/helper/editorHelper';
import { buildHotKeys } from 'shared/helpers/KeyboardHelpers';
import { isEditorHotKeyEnabled } from 'dashboard/mixins/uiSettings';
import { ExceptionWithMessage } from 'shared/helpers/CustomErrors';
import { getMessageVariables } from 'shared/helpers/CannedHelper';

// components
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import Recipients from 'dashboard/components/widgets/conversation/Recipients.vue';
import CannedResponse from 'dashboard/components/widgets/conversation/CannedResponse.vue';
import MessageSignatureMissingAlert from 'dashboard/components/widgets/conversation/MessageSignatureMissingAlert';
import InboxDropdownItem from 'dashboard/components/widgets/InboxDropdownItem.vue';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import ReplyBottomPanel from 'dashboard/components/widgets/WootWriter/ReplyBottomPanel.vue';
const EmojiInput = () => import('shared/components/emoji/EmojiInput');

export default {
  name: "CreateConversationForm",
  components: {
    EmojiInput,
    Thumbnail,
    WootMessageEditor,
    Recipients,
    CannedResponse,
    InboxDropdownItem,
    FileUpload,
    AttachmentPreview,
    MessageSignatureMissingAlert,
    ReplyBottomPanel,
  },
  mixins: [alertMixin, clickaway, uiSettingsMixin, inboxMixin, fileUploadMixin, rtlMixin],
  props: {
    isEditForwardType: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    processedMessageContent: {
      type: String,
      default: '',
    },
    messageEmailFrom: {
      type: String,
      default: '',
    },
    messageOriginalSender: {
      type: String,
      default: '',
    },
    isShowInboxList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      conversationId: 0,
      subject: '',
      message: this.processedMessageContent
        ? this.buildProcessedMessageContent()
        : '',
      showCannedResponseMenu: false,
      cannedResponseSearchKey: '',
      toEmails: [],
      bccEmails: [],
      ccEmails: [],
      targetInbox: {},
      attachedFiles: [],
      isFocused: false,
      showEmojiPicker: false,
      showUserMentions: false,
      showCannedMenu: false,
      showVariablesMenu: false,
      showMentions: false,
      hasSlashCommand: false,
      isRecordingAudio: false,
      recordingAudioState: '',
      recordingAudioDurationText: '',
      updateEditorSelectionWith: '',
      showArticleSearchPopover: false,
      messageVariables: {},
      replyEmailHeadHeight: 0,
      replyType: REPLY_EDITOR_MODES.REPLY,
    };
  },
  validations: {
    subject: {
      required: requiredUnless('isEditForwardType'),
    },
    message: {
      required,
    },
    targetInbox: {
      required: requiredUnless('isEditForwardType'),
    },
    toEmails: {
      required,
      hasValidEmails(value) {
        if (!value || !value.length) return false;

        return value.every(email => email && email.email);
      }
    }
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      conversationsUiFlags: 'contactConversations/getUIFlags',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      shops: 'shops/getShops',
      allInboxes: 'inboxes/getInboxes',
      contacts: 'contacts/getContactsList',
      contactMeta: 'contacts/getMeta',
    }),
    inboxes() {
      return this.allInboxes.filter(inbox => inbox.channel_type === INBOX_TYPES.EMAIL);
    },
    sendWithSignature() {
      return this.fetchSignatureFlagFromUiSettings(this.channelType);
    },
    signatureToApply() {
      return this.messageSignature;
    },
    newMessagePayload() {
      const toEmails = this.toEmails.map(email => email.email).join(',');
      const ccEmails = this.ccEmails.map(email => email.email).join(',');
      const bccEmails = this.bccEmails.map(email => email.email).join(',');

      const payload = {
        inboxId: this.targetInbox.id,
        contactId: this.primaryContact.id,
        sourceId: this.primaryContact.email,
        message: { 
          content: this.message, 
          message_type: 'outgoing',
          to_emails: toEmails,
          subject: this.subject,
        },
        mailSubject: this.subject,
        assigneeId: this.currentUser.id,
      };

      if (ccEmails) {
        payload.message.cc_emails = ccEmails;
      }

      if (bccEmails) {
        payload.message.bcc_emails = bccEmails;
      }

      if (this.attachedFiles && this.attachedFiles.length) {
        payload.files = [];
        this.setAttachmentPayload(payload);
      }
      
      return payload;
    },
    primaryContact() {
      if (this.toEmails.length) {
        // find contact data by using first email in to_emails
        const toContact = this.contacts.find(email => email.email === this.toEmails[0].email);

        return toContact;
      }

      return null;
    },
    selectedInbox: {
      get() {
        return (
          this.inboxes.find(inbox => {
            return inbox.id === this.targetInbox?.id;
          }) || {
            inbox: {},
          }
        );
      },
      set(value) {
        this.targetInbox = value.inbox;
      },
    },
    showNoInboxAlert() {
      if (!this.contact.contactableInboxes) {
        return false;
      }
      return this.inboxes.length === 0 && !this.uiFlags.isFetchingInboxes;
    },
    isSignatureEnabledForInbox() {
      return this.isAnEmailInbox && this.sendWithSignature;
    },
    signatureToggleTooltip() {
      return this.sendWithSignature
        ? this.$t('CONVERSATION.FOOTER.DISABLE_SIGN_TOOLTIP')
        : this.$t('CONVERSATION.FOOTER.ENABLE_SIGN_TOOLTIP');
    },
    isAnEmailInbox() {
      return this.selectedInbox?.channel_type === INBOX_TYPES.EMAIL;
    },
    isAnWebWidgetInbox() {
      return this.selectedInbox?.channel_type === INBOX_TYPES.WEB;
    },
    isEmailOrWebWidgetInbox() {
      return this.isAnEmailInbox || this.isAnWebWidgetInbox;
    },
    hasAttachments() {
      return this.attachedFiles.length;
    },
    inbox() {
      return this.targetInbox;
    },
    allowedFileTypes() {
      return ALLOWED_FILE_TYPES;
    },
    messageSignature() {
      const shop = this.shops.find(s => s.shopify_domain === this.inbox.shop_name);
      if (!shop) return;

      return this.currentUser.user_shop_signatures.find(signature => signature.shop_id === shop.id)?.message_signature;
    },
    isMessageEmpty() {
      if (!this.message) {
        return true;
      }
      return !this.message.trim().replace(/\n/g, '').length;
    },
    hasRecordedAudio() {
      return (
        this.$refs.audioRecorderInput &&
        this.$refs.audioRecorderInput.hasAudio()
      );
    },
    showAudioRecorder() {
      return this.showFileUpload;
    },
    showAudioRecorderEditor() {
      return this.showAudioRecorder && this.isRecordingAudio;
    },
    editorMessageKey() {
      const { editor_message_key: isEnabled } = this.uiSettings;
      return isEnabled;
    },
    commandPlusEnterToSendEnabled() {
      return this.editorMessageKey === 'cmd_enter';
    },
    enterToSendEnabled() {
      return this.editorMessageKey === 'enter';
    },
    emojiDialogClassOnExpandedLayoutAndRTLView() {
      if (this.isRTLView) {
        return 'emoji-dialog--rtl';
      }
      return '';
    },
    connectedPortalSlug() {
      const { help_center: portal = {} } = this.inbox;
      const { slug = '' } = portal;
      return slug;
    },
  },
  watch: {
    message(value) {
      this.hasSlashCommand = value[0] === '/' && !this.isEmailOrWebWidgetInbox;

      const hasNextWord = value.includes(' ');
      const isShortCodeActive = this.hasSlashCommand && !hasNextWord;

      if (isShortCodeActive) {
        this.cannedResponseSearchKey = value.substring(1);
        this.showCannedResponseMenu = true;
      } else {
        this.cannedResponseSearchKey = '';
        this.showCannedResponseMenu = false;
      }
    },
    targetInbox() {
      this.setSignature();
    },
  },
  async mounted() {
    this.setSignature();
    document.addEventListener('keydown', this.handleKeyEvents);
    await this.$store.dispatch('contacts/get');

    // initially default selecting first inbox if there is at least one inbox
    if (this.inboxes.length > 0) {
      this.targetInbox = this.inboxes[0];
    }
    // initialize message variables
    this.messageVariables = getMessageVariables({
      conversation: this.currentChat,
    });
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyEvents);
  },
  methods: {
    buildProcessedMessageContent() {
      const now = moment().format('ddd, MMM D, YYYY at h:mm A');
      return (
        '---------- Forwarded message ---------\n' +
        'From: <' +
        this.messageOriginalSender +
        '>\n' +
        'Date: ' +
        now +
        '\n' +
        'To: <' +
        this.contact.email +
        '>' +
        '\n\n\n' +
        this.processedMessageContent
      ).trim();
    },
    setSignature() {
      if (this.messageSignature) {
        if (this.isSignatureEnabledForInbox) {
          this.message = appendSignature(this.message, this.signatureToApply);
        } else {
          this.message = removeSignature(this.message, this.signatureToApply);
        }
      }
    },
    setAttachmentPayload(payload) {
      this.attachedFiles.forEach(attachment => {
        if (this.globalConfig.directUploadsEnabled) {
          payload.files.push(attachment.blobSignedId);
        } else {
          payload.files.push(attachment.resource.file);
        }
      });
    },
    attachFile({ blob, file }) {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.attachedFiles.push({
          resource: blob || file,
          isPrivate: this.isPrivate,
          thumb: reader.result,
          blobSignedId: blob ? blob.signed_id : undefined,
        });
      };
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
    onPaste(e) {
      const data = e.clipboardData.files;
      if (!this.showRichContentEditor && data.length !== 0) {
        this.$refs.messageInput.$el.blur();
      }
      if (!data.length || !data[0]) {
        return;
      }
      data.forEach(file => {
        const { name, type, size } = file;
        this.onFileUpload({ name, type, size, file: file });
      });
    },
    onSuccess() {
      this.$emit('success');
    },
    replaceTextWithCannedResponse(message) {
      this.message = message;
    },
    toggleCannedMenu(value) {
      this.showCannedMenu = value;
    },
    onFormSubmit(e) {
      this.$v.$touch();

      let isRecipientsValid = true;

      // Add this line to trigger recipient validation
      if (this.$refs.emailHeadEl) {
        this.$refs.emailHeadEl.$v.$touch()
        isRecipientsValid = !this.$refs.emailHeadEl.$v.$invalid
      }

      if (this.$v.$invalid || !isRecipientsValid) {
        return;
      }

      if (!this.toEmails || !this.toEmails.length) {
        this.showAlert(this.$t('NEW_CREATE_CONVERSATION.TO.ERROR'));
        return;
      }

      const payload = this.newMessagePayload;

      this.createConversation({
        payload,
        isFromWhatsApp: false,
      });
    },
    async createConversation({ payload, isFromWhatsApp }) {
      try {
        const data = await this.onSubmit(payload, isFromWhatsApp);
        const action = {
          type: 'link',
          to: `/app/accounts/${data.account_id}/conversations/${data.id}`,
          message: this.$t('NEW_CREATE_CONVERSATION.GO_TO_CONVERSATION'),
        };
        this.onSuccess();
        this.showAlert(
          this.$t('NEW_CREATE_CONVERSATION.SUCCESS_MESSAGE'),
          action
        );
      } catch (error) {
        if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          this.showAlert(this.$t('NEW_CREATE_CONVERSATION.ERROR_MESSAGE'));
        }
      }
    },
    async onSendWhatsAppReply(messagePayload) {
      const isFromWhatsApp = true;
      const payload = this.prepareWhatsAppMessagePayload(messagePayload);
      await this.createConversation({ payload, isFromWhatsApp });
    },
    inboxReadableIdentifier(inbox) {
      return `${inbox.name} (${inbox.channel_type})`;
    },
    computedInboxSource(inbox) {
      if (!inbox.channel_type) return '';
      const classByType = getInboxSource(
        inbox.channel_type,
        inbox.phone_number,
        inbox
      );
      return classByType;
    },
    toggleMessageSignature() {
      this.setSignatureFlagForInbox(this.channelType, !this.sendWithSignature);
      this.setSignature();
    },
    toggleEmojiPicker() {
      this.$v.$reset();
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    hideEmojiPicker() {
      if (this.showEmojiPicker) {
        this.toggleEmojiPicker();
      }
    },
    handleKeyEvents(e) {
      const keyCode = buildHotKeys(e);
      if (keyCode === 'escape') {
        this.hideEmojiPicker();
        this.hideMentions();
      } else if (keyCode === 'enter' && this.isAValidEvent('enter')) {
        this.onFormSubmit();
        e.preventDefault();
      } else if (
        ['meta+enter', 'ctrl+enter'].includes(keyCode) &&
        this.isAValidEvent('cmd_enter')
      ) {
        this.onFormSubmit();
      }
    },
    isAValidEvent(selectedKey) {
      return (
        !this.showUserMentions &&
        !this.showMentions &&
        !this.showCannedMenu &&
        !this.showVariablesMenu &&
        this.isFocused &&
        isEditorHotKeyEnabled(this.uiSettings, selectedKey)
      );
    },
    toggleUserMention(currentMentionState) {
      this.showUserMentions = currentMentionState;
    },
    toggleCannedMenu(value) {
      this.showCannedMenu = value;
    },
    toggleVariablesMenu(value) {
      this.showVariablesMenu = value;
    },
    toggleRichContentEditor() {
      this.updateUISettings({
        display_rich_content_editor: !this.showRichContentEditor,
      });

      const plainTextSignature = extractTextFromMarkdown(this.messageSignature);

      if (!this.showRichContentEditor && this.messageSignature) {
        // remove the old signature -> extract text from markdown -> attach new signature
        let message = removeSignature(this.message, this.messageSignature);
        message = extractTextFromMarkdown(message);
        message = appendSignature(message, plainTextSignature);

        this.message = message;
      } else {
        this.message = replaceSignature(
          this.message,
          plainTextSignature,
          this.messageSignature
        );
      }
    },
    onTypingOn() {
      // Implement if needed for new conversations
    },
    onTypingOff() {
      // Implement if needed for new conversations  
    },
    onBlur() {
      this.isFocused = false;
    },
    onFocus() {
      this.isFocused = true;
    },
    clearEditorSelection() {
      this.updateEditorSelectionWith = '';
    },
    toggleInsertArticle() {
      this.showArticleSearchPopover = !this.showArticleSearchPopover;
    },
    addIntoEditor(content) {
      this.updateEditorSelectionWith = content;
      this.onFocus();
    },
    setupResizeObserver() {
      if (this.$refs.emailHeadEl && this.$refs.emailHeadEl.$el) {
        this.observer = new ResizeObserver(entries => {
          entries.forEach(entry => {
            this.replyEmailHeadHeight = entry.target.offsetHeight;
          });
        });
        this.observer.observe(this.$refs.emailHeadEl.$el);
      }
    },
    handleBccVisibility() {
      this.$nextTick(() => {
        this.setupResizeObserver();
      });
    },
    toggleAudioRecorder() {
      this.isRecordingAudio = !this.isRecordingAudio;
      this.isRecorderAudioStopped = !this.isRecordingAudio;
      if (!this.isRecordingAudio) {
        this.clearMessage();
        this.clearEmailField();
      }
    },
    toggleAudioRecorderPlayPause() {
      if (this.isRecordingAudio) {
        if (!this.isRecorderAudioStopped) {
          this.isRecorderAudioStopped = true;
          this.$refs.audioRecorderInput.stopAudioRecording();
        } else if (this.isRecorderAudioStopped) {
          this.$refs.audioRecorderInput.playPause();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.create-conversation-form {
  @apply flex px-0 py-0 flex-col h-full flex-1 overflow-hidden;

  input[type="text"] {
    @apply mb-1;
  }

  .editor-wrapper {
    @apply flex flex-col flex-1 -mt-px border border-solid border-slate-200 dark:border-slate-600 rounded-lg bg-white dark:bg-primary px-4 pt-2 pb-0;

    .editor-root {
      @apply flex-1 h-auto;
    }

    .attachment-preview-box {
      @apply px-0
    }
  }

  .bottom-box {
    @apply px-0;
  }

  .subject-field {
    input[type="text"] {
      @apply text-sm leading-[18px];
    }
  }

  .conversation-editor .editor-wrapper {
    > textarea {
      min-height: initial;
      max-height: initial;
      height: 100%;
      resize: none;
    }

    > textarea {
      @apply pr-4;
    }
  }
}


.footer {
  @apply w-full py-3 px-4 bg-white dark:bg-primary flex justify-end gap-2;
}

.message-editor {
  @apply px-3;

  ::v-deep {
    .ProseMirror-menubar {
      @apply rounded-tl-[4px];
    }
  }
}

.file-uploads {
  @apply text-start;
}

.multiselect-wrap--small.has-multi-select-error {
  ::v-deep {
    .multiselect__tags {
      @apply border-red-500;
    }
  }
}

::v-deep {
  .mention--box {
    @apply left-0 m-auto right-0 top-auto h-fit;
  }
  .multiselect .multiselect__content .multiselect__option span {
    @apply inline-flex w-6 text-slate-600 dark:text-slate-400;
  }
  .multiselect .multiselect__content .multiselect__option {
    @apply py-0.5 px-1;
  }
}

.send-button {
  @apply mb-0;
}

.banner--self-assign {
  @apply py-2;
}

.attachment-preview-box {
  @apply bg-transparent py-0 px-4 inline-block pointer-events-auto;
}

.conversation-editor {
  @apply border-t border-neutral-high dark:border-neutral-dark-medium bg-white dark:bg-primary;

  &.is-private {
    @apply bg-yellow-50 dark:bg-yellow-200;
  }
}

.send-button {
  @apply mb-0;
}

.emoji-dialog {
  @apply top-[unset] -bottom-10 -left-80 right-[unset];

  &::before {
    transform: rotate(270deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
    @apply -right-4 bottom-2 rtl:right-0 rtl:-left-4;
  }
}

.emoji-dialog--rtl {
  @apply left-[unset] -right-80;
  &::before {
    transform: rotate(90deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
  }
}

.emoji-dialog--expanded {
  @apply left-[unset] bottom-0 absolute z-[100];

  &::before {
    transform: rotate(0deg);
    @apply left-1 -bottom-2;
  }
}

.normal-editor__canned-box {
  width: calc(100% - 2 * var(--space-normal));
  left: var(--space-normal);
}

.trigger-resize {
  @apply text-center select-none relative z-10;

  &__item {
    @apply w-[28px] h-[28px] rounded-[28px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-primary border border-neutral-high dark:border-neutral-dark-medium hover:bg-neutral-low dark:hover:bg-primary cursor-grab;
  }

  &__icon {
    @apply text-secondary dark:text-secondary-dark absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
  }
}
</style>
