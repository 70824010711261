/* global axios */
import ApiClient from './ApiClient';

class GmailImporterAPI extends ApiClient {
  constructor() {
    super('gmail_importer', { accountScoped: true });
  }

  startImport(params) {
    return axios.post(this.url, params);
  }

  getStatus() {
    return axios.get(`${this.url}/status`)
  }
}

export default new GmailImporterAPI();
