<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header
        :header-title="$t('AGENT_MGMT.ADD.TITLE')"
        :header-content="$t('AGENT_MGMT.ADD.DESC')"
      />

      <form
        class="flex flex-col w-full items-start"
        @submit.prevent="addAgent()"
      >
        <div class="w-full">
          <label :class="{ error: $v.agentName.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.NAME.LABEL') }}
            <input
              v-model.trim="agentName"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
            />
          </label>
        </div>
        <div class="w-full">
          <label :class="{ error: $v.agentType.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.LABEL') }}
            <select v-model="agentType">
              <option v-for="role in roles" :key="role.name" :value="role.name">
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.ADD.FORM.AGENT_TYPE.ERROR') }}
            </span>
          </label>
        </div>
        <div class="w-full">
          <label :class="{ error: $v.agentEmail.$error }">
            {{ $t('AGENT_MGMT.ADD.FORM.EMAIL.LABEL') }}
            <input
              v-model.trim="agentEmail"
              type="text"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.EMAIL.PLACEHOLDER')"
              @input="$v.agentEmail.$touch"
            />
          </label>
        </div>
        <!-- Add inbox -->
        <div v-if="isAddAgentInboxFeatureOn" class="w-full">
          <!-- Add inbox dropdown -->
          <label>
            <div class="flex flex-col">
              <span class="text-sm font-medium text-slate-800 dark:text-slate-100">
                {{ $t('AGENT_MGMT.ADD.FORM.INBOXES.LABEL') }}
              </span>
              <span class="text-sm font-normal text-slate-400 dark:text-slate-400 mt-1 mb-2.5">
                {{ $t('AGENT_MGMT.ADD.FORM.INBOXES.DESCRIPTION') }}
              </span>
            </div>
            <multiselect
              v-model="selectedInboxes"
              :options="availableInboxes"
              :taggable="true"
              :select-label="''"
              deselect-label="''"
              :is-searchable="false"
              :multiple="true"
              track-by="id"
              label="name"
              :disabled="isSelectedAll"
              :placeholder="$t('AGENT_MGMT.ADD.FORM.INBOXES.PLACEHOLDER')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            >
              <template slot="option" slot-scope="props">
                <div class="flex align-items justify-between">
                  <span class="option__name">{{ props.option.name }}</span>
                  <fluent-icon
                    v-if="selectedInboxes.map(inbox => inbox.id).includes(props.option.id)"
                    icon="checkmark" size="16px" class="icon" />
                </div>
              </template>
            </multiselect>
          </label>
          <!-- Select all inboxes checkbox -->
          <div class="flex align-items-center mb-3">
            <input
              :value="isSelectedAll"
              :checked="isSelectedAll"
              class="checkbox-style"
              type="checkbox"
              @change="onSelectAllChange($event.target.checked)"
            />
            <span class="text-sm text-slate-800 dark:text-slate-400 ml-2.5">
              {{ $t('AGENT_MGMT.ADD.FORM.INBOXES.ADD_TO_ALL_INBOXES') }}
            </span>
          </div>
        </div>
        <div class="flex flex-row justify-end gap-2 pt-4 px-0 w-full">
          <button class="button clear" @click.prevent="onClose">
            {{ $t('AGENT_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
          </button>
          <woot-submit-button
            :disabled="
              $v.agentEmail.$invalid ||
              $v.agentName.$invalid ||
              uiFlags.isCreating
            "
            :button-text="$t('AGENT_MGMT.ADD.FORM.SUBMIT')"
            :loading="uiFlags.isCreating"
          />
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue';

export default {
  components: {
    MultiselectDropdown,
  },
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      agentName: '',
      agentEmail: '',
      agentType: 'agent',
      isSelectedAll: false,
      selectedInboxes: [],
      roles: [
        {
          name: 'administrator',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
        {
          name: 'agent',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
      ],
      show: true,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      availableInboxes: 'inboxes/getInboxes',
    }),
    isAddAgentInboxFeatureOn() {
      return !!this.$featureFlags['add-agent-inbox']
    },
  },
  mounted() {
    // Fetch inboxes when component mounts
    if(this.isAddAgentInboxFeatureOn && this.availableInboxes.length === 0) {
      this.$store.dispatch('inboxes/get');
    }
  },
  validations: {
    agentName: {
      required,
      minLength: minLength(1),
    },
    agentEmail: {
      required,
      email,
    },
    agentType: {
      required,
    },
  },
  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    onSelectAllChange(newVal) {
      this.isSelectedAll = newVal
    },
    async addAgent() {
      try {
        let ids = []
        if(this.isSelectedAll) {
          ids = this.availableInboxes.map(inbox => inbox.id)
        } else {
          ids = this.selectedInboxes.map(inbox => inbox.id)
        }

        const payload = {
          name: this.agentName,
          email: this.agentEmail,
          role: this.agentType
        }

        if(this.isAddAgentInboxFeatureOn && ids.length) {
          payload.inbox_ids = ids
        }

        await this.$store.dispatch('agents/create', payload);
        this.showAlert(this.$t('AGENT_MGMT.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const { response: { data: { error: errorResponse = '' } = {} } = {} } =
          error;
        let errorMessage = '';
        if (error.response.status === 422) {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.EXIST_MESSAGE');
        } else {
          errorMessage = this.$t('AGENT_MGMT.ADD.API.ERROR_MESSAGE');
        }
        this.showAlert(errorResponse || errorMessage);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
input[type='checkbox'].checkbox-style {
  @apply border border-[#858585] dark:border-[#858585] bg-white dark:bg-[#3B3B3B] cursor-pointer w-[20px] h-[20px] rounded-[3px] appearance-none;
  margin: 0 !important;
}

input[type='checkbox'].checkbox-style:checked {
  @apply bg-accent dark:bg-accent-dark border border-accent dark:border-accent-dark;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.multiselect__option--selected:after {
  content: '' !important;
  display: none !important;
}

.multiselect__option--highlight:after {
  content: '' !important;
  display: none !important;
}

::v-deep {
  .multiselect__option--selected {
    &:after {
      content: '' !important;
      display: none !important;
    }
  }
}
</style>
