<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <template v-if="Object.keys(currentInbox.provider_config || {}).length">
      <!-- Choose Options -->
      <template v-if="lastStep === 1">
        <div class="w-full">
          <page-header
            :header-title="$t('INBOX_MGMT.ADD.AUTH_EMAIL.TITLE')"
            :header-content="isShowSimpleOption ? $t('INBOX_MGMT.ADD.AUTH_EMAIL.DESC') : undefined"
          />
        </div>
        <div
          class="flex flex-wrap -mx-2"
          :class="isShowSimpleOption ? 'justify-center' : undefined"
        >

          <!-- Simple option -->
          <div v-if="isShowSimpleOption" class="w-1/2 px-2">
            <div class="flex flex-col h-full">
              <div
                v-dompurify-html="
                  $t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_SIMPLE.HEADING')
                "
                class="mb-4 text-primary dark:text-primary-dark"
              />
              <dns-card
                :title="$t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_SIMPLE.TITLE')"
                :desc="$t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_SIMPLE.DESC')"
                :capsule-text="
                  $t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_SIMPLE.TIME')
                "
              >
                <template #content>
                  <div
                    class="text-primary dark:text-primary-dark font-medium mb-2 text-sm"
                  >
                    {{ $t('INBOX_MGMT.ADD.AUTH_EMAIL.REQUIREMENT') }}
                  </div>
                  <ul class="text-sm">
                    <li
                      v-for="(item, index) in $t(
                        'INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_SIMPLE.REQUIREMENT.CONTENT'
                      )"
                      :key="index"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </template>
                <template #action>
                  <woot-button is-expanded @click="sendOption('simple')">
                    {{ $t('INBOX_MGMT.ADD.AUTH_EMAIL.SELECT') }}
                  </woot-button>
                </template>
              </dns-card>
            </div>
          </div>

          <!-- Advance option -->
          <div class="w-1/2 px-2">
            <div class="flex flex-col h-full">
              <div
                v-if="isShowSimpleOption"
                v-dompurify-html="
                  $t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_ADVANCE.HEADING')
                "
                class="mb-4 text-primary dark:text-primary-dark"
              />
              <dns-card
                :title="$t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_ADVANCE.TITLE')"
                :desc="$t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_ADVANCE.DESC')"
                :capsule-text="
                  $t('INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_ADVANCE.TIME')
                "
                capsule-variant="secondary"
              >
                <template #content>
                  <div
                    class="text-primary dark:text-primary-dark font-medium mb-2 text-sm"
                  >
                    {{ $t('INBOX_MGMT.ADD.AUTH_EMAIL.REQUIREMENT') }}
                  </div>
                  <ul class="text-sm">
                    <li
                      v-for="(item, index) in $t(
                        'INBOX_MGMT.ADD.AUTH_EMAIL.OPTION_ADVANCE.REQUIREMENT.CONTENT'
                      )"
                      :key="index"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </template>
                <template #action>
                  <woot-button is-expanded @click="sendOption('advance')">
                    {{ $t('INBOX_MGMT.ADD.AUTH_EMAIL.SELECT') }}
                  </woot-button>
                </template>
              </dns-card>
            </div>
          </div>
        </div>
      </template>

      <!-- DNS Table -->
      <div v-if="lastStep === 2" class="w-full">
        <page-header
          :header-title="
            $t('INBOX_MGMT.ADD.AUTH_EMAIL.AUTH_OWNERSHIP.TITLE', {
              email: currentInbox.email,
            })
          "
          :header-content="$t('INBOX_MGMT.ADD.AUTH_EMAIL.AUTH_OWNERSHIP.DESC')"
        />

        <dns-table
          class="mb-4"
          title="DKIM"
          type="TXT"
          :host="getPostmarkDomain.dkim_host"
          :value="getPostmarkDomain.dkim_value"
          :is-inactive="isDkimInactive"
          :is-verified="getPostmarkDomain.dkim_verified || isDkimVerified"
          :is-error="isDkimError"
          :is-loading="isDkimLoading"
          :callout-title="$t('INBOX_MGMT.ADD.AUTH_EMAIL.ERROR_DKIM.TITLE')"
          :callout-desc="
            $t('INBOX_MGMT.ADD.AUTH_EMAIL.ERROR_DKIM.DESC', {
              dkimDomainKey: getPostmarkDomain.dkim_host,
            })
          "
        />
        <dns-table
          class="mb-4"
          title="Return-Path"
          type="CNAME"
          :host="getPostmarkDomain.return_path"
          :value="getPostmarkDomain.return_path_cname_value"
          :is-inactive="isPathInactive"
          :is-verified="
            getPostmarkDomain.return_path_verified || isPathVerified
          "
          :is-error="isPathError"
          :is-loading="isPathLoading"
          :callout-title="
            $t('INBOX_MGMT.ADD.AUTH_EMAIL.ERROR_RETURN_PATH.TITLE')
          "
          :callout-desc="
            $t('INBOX_MGMT.ADD.AUTH_EMAIL.ERROR_RETURN_PATH.DESC', {
              pmBounces: getPostmarkDomain.return_path,
            })
          "
        />

        <div class="flex items-center gap-2.5 mb-4">
          <div class="flex items-center justify-center gap-2.5">
            <woot-button
              v-if="!isPathVerified || !isDkimVerified"
              :disabled="isDisabled"
              @click="handleVerify"
            >
              {{ $t('INBOX_MGMT.ADD.AUTH_EMAIL.AUTH_OWNERSHIP.VERIFY') }}
            </woot-button>
            <woot-button
              v-if="isDnsVerified"
              @click="handleSuccessVerified"
            >
              {{ $t('INBOX_MGMT.ADD.AUTH_EMAIL.CONTINUE') }}
            </woot-button>
          </div>
        </div>
      </div>

      <!-- Resend email -->
      <div
        v-if="lastStep === 3"
        class="flex flex-col h-[calc(100%-100px)] justify-center"
      >
        <div class="w-[400px] px-2 m-auto">
          <dns-card
            :title="$t('INBOX_MGMT.ADD.AUTH_EMAIL.VERIFY.TITLE')"
            :desc="
              $t('INBOX_MGMT.ADD.AUTH_EMAIL.VERIFY.DESC', {
                email: currentInbox.email,
              })
            "
          >
            <template #content>
              <div
                class="text-primary dark:text-primary-dark font-medium mb-6 text-sm"
              >
                <woot-button
                  variant="clear"
                  class="p-0 h-[inherit]"
                  :disabled="isDisabled"
                  @click="handleResendEmail"
                >
                  {{ $t('INBOX_MGMT.ADD.AUTH_EMAIL.RESEND') }}
                </woot-button>
              </div>
            </template>
            <template #action>
              <woot-button is-expanded @click="gotoForwardEmail">
                {{ $t('INBOX_MGMT.ADD.AUTH_EMAIL.CONTINUE') }}
              </woot-button>
            </template>
          </dns-card>
        </div>
      </div>
    </template>

    <template v-else>
      <div
        class="p-4 border border-neutral-medium dark:border-neutral-dark-medium"
      >
        Unable to get Postmark domain.
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '../SettingsSubPageHeader.vue';
import DnsTable from './components/DnsTable.vue';
import DnsCard from './components/DnsCard.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import router from '../../../index';

export default {
  components: {
    PageHeader,
    DnsTable,
    DnsCard,
  },
  mixins: [alertMixin],
  data() {
    return {
      isDisabled: false,
      isDkimInactive: true,
      isPathInactive: true,
      isDkimError: false,
      isPathError: false,
      isDkimVerified: false,
      isPathVerified: false,
      isDnsVerified: false,
      currentStep: 1,
      isDkimLoading: false,
      isPathLoading: false,
      lastStep: null,
      flowType: null,
      isShowSimpleOption: false, // temporary hide the simple option
    };
  },
  computed: {
    ...mapGetters({
      inbox: 'inboxes/getInbox',
    }),
    inboxId() {
      return this.$route.params.inbox_id;
    },
    currentInbox() {
      return this.inbox(this.inboxId);
    },
    getPostmarkDomain() {
      return this.currentInbox?.provider_config?.postmark_domain;
    },
  },
  watch: {
    '$route.query': 'updateQueryParams',
  },
  mounted() {
    this.updateQueryParams();
  },
  methods: {
    updateQueryParams() {
      this.lastStep = parseInt(this.$route.query.last_step, 10);
      this.flowType = this.$route.query.flow_type;
    },
    async handleVerify() {
      this.isDisabled = true;

      this.resetDkimStatus();
      this.resetPathStatus();

      this.verifyingText = this.$t(
        'INBOX_MGMT.ADD.AUTH_EMAIL.AUTH_OWNERSHIP.VERIFYING'
      );

      this.isDkimLoading = true;
      this.isPathLoading = true;

      try {
        const data = await this.$store.dispatch(
          'inboxes/verifyDkimAndReturnPath',
          this.inboxId
        );

        if (data.provider_config?.postmark_domain.dkim_verified) {
          this.isDkimVerified = true;
          this.isDkimInactive = false;
        } else {
          this.isDkimError = true;
        }

        if (data.provider_config?.postmark_domain.return_path_verified) {
          this.isPathVerified = true;
          this.isPathInactive = false;
        } else {
          this.isPathError = true;
        }

        if (data.provider_config?.postmark_domain.dkim_verified && data.provider_config?.postmark_domain.return_path_verified) {
          this.isDnsVerified = true;
        }

        this.isDkimLoading = false;
        this.isPathLoading = false;
        this.isDisabled = false;
      } catch (error) {
        console.error(error); // eslint-disable-line
        this.isDisabled = false;
      }
    },
    handleSuccessVerified() {
      router.replace({
        name: 'settings_inboxes_email_provider_forward',
        params: {
          page: 'new',
          inbox_id: this.currentInbox.id,
        },
      });
    },
    async handleResendEmail() {
      this.isDisabled = true;
      try {
        const data = await this.$store.dispatch(
          'inboxes/resendSenderConfirmation',
          this.inboxId
        );

        this.isDisabled = false;

        // show alert if resend email successfully sent
        this.showAlert(data.message);
      } catch (error) {
        // TODO: need to confirmation from backend for this
        // show alert if user has been confirmed
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.AUTH_EMAIL.VERIFY.CONFIRMED', {
            email: `${this.currentInbox.email}`,
          })
        );
        this.isDisabled = false;
      }
    },
    async handleCopy(val) {
      await copyTextToClipboard(val);
      this.showAlert(this.$t('INBOX_MGMT.COPY.SUCCESS'));
    },
    resetDkimStatus() {
      this.isDkimError = false;
      this.isDkimInactive = true;
    },
    resetPathStatus() {
      this.isPathError = false;
      this.isPathInactive = true;
    },
    async gotoForwardEmail() {
      try {
        const data = await this.$store.dispatch(
          'inboxes/confirmSenderSignature',
          this.inboxId
        );

        if (data.confirmed) {
          router.replace({
            name: 'settings_inboxes_email_provider_forward',
            params: {
              page: 'new',
              inbox_id: this.currentInbox.id,
            },
          });
        } else {
          this.showAlert('Please confirm the email first');
        }
      } catch (error) {
        this.showAlert(error.statusText);
      }
    },
    async postDnsStep(lastStep, flowType) {
      await this.$store.dispatch('inboxes/setDnsProcessStep', {
        inboxId: this.inboxId,
        lastStep: lastStep,
        flowType: flowType,
      });
      this.$router.push({
        query: { last_step: lastStep, flow_type: flowType },
      });
    },
    async sendOption(option) {
      if (option === 'simple') {
        this.currentStep = 3;
        this.postDnsStep(this.currentStep, 'simple');
      }

      if (option === 'advance') {
        this.postDnsStep(this.currentStep + 1, 'advance');
        this.currentStep = 2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.capsule {
  @apply p-1 px-2 bg-accent-low dark:bg-accent-dark-low rounded-lg inline-block absolute top-1/2 right-0 transform -translate-y-1/2;

  &__text {
    @apply text-accent text-xs leading-none font-medium;
  }

  &--grey {
    @apply bg-neutral-medium dark:bg-neutral-dark-medium;

    .capsule__text {
      @apply text-primary dark:text-primary-dark;
    }
  }
}
::v-deep {
  .button .button__content {
    text-align: center !important;
  }
}
</style>
