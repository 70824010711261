import Vue from 'vue';
import types from '../../mutation-types';
import getters, { getSelectedChatConversation } from './getters';
import actions from './actions';
import { findPendingMessageIndex, findAiProcessingMessageIndex } from './helpers';
import { MESSAGE_STATUS, MESSAGE_TYPE } from 'shared/constants/messages';
import wootConstants from 'dashboard/constants/globals';
import { BUS_EVENTS } from '../../../../shared/constants/busEvents';

const state = {
  allConversations: [],
  listLoadingStatus: true,
  chatStatusFilter: wootConstants.STATUS_TYPE.OPEN,
  chatSortFilter: wootConstants.SORT_BY_TYPE.LATEST,
  currentInbox: null,
  selectedChatId: null,
  appliedFilters: [],
  conversationParticipants: [],
  conversationLastSeen: null,
  syncConversationsMessages: {},
  metaCommentHiddenStatus: [],
  metaCommentLikedStatus: [],
  selectedFolderId: 0, // 0 means not selecting any custom view / folder
  folders: [],
  isRefreshConversationsFlagActive: false,
  isInsidePlayground: false,
};

// this function will return the statuses list in current custom view query
// since these statuses will be needed to filter the conversations list
//
// Comparing current folder id is not enough, since virtually custom view page
// will send that query (status=['open'] or 'pending', etc)
// to backend, NOT the custom view ID
const getFolderStatuses = currentCustomView => {
  const payloadQuery = currentCustomView?.query?.payload || [];

  if (payloadQuery.length) {
    const statusQuery = payloadQuery.find(
      item => item.attribute_key === 'status'
    );

    return statusQuery?.values || [];
  }

  return [];
};

// custom view status check:
//
// 1. if statuses is empty array, include as true.
// 2. if currentCustomView has status 'all' in its query,
//    we set as true, i.e. whatever is the current conversation status
// 3. Check if currentStatus is present in statuses as well
const isMatchStatus = (statuses, currentStatus) => {
  if (!Array.isArray(statuses)) return false;

  if (statuses.length === 0 || statuses.includes('all')) return true;

  return statuses.includes(currentStatus);
};

const hiddenStatusIncluded = statuses => {
  if (!Array.isArray(statuses)) return false;

  return statuses.includes('hidden');
};

// mutations
export const mutations = {
  [types.SET_ALL_CONVERSATION](_state, conversationList) {
    const newAllConversations = [..._state.allConversations];
    const currentCustomView = getters.currentCustomView(_state);
    const statuses = getFolderStatuses(currentCustomView);

    // only add new conversation whose status is same as selected folder status
    // if selected folder id is 0, then add all conversations
    if (
      _state.isRefreshConversationsFlagActive &&
      _state.selectedFolderId > 0
    ) {
      // for custom view fix, we'll check against old and new conversations
      // to make sure any conversation that has unmatched status is not added
      const totalList = [...newAllConversations, ...conversationList];
      const newList = [];

      totalList.forEach(conversation => {
        if (isMatchStatus(statuses, conversation.status)) {
          newList.push(conversation);
        }

        // for hidden view since 'hidden' is not primary status( conversation may be "open" or "pending" ... and hidden at the same time)
        if (conversation.meta?.hidden && hiddenStatusIncluded(statuses)) {
          newList.push(conversation);
        }
      });

      _state.allConversations = newList;
    } else {
      conversationList.forEach(conversation => {
        const indexInCurrentList = newAllConversations.findIndex(
          c => c.id === conversation.id
        );
        if (indexInCurrentList < 0) {
          newAllConversations.push(conversation);
        }
      });

      _state.allConversations = newAllConversations;
    }
  },
  [types.EMPTY_ALL_CONVERSATION](_state) {
    _state.allConversations = [];
    _state.selectedChatId = null;
  },
  [types.SET_SELECTED_FOLDER_ID](_state, folderId) {
    _state.selectedFolderId = folderId;
  },
  [types.SET_SELECTED_FOLDER](_state, folder) {
    _state.selectedFolder = folder;
  },
  [types.SET_REFRESH_CONVERSATIONS_FLAG_ACTIVE](_state, newVal) {
    _state.isRefreshConversationsFlagActive = newVal;
  },
  [types.SET_ALL_MESSAGES_LOADED](_state) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat, 'allMessagesLoaded', true);
  },

  [types.CLEAR_ALL_MESSAGES_LOADED](_state) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat, 'allMessagesLoaded', false);
  },
  [types.CLEAR_CURRENT_CHAT_WINDOW](_state) {
    _state.selectedChatId = null;
  },

  [types.SET_PREVIOUS_CONVERSATIONS](_state, { id, data }) {
    if (data.length) {
      const [chat] = _state.allConversations.filter(c => c.id === id);
      chat.messages.unshift(...data);
    }
  },
  [types.SET_ALL_ATTACHMENTS](_state, { id, data }) {
    const [chat] = _state.allConversations.filter(c => c.id === id);
    if (!chat) return;
    Vue.set(chat, 'attachments', []);
    chat.attachments.push(...data);
  },
  [types.SET_SHOPIFY_DATA](_state, { id, data }) {
    const [chat] = _state.allConversations.filter(c => c.id === id);
    if (!chat) return;
    Vue.set(chat, 'shopify_data', data);
  },

  [types.SET_KLAVIYO_DATA](_state, { id, data }) {
    const [chat] = _state.allConversations.filter(c => c.id === id);
    if (!chat) return;
    Vue.set(chat, 'klaviyo_data', data);
  },

  [types.SET_MISSING_MESSAGES](_state, { id, data }) {
    const [chat] = _state.allConversations.filter(c => c.id === id);
    if (!chat) return;
    Vue.set(chat, 'messages', data);
  },

  [types.SET_CURRENT_CHAT_WINDOW](_state, activeChat) {
    if (activeChat) {
      _state.selectedChatId = activeChat.id;
    }
  },

  [types.ASSIGN_AGENT](_state, assignee) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat.meta, 'assignee', assignee);
  },

  [types.ASSIGN_TEAM](_state, { team, conversationId }) {
    const [chat] = _state.allConversations.filter(c => c.id === conversationId);
    Vue.set(chat.meta, 'team', team);
  },

  [types.UPDATE_CONVERSATION_LAST_ACTIVITY](
    _state,
    { lastActivityAt, conversationId }
  ) {
    const [chat] = _state.allConversations.filter(c => c.id === conversationId);
    if (chat) {
      Vue.set(chat, 'last_activity_at', lastActivityAt);
    }
  },
  [types.ASSIGN_PRIORITY](_state, { priority, conversationId }) {
    const [chat] = _state.allConversations.filter(c => c.id === conversationId);
    Vue.set(chat, 'priority', priority);
  },

  [types.UPDATE_CONVERSATION_CUSTOM_ATTRIBUTES](_state, custom_attributes) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat, 'custom_attributes', custom_attributes);
  },

  [types.CHANGE_CONVERSATION_STATUS](
    _state,
    { conversationId, status, snoozedUntil }
  ) {
    const conversation =
      getters.getConversationById(_state)(conversationId) || {};
    Vue.set(conversation, 'snoozed_until', snoozedUntil);
    Vue.set(conversation, 'status', status);
  },

  [types.MUTE_CONVERSATION](_state) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat, 'muted', true);
  },

  [types.UNMUTE_CONVERSATION](_state) {
    const [chat] = getSelectedChatConversation(_state);
    Vue.set(chat, 'muted', false);
  },

  [types.ADD_CONVERSATION_ATTACHMENTS]({ allConversations }, message) {
    const { conversation_id: conversationId } = message;
    const [chat] = getSelectedChatConversation({
      allConversations,
      selectedChatId: conversationId,
    });

    if (!chat) return;

    const isMessageSent =
      message.status === MESSAGE_STATUS.SENT && message.attachments;
    if (isMessageSent) {
      message.attachments.forEach(attachment => {
        if (!chat.attachments.some(a => a.id === attachment.id)) {
          chat.attachments.push(attachment);
        }
      });
    }
  },

  [types.DELETE_CONVERSATION_ATTACHMENTS]({ allConversations }, message) {
    const { conversation_id: conversationId } = message;
    const [chat] = getSelectedChatConversation({
      allConversations,
      selectedChatId: conversationId,
    });

    if (!chat) return;

    const isMessageSent = message.status === MESSAGE_STATUS.SENT;
    if (isMessageSent) {
      const attachmentIndex = chat.attachments.findIndex(
        a => a.message_id === message.id
      );
      if (attachmentIndex !== -1) chat.attachments.splice(attachmentIndex, 1);
    }
  },

  [types.ADD_MESSAGE]({ allConversations, selectedChatId, isInsidePlayground }, message) {
    const { conversation_id: conversationId } = message;
    const [chat] = getSelectedChatConversation({
      allConversations,
      selectedChatId: conversationId,
    });

    if (!chat) return;

    const pendingMessageIndex = findPendingMessageIndex(chat, message);
    if (pendingMessageIndex !== -1) {
      const existingMessage = chat.messages[pendingMessageIndex];

      if (existingMessage && isInsidePlayground && typeof message.id === 'number' && typeof message.message_type === 'string') {
        message.message_type = existingMessage.message_type;
        message.created_at = existingMessage.created_at;
      }

      Vue.set(chat.messages, pendingMessageIndex, message);
    } else {
      // If we're in playground, and the message is an outgoing message,
      // it's assumed to be AI response (from background job)
      // Thus, we replace the AI processing indicator with the actual AI response
      if (isInsidePlayground && message.message_type === MESSAGE_TYPE.OUTGOING) {
        const aiProcessingMessageIndex = findAiProcessingMessageIndex(chat);
        Vue.set(chat.messages, aiProcessingMessageIndex, message);
      } else {
        // Otherwise, we're adding a new message to the conversation
        chat.messages.push(message);
        chat.timestamp = message.created_at;
        const { conversation: { unread_count: unreadCount = 0 } = {} } = message;
        chat.unread_count = unreadCount;
        if (selectedChatId === conversationId) {
          window.bus.$emit(BUS_EVENTS.FETCH_LABEL_SUGGESTIONS);
          window.bus.$emit(BUS_EVENTS.SCROLL_TO_MESSAGE);
        }
      }

    }
  },

  [types.ADD_CONVERSATION](_state, conversation) {
    _state.allConversations.push(conversation);
  },

  [types.UPDATE_CONVERSATION](_state, conversation) {
    const { allConversations } = _state;
    const currentConversationIndex = allConversations.findIndex(
      c => c.id === conversation.id
    );
    if (currentConversationIndex > -1) {
      const { messages, ...conversationAttributes } = conversation;
      const currentConversation = {
        ...allConversations[currentConversationIndex],
        ...conversationAttributes,
      };
      Vue.set(allConversations, currentConversationIndex, currentConversation);
      if (_state.selectedChatId === conversation.id) {
        window.bus.$emit(BUS_EVENTS.FETCH_LABEL_SUGGESTIONS);
        window.bus.$emit(BUS_EVENTS.SCROLL_TO_MESSAGE);
      }
    } else if (
      _state.isRefreshConversationsFlagActive &&
      _state.selectedFolderId > 0
    ) {
      const currentCustomView = getters.currentCustomView(_state);
      const statuses = getFolderStatuses(currentCustomView);

      if (isMatchStatus(statuses, conversation.status)) {
        _state.allConversations.push(conversation);
      }
    } else {
      _state.allConversations.push(conversation);
    }
  },

  [types.UPDATE_CONVERSATION_STATUS](_state, { id, status }) {
    const conversation = _state.allConversations.find(c => c.id === id);
    if (conversation) {
      Vue.set(conversation, 'status', status);
    }
  },

  [types.SET_LIST_LOADING_STATUS](_state) {
    _state.listLoadingStatus = true;
  },

  [types.CLEAR_LIST_LOADING_STATUS](_state) {
    _state.listLoadingStatus = false;
  },

  [types.UPDATE_MESSAGE_UNREAD_COUNT](
    _state,
    { id, lastSeen, unreadCount = 0 }
  ) {
    const [chat] = _state.allConversations.filter(c => c.id === id);
    if (chat) {
      Vue.set(chat, 'agent_last_seen_at', lastSeen);
      Vue.set(chat, 'unread_count', unreadCount);
    }
  },
  [types.CHANGE_CHAT_STATUS_FILTER](_state, data) {
    _state.chatStatusFilter = data;
  },

  [types.CHANGE_CHAT_SORT_FILTER](_state, data) {
    _state.chatSortFilter = data;
  },

  // Update assignee on action cable message
  [types.UPDATE_ASSIGNEE](_state, payload) {
    const [chat] = _state.allConversations.filter(c => c.id === payload.id);
    Vue.set(chat.meta, 'assignee', payload.assignee);
  },

  [types.UPDATE_CONVERSATION_CONTACT](_state, { conversationId, ...payload }) {
    const [chat] = _state.allConversations.filter(c => c.id === conversationId);
    if (chat) {
      Vue.set(chat.meta, 'sender', payload);
    }
  },

  [types.SET_ACTIVE_INBOX](_state, inboxId) {
    _state.currentInbox = inboxId ? parseInt(inboxId, 10) : null;
  },

  [types.SET_CONVERSATION_CAN_REPLY](_state, { conversationId, canReply }) {
    const [chat] = _state.allConversations.filter(c => c.id === conversationId);
    if (chat) {
      Vue.set(chat, 'can_reply', canReply);
    }
  },

  [types.CLEAR_CONTACT_CONVERSATIONS](_state, contactId) {
    const chats = _state.allConversations.filter(
      c => c.meta.sender.id !== contactId
    );
    Vue.set(_state, 'allConversations', chats);
  },

  [types.SET_CONVERSATION_FILTERS](_state, data) {
    _state.appliedFilters = data;
  },

  [types.CLEAR_CONVERSATION_FILTERS](_state) {
    _state.appliedFilters = [];
  },

  [types.SET_LAST_MESSAGE_ID_IN_SYNC_CONVERSATION](
    _state,
    { conversationId, messageId }
  ) {
    _state.syncConversationsMessages[conversationId] = messageId;
  },
  [types.SET_META_COMMENT_HIDDEN_STATUS](_state, data) {
    if (Array.isArray(data)) {
      _state.metaCommentHiddenStatus = data;
    } else {
      _state.metaCommentHiddenStatus = _state.metaCommentHiddenStatus || [];
      _state.metaCommentHiddenStatus.push(data);
    }
  },
  [types.SET_META_COMMENT_LIKED_STATUS](_state, data) {
    if (Array.isArray(data)) {
      _state.metaCommentLikedStatus = data;
    } else {
      _state.metaCommentLikedStatus = _state.metaCommentLikedStatus || [];
      _state.metaCommentLikedStatus.push(data);
    }
  },
  [types.SET_CUSTOM_VIEWS_CONVERSATION](_state, customViewsList) {
    _state.folders = customViewsList;
  },
  [types.SET_IS_INSIDE_PLAYGROUND](_state, value) {
    _state.isInsidePlayground = value;
  },
  [types.APPEND_MESSAGE](_state, message) {
    const [chat] = _state.allConversations.filter(c => c.id === message.conversation_id);

    if (chat) {
      chat.messages.push(message);
    }
  },
  [types.DELETE_CONVERSATION](_state, conversationId) {
    const { allConversations } = _state;
    const updatedConversations = allConversations.filter(c => c.id !== conversationId);
    _state.allConversations = updatedConversations;
    
    // Clear selected chat if it was the deleted conversation
    if (_state.selectedChatId === conversationId) {
      _state.selectedChatId = null;
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
