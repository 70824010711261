import posthog from 'posthog-js';
import PlaygroundWrapper from '../PlaygroundWrapper.vue';
import Playground from './Index.vue';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/ai/playground/inbox/:inbox_id'),
      roles: ['administrator'],
      component: PlaygroundWrapper,
      beforeEnter: (to, from, next) => {
        // get account id from params
        const accountId = to.params.accountId;

        // if featureflag disabled redirect to homepage (dashboard)
        if (posthog.isFeatureEnabled('ai-playground')) {
          next();
        } else {
          next({ name: 'home', params: { accountId } });
        }
      },
      children: [
        {
          path: '',
          name: 'ai_playground',
          roles: ['administrator'],
          component: Playground,
          props: route => {
            return {
              inboxId: route.params.inbox_id,
            };
          },
        },
        {
          path: 'conversations/:id',
          name: 'ai_playground_detail',
          roles: ['administrator'],
          component: Playground,
          props: route => {
            return {
              conversationId: route.params.id,
              inboxId: route.params.inbox_id,
            };
          },
        },
      ],
    },
  ],
};
