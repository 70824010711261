<template>
  <div v-if="paginationExists" class="custom-pagination">
    <ul class="custom-pagination__list">
      <li>
        <a
          role="button"
          class="custom-pagination__previous"
          :class="{ 'is-disabled': isFirstPage }"
          :href="paginationItems.prevPage.pageUrl"
          @click.prevent="
            !isFirstPage &&
              handlePageItemClick(paginationItems.prevPage.pageNumber)
          "
        >
          <fluent-icon size="16" icon="chevron-left" />
        </a>
      </li>
      <template v-for="(page, index, idx) in paginationItems.pages">
        <li v-if="page.gap" :key="page.id">
          <span class="custom-pagination__ellipsis">&hellip;</span>
        </li>
        <li v-else-if="page.current" :key="index" class="has-page-active">
          <span class="custom-pagination__link is-current">
            {{ page.pageNumber }}
          </span>
        </li>
        <li v-else :key="idx">
          <a
            v-if="page.pageUrl"
            class="custom-pagination__link"
            :href="page.pageUrl"
            @click.prevent="handlePageItemClick(page.pageNumber)"
          >
            {{ page.pageNumber }}
          </a>
        </li>
      </template>
      <li>
        <a
          role="button"
          class="custom-pagination__next"
          :class="{ 'is-disabled': isLastPage }"
          :href="paginationItems.nextPage.pageUrl"
          @click.prevent="
            !isLastPage &&
              handlePageItemClick(paginationItems.nextPage.pageNumber)
          "
        >
          <fluent-icon size="16" icon="chevron-right" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pagination: {
      type: Object,
      default: () => {
        return {
          currentPage: 1,
          limit: 10,
          offset: 0,
          total: 30,
          totalPages: 0,
        };
      },
    },
    urlParams: {
      type: String,
      default: '',
    },
    paginationWindow: {
      type: Number,
      default: 3,
    },
    disableLastPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    urlParamSerialized() {
      return this.urlParams ? `&${this.urlParams}` : '';
    },
    paginationExists() {
      return Math.ceil(this.pagination.total / this.pagination.limit) >= 2;
    },
    paginationItems() {
      const currentPage = this.pagination.currentPage;
      const totalPages = this.pagination.totalPages;
      const pages = [];
      const prevPage = {};
      const nextPage = {};

      if (totalPages >= 2) {
        const lowerBound = Math.max(1, currentPage - this.paginationWindow);
        const upperBound = Math.min(
          totalPages,
          currentPage + this.paginationWindow
        );
        let lastPageNumber = 0;

        for (let count = 0; count < this.paginationWindow * 2 + 1; count += 1) {
          const pageNumber = lowerBound + count;

          if (pageNumber <= totalPages) {
            lastPageNumber = pageNumber;
            pages[count] = {
              pageNumber,
              pageUrl: `?page=${pageNumber}${this.urlParamSerialized}`,
              current: currentPage === pageNumber,
              ariaLabel: `${this.ariaPageLabel} ${pageNumber}`,
            };
          }
        }

        if (lowerBound >= 2) {
          const firstPage = {
            pageNumber: 1,
            pageUrl: `?page=1${this.urlParamSerialized}`,
            current: false,
          };

          if (lowerBound > 2) {
            const pageGap = {
              gap: true,
            };

            pages.unshift(pageGap);
          }

          pages.unshift(firstPage);
        }

        if (upperBound <= totalPages - 1 && lastPageNumber < totalPages) {
          const lastPage = {
            pageNumber: totalPages,
            pageUrl: this.disableLastPage
              ? ''
              : `?page=${totalPages}${this.urlParamSerialized}`,
            current: false,
          };

          if (upperBound < totalPages - 1 && lastPageNumber < totalPages) {
            const pageGap = {
              gap: true,
            };

            pages.push(pageGap);
          }

          pages.push(lastPage);
        }

        if (currentPage > 1) {
          prevPage.enabled = true;
          prevPage.pageUrl = `?page=${currentPage - 1}${
            this.urlParamSerialized
          }`;
          prevPage.pageNumber = currentPage - 1;
        }

        if (currentPage < totalPages) {
          nextPage.enabled = true;
          nextPage.pageUrl = `?page=${currentPage + 1}${
            this.urlParamSerialized
          }`;
          nextPage.pageNumber = currentPage + 1;
        }
      }

      return {
        prevPage,
        pages,
        nextPage,
      };
    },
    isFirstPage() {
      return this.pagination.currentPage === 1;
    },
    isLastPage() {
      return this.pagination.currentPage === this.pagination.totalPages;
    },
  },
  methods: {
    handlePageItemClick(newPage) {
      this.$emit('pageChange', newPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-pagination {
  @apply flex items-center justify-center;

  &__list {
    @apply flex items-center justify-center select-none;

    li {
      @apply m-0 p-0 list-none flex items-center justify-center mx-0.5;
    }

    &.has-page-active {
      z-index: 1;
    }
  }

  &__link {
    @apply flex items-center justify-center text-primary dark:text-primary-dark w-[38px] h-[38px] bg-white dark:bg-primary border border-secondary-medium dark:border-neutral-dark-medium select-none rounded-md text-xs hover:bg-neutral-light dark:hover:bg-neutral-dark-light;

    &.is-current {
      @apply border-accent-hover dark:border-accent-dark text-accent dark:text-accent-dark pointer-events-none;
    }

    &.is-disabled {
      @apply pointer-events-none hidden;
    }
  }

  &__next,
  &__previous {
    @apply flex items-center justify-center text-primary dark:text-primary-dark w-[38px] h-[38px] bg-white dark:bg-primary border border-secondary-medium dark:border-neutral-dark-medium select-none rounded-md text-xs hover:bg-neutral-light dark:hover:bg-neutral-dark-light;

    &.is-disabled {
      @apply opacity-50 cursor-not-allowed bg-neutral-light dark:bg-neutral-dark-light;
    }
  }

  &__ellipsis {
    @apply flex items-center justify-center w-[38px] h-[38px] text-xs select-none;
  }
}
</style>
