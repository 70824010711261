/* global axios */
import ApiClient from './ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  getLabels(conversationID) {
    return axios.get(`${this.url}/${conversationID}/labels`);
  }

  updateLabels(conversationID, labels) {
    return axios.post(`${this.url}/${conversationID}/labels`, { labels });
  }

  getGeneratedMessage(conversationId, intentId, regenerate = null, formatType = null) {
    let url = `${this.url}/${conversationId}/generated_message`;
    const params = new URLSearchParams();

    if (typeof intentId === 'number' && !Number.isNaN(intentId)) {
      params.append('intent_id', intentId);
    }

    if (regenerate) {
      params.append('regenerate', regenerate);
    }

    if (formatType) {
      params.append('format_type', formatType);
    }

    const queryString = params.toString();
    if (queryString) {
      url += `?${queryString}`;
    }

    return axios.get(url);
  }

  getGeneratedIntentFields(conversationId) {
    const url = `${this.url}/${conversationId}/generated_intent_fields`;
    return axios.get(url);
  }

  detectIntent(conversationId, messageId) {
    let url = `${this.url}/${conversationId}/detect_intent`;

    const params = new URLSearchParams();
    if (messageId) {
      params.append('before_message_id', messageId);
    }

    const queryString = params.toString();
    if (queryString) {
      url += `?${queryString}`;
    }

    return axios.get(url);
  }

  copyConversation(conversationId, payload) {
    return axios.post(`${this.url}/${conversationId}/copy`, payload);
  }
}

export default new ConversationApi();
