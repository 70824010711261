<template>
  <woot-modal
    :show.sync="showModal"
    :on-close="onCancel"
    :is-use-transition="false"
    modal-type="right-aligned"
    class="instruction"
  >
    <div class="instruction__wrapper">
      <div class="instruction__head">
        <woot-modal-header :header-title="headerTitle" class="pt-4" />
      </div>
      <div class="instruction__body">
        <div class="mb-2 text-sm">
          {{ $t('SIDEBAR.AI_RESPONSE.MODAL.TITLE') }}
        </div>
        <input
          ref="titleField"
          v-model="title"
          type="text"
          class="instruction__input"
          :class="{
            'is-focused': focusedField === 'titleField',
          }"
          :disabled="isFetching"
          :placeholder="isFetching ? 'Generating title...' : ''"
          @focus="onFocus('titleField')"
          @blur="onBlur"
        />
        <div class="mb-2 text-sm">
          {{ $t('SIDEBAR.AI_RESPONSE.MODAL.DESCRIPTION') }}
        </div>
        <resizable-text-area
          v-model="description"
          class="instruction__textarea"
          :class="{
            'is-focused': focusedField === 'descriptionField',
          }"
          :disabled="isFetching"
          :placeholder="isFetching ? 'Generating description...' : ''"
          @focus="onFocus('descriptionField')"
          @blur="onBlur"
        />
        <div class="mb-2 text-sm">
          {{ $t('SIDEBAR.AI_RESPONSE.MODAL.RESPOND') }}
        </div>
        <div
          class="instruction__editor"
          :class="{
            'is-focused': focusedField === 'instructionField',
          }"
        >
          <woot-message-editor
            v-model="instruction"
            class="input"
            :disabled="isFetching"
            :markdown-placeholder="
              $t('SIDEBAR.AI_RESPONSE.MODAL.RESPOND_PLACEHOLDER')
            "
            @focus="onFocus('instructionField')"
            @blur="onBlur"
          />
        </div>
      </div>
      <div class="instruction__footer">
        <div class="instruction__footer-button">
          <woot-button variant="clear" @click="onCancel">
            {{ $t('SIDEBAR.AI_RESPONSE.CANCEL') }}
          </woot-button>
        </div>
        <div class="instruction__footer-button">
          <woot-button :is-disabled="isDisabled" @click="onSubmit">
            {{ $t('SIDEBAR.AI_RESPONSE.SAVE') }} &
            {{ $t('SIDEBAR.AI_RESPONSE.REWRITE') }}
          </woot-button>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import ResizableTextArea from 'shared/components/ResizableTextArea.vue';
import { mapActions } from 'vuex';
import AnalyticsHelper from 'dashboard/helper/AnalyticsHelper';
import { AI_EVENTS } from 'dashboard/helper/AnalyticsHelper/events';

export default {
  components: {
    WootMessageEditor,
    ResizableTextArea,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    intent: {
      type: Object,
      required: false,
      default: null,
    },
    isNewIntent: {
      type: Boolean,
      default: false,
    },
    conversationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showModal: this.show,
      focusedField: null,
      title: '',
      description: '',
      instruction: '',
      isFetching: true,
    };
  },
  computed: {
    isDisabled() {
      return (
        !this.title || !this.description || !this.instruction || this.isFetching
      );
    },

    headerTitle() {
      return this.intent && !this.isNewIntent
        ? this.$t('SIDEBAR.AI_RESPONSE.EDIT_INSTRUCTION')
        : this.$t('SIDEBAR.AI_RESPONSE.ADD_INSTRUCTION');
    },
  },
  watch: {
    async show(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.isFetching = true;
        await this.prepareIntent();
        this.isFetching = false;
        this.$nextTick(() => {
          if (this.$refs.titleField) {
            this.$refs.titleField.focus();
          }
        });
      } else {
        this.isFetching = false;
      }
    },
    conversationId(newConversationId, oldConversationId) {
      if (newConversationId !== oldConversationId) {
        // Clear local variables when a new conversation is set
        this.title = '';
        this.description = '';
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.titleField) {
        this.$refs.titleField.focus();
      }
    });
  },
  methods: {
    ...mapActions('intents', ['getById', 'create', 'update']),
    ...mapActions('generatedIntentFields', ['fetchGeneratedIntentFields']),

    onFocus(fieldName) {
      this.focusedField = fieldName;
    },

    onBlur() {
      this.focusedField = null;
    },

    async prepareIntent() {
      if (this.intent && !this.isNewIntent) {
        this.fetchIntentDetails();
      } else if (
        this.isNewIntent &&
        this.title === '' &&
        this.description === ''
      ) {
        try {
          // Fetch the generated intent fields
          await this.fetchGeneratedIntentFields({
            conversationId: this.conversationId,
          });

          // Get the fetched generated intent fields from the store
          const generatedIntentFields =
            this.$store.getters[
              'generatedIntentFields/getGeneratedIntentFields'
            ];

          if (generatedIntentFields) {
            // Populate title and description as suggestions
            this.title = generatedIntentFields.title || '';
            this.description = generatedIntentFields.description || '';
          } else {
            // Handle the case when no generated intent fields are available
            this.title = '';
            this.description = '';
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching generated intent fields:', error);
        }
      }
    },

    fetchIntentDetails() {
      this.title = this.intent?.title || '';
      this.description = this.intent?.description || '';
      this.instruction = this.intent?.instructions || '';
    },

    onCancel() {
      this.$emit('cancel');
    },

    async onSubmit() {
      if (this.isDisabled) {
        return;
      }

      this.isFetching = true;

      const intentObj = {
        title: this.title,
        description: this.description,
        instructions: this.instruction,
      };

      let savedData;

      if (this.intent && !this.isNewIntent) {
        savedData = await this.update({ id: this.intent.id, ...intentObj });
      } else {
        const response = await this.create({ enabled: true, ...intentObj });
        savedData = response.data;
      }

      this.isFetching = false;
      // Set the current intent after saving
      this.$store.commit('intents/SET_CURRENT_INTENT', savedData);

      this.$emit('submit', savedData);

      if (this.intent && !this.isNewIntent) {
        AnalyticsHelper.track(AI_EVENTS.SAVE_AND_REWRITE, {
          conversationId: this.conversationId,
          intentId: this.intent?.id,
        });
      } else {
        AnalyticsHelper.track(AI_EVENTS.ADD_INSTRUCTION, {
          conversationId: this.conversationId,
          intentId: savedData?.id,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.instruction {
  ::v-deep {
    .icon {
      margin-right: 0 !important;
    }
    .button {
      @apply top-[10px];
    }
  }

  &__wrapper {
    @apply h-full flex flex-col;
  }

  &__head {
    @apply border-b border-neutral-high dark:border-secondary-dark-low pb-3;
  }

  &__body {
    @apply w-full flex-1 pt-6 px-8 pb-6 overflow-y-auto;
  }

  &__input {
    @apply w-full text-sm px-4;

    &:focus {
      @apply transition-all duration-100 ease-in;
    }
  }

  &__textarea {
    @apply w-full dark:bg-primary border border-neutral-high dark:border-neutral-dark-high max-h-[90px] min-h-[90px] resize-none text-sm p-4 rounded-lg transition-all duration-100 ease-in;
  }

  &__editor {
    @apply px-4 pb-4 bg-neutral dark:bg-primary border border-neutral-high dark:border-neutral-dark-high rounded-lg transition-all duration-100 ease-in;

    ::v-deep {
      .ProseMirror-woot-style {
        min-height: calc(100vh - 472px);
        max-height: calc(100vh - 472px);
      }
    }
  }

  &__footer {
    @apply flex items-center justify-end px-8 pb-4 flex-shrink-0;

    &-button {
      @apply mr-2;

      &:last-child {
        @apply mr-0;
      }
    }
  }

  .is-focused {
    @apply border-accent dark:border-accent-dark;
  }
}
</style>
